const { useMemo } = require('react')

const useSortData = (data: any[], kpi: string, ascending: boolean) => {
  const sortedData = useMemo(() => {
    const sortedArray = data || []

    if (!ascending) {
      sortedArray.sort((a: any, b: any) => {
        const isNumberA = typeof a[kpi] === 'number'
        const isNumberB = typeof b[kpi] === 'number'

        if (isNumberA && isNumberB) {
          return (
            (b[kpi] !== Infinity || typeof b[kpi] !== 'string'
              ? b[kpi]
              : -Infinity) -
            (a[kpi] !== Infinity || typeof a[kpi] !== 'string'
              ? a[kpi]
              : -Infinity)
          )
        }
        if (isNumberA) {
          return -1
        }
        if (isNumberB) {
          return 1
        }
        return 0
      })
    }

    if (ascending) {
      sortedArray.sort((a: any, b: any) => {
        const isNumberA = typeof a[kpi] === 'number'
        const isNumberB = typeof b[kpi] === 'number'
        if (isNumberA && isNumberB) {
          return (
            (a[kpi] !== Infinity || typeof a[kpi] !== 'string'
              ? a[kpi]
              : -Infinity) -
            (b[kpi] !== Infinity || typeof b[kpi] !== 'string'
              ? b[kpi]
              : -Infinity)
          )
        }
        if (isNumberA) {
          return -1
        }
        if (isNumberB) {
          return 1
        }
        return 0
      })
    }

    return sortedArray
  }, [data, kpi, ascending])
  return sortedData
}

export default useSortData
