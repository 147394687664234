import { createShouldForwardProp, getNavigationTextColor } from 'common/helpers'

import { Box, BoxProps, styled } from '@mui/material'
import { SIDEBAR } from 'common/constants'

interface NavDesktopMiniSectionProps extends BoxProps {
  isSelect: boolean
  isAccessible: boolean
}

const NavDesktopMiniSectionForwardProps = createShouldForwardProp([
  'isSelect',
  'isAccessible',
])
export const NavDesktopOpenWrapper = styled(Box)(({ theme }) => ({
  minWidth: SIDEBAR.WIDTH_DESKTOP_OPEN,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}))

export const NavDesktopMiniWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: `${theme.spacing(3)} ${theme.spacing(0.5)}`,
}))

export const NavDesktopMiniSection = styled(Box, {
  shouldForwardProp: NavDesktopMiniSectionForwardProps,
})<NavDesktopMiniSectionProps>(({ theme, isSelect, isAccessible }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: getNavigationTextColor(
    isSelect,
    isAccessible,
    theme.palette.primary.main,
    theme.palette.text.disabled
  ),
  padding: theme.spacing(0.5),
  borderRadius: theme.spacing(1.5),
  backgroundColor: isSelect ? theme.palette.background.paper2 : 'inherit',
}))
