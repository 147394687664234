import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AddCircle,
  Clear,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  Typography,
  useTheme,
} from '@mui/material'

import {
  AdvancedFilterConditionSelectWrapper,
  UserConditionTextFiled,
} from './styles'

const conditionList = [
  { value: 'contains', label: 'CONTAINS_IGNORE_CASE' },
  { value: 'contains_not', label: 'CONTAINS_NOT_IGNORE_CASE' },
  { value: 'eq', label: 'EQUALS_IGNORE_CASE' },
  { value: 'neq', label: 'EQUALS_NOT_IGNORE_CASE' },
  { value: 'startwith', label: 'STARTS_WITH_IGNORE_CASE' },
  { value: 'starts_not_with', label: 'STARTS_NOT_WITH_IGNORE_CASE' },
]

const AdvancedFilterRow = ({
  rowId,
  rowFilterDetails,
  handleAddConditionRow,
  handleRemoveConditionRow,
  handleConditionChange,
  handleValueChange,
  allowRemove,
  isLastRow,
}: {
  rowId: string
  rowFilterDetails: any
  handleAddConditionRow: any
  handleRemoveConditionRow: any
  handleConditionChange: any
  handleValueChange: any
  allowRemove: boolean
  isLastRow: boolean
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openConditionOptionsDropDown = Boolean(anchorEl)

  const getConditionLabel = (value: string) => {
    const condition = conditionList.find(
      (conditionItem) => conditionItem.value === value
    )
    return condition ? condition.label : ''
  }

  const openConditionDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeConditionDropDown = () => {
    setAnchorEl(null)
  }

  const handleSelectCondition = (option: string) => {
    handleConditionChange(rowId, option)
    setAnchorEl(null)
  }

  const handleUserConditionInput = (event: ChangeEvent<HTMLInputElement>) => {
    handleValueChange(rowId, event.target.value)
  }

  const resetUserConditionInput = () => {
    handleValueChange(rowId, '')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}
      >
        <AdvancedFilterConditionSelectWrapper onClick={openConditionDropdown}>
          <Box>
            <Typography variant="body1">
              {t(`${getConditionLabel(rowFilterDetails.filterType)}`, {
                ns: 'filter',
              })}
            </Typography>
          </Box>

          {openConditionOptionsDropDown ? (
            <KeyboardArrowUp />
          ) : (
            <KeyboardArrowDown />
          )}
        </AdvancedFilterConditionSelectWrapper>

        <Menu
          id="date-range-menu"
          anchorEl={anchorEl}
          open={openConditionOptionsDropDown}
          onClose={closeConditionDropDown}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: '606px',
                maxHeight: '284px',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                mt: '6px',
                px: 1.5,
                borderRadius: 4,
                boxShadow: theme.palette.customShadow.popover,
                bgcolor: theme.palette.background.paper3,
              },
            },
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          {conditionList.map((conditionOption) => (
            <Box
              key={conditionOption.value}
              onClick={() => handleSelectCondition(conditionOption.value)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                py: 1,
                px: 2,
                my: 0.5,
                borderRadius: 2,
                cursor: 'pointer',
                color:
                  conditionOption.value === rowFilterDetails.filterType
                    ? theme.palette.primary.main
                    : 'inherit',
                ':hover': {
                  bgcolor: theme.palette.background.paper2,
                },
              }}
            >
              <Typography variant="body1">
                {t(`${conditionOption.label}`, {
                  ns: 'filter',
                })}
              </Typography>
            </Box>
          ))}
        </Menu>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <UserConditionTextFiled
          fullWidth
          autoComplete="off"
          variant="outlined"
          placeholder={t('TYPE_YOUR_CONDITION', {
            ns: 'filter',
          })}
          value={rowFilterDetails.filterValue}
          onChange={handleUserConditionInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {rowFilterDetails.filterValue !== '' && (
                  <Clear
                    sx={{
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={resetUserConditionInput}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            minWidth: '150px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {isLastRow ? (
            <Button
              onClick={handleAddConditionRow}
              disabled={rowFilterDetails.filterValue === ''}
              variant="contained"
              startIcon={<AddCircle />}
              sx={{
                borderRadius: 5,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {t('OR', {
                ns: 'filter',
              })}
            </Button>
          ) : (
            <Typography
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {t('OR', {
                ns: 'filter',
              })}
            </Typography>
          )}

          <IconButton
            onClick={() => handleRemoveConditionRow(rowId)}
            disabled={!allowRemove}
            sx={{ color: theme.palette.text.legend }}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default AdvancedFilterRow
