import { KPI_FORMAT } from 'common/constants'
import { useMemo } from 'react'

const useCalculateTotalKpi3 = (
  totalKpi1: number,
  totalKpi2: number,
  dataType: string
) => {
  const totalKpi3 = useMemo(() => {
    if (totalKpi2 === 0) {
      return null
    }
    if (dataType === KPI_FORMAT.integer || dataType === KPI_FORMAT.currency) {
      return totalKpi1 / totalKpi2
    }
    if (dataType === KPI_FORMAT.percent) {
      return totalKpi1 / totalKpi2
    }
    if (dataType === KPI_FORMAT.time) {
      return totalKpi1 / totalKpi2
    }

    return 0
  }, [totalKpi1, totalKpi2, dataType])

  return totalKpi3 as number
}

export default useCalculateTotalKpi3
