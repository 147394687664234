import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getDimensionsByFilter } from 'api'
import type { RootState } from 'store/store'

import { STATUS } from 'common/constants'

export const fetchDimensionFilter = createAsyncThunk(
  'dimensionFilter/fetchDimensionFilter',
  async ({ payload }: { payload: any }) => {
    const response = await getDimensionsByFilter({ payload })

    return response.data
  }
)

const initialState: any = {
  value: [],
  status: 0,
}

export const dimensionFilterSlice = createSlice({
  name: 'dimensionFilter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDimensionFilter.pending, (state) => {
        state.status = STATUS.pending
      })
      .addCase(fetchDimensionFilter.fulfilled, (state, { payload }) => {
        state.status = STATUS.fulfilled
        state.value = payload?.data
      })
      .addCase(fetchDimensionFilter.rejected, (state) => {
        state.status = STATUS.rejected
      })
  },
})

export const selectDimensionFilter = (state: RootState) => state.dimensionFilter

export default dimensionFilterSlice.reducer
