import { Box, styled } from '@mui/material'
import { DASHBOARD_LAYOUT } from 'common/constants'

export const DrawerContainer = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,

  [theme.breakpoints.up('sm')]: {
    width: `${DASHBOARD_LAYOUT.WIDTH_MIN}px`,
  },
}))

export const SidebarMobileHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(3)}`,
  paddingBottom: `${theme.spacing(1)}`,
  borderBottom: `0.1rem solid ${theme.palette.background.disabled}`,
}))
