import { useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectAuth, setAuth } from 'store/features/auth-slice'
import { selectModal, setModal } from 'store/features/modal-slice'
import { resetFilters } from 'store/features/filter-slice'

import { getPersistedItem } from 'common/helpers'
import { CUSTOMER_MENU, CUSTOMER_SELECT } from 'common/constants'

import CustomerSearch from './customer-search'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Typography, Menu, useMediaQuery, useTheme } from '@mui/material'

import { CustomerSelectWrapper } from './styles'

interface Customer {
  customerId?: number
  customerName?: string
  currency?: string
  reportingUnits?: number
}

const CustomerSearchSelect = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const {
    value: { activeComponent: modalActiveComponent },
  } = useAppSelector(selectModal)

  const {
    value: { customers, currentCustomer },
  } = useAppSelector(selectAuth)

  const isCustomerSelect = modalActiveComponent === CUSTOMER_SELECT
  const [userSearch, setUserSearch] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState(currentCustomer)

  const customerList: Customer[] = customers ? Object?.values(customers) : [{}]

  const customerOptionsBySearch = customerList.filter((option) =>
    option?.customerName?.toLowerCase().includes(userSearch.toLowerCase())
  )

  const persistedAuth = getPersistedItem('auth')

  useEffect(() => {
    if (!selectedCustomer) {
      setSelectedCustomer(currentCustomer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer])

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setUserSearch('')
  }

  const handleCustomerSelect = (selected: Customer) => {
    setAnchorEl(null)
    setSelectedCustomer(selected)
    setUserSearch('')
    dispatch(setAuth({ ...persistedAuth, currentCustomer: selected }))
    dispatch(setModal(''))
    dispatch(resetFilters())
  }

  const handleUserSearch = (select: string) => {
    setUserSearch(select)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setUserSearch('')
  }

  return (
    <Box sx={{ display: 'flex', p: 2 }}>
      {isWideScreen && (
        <CustomerSelectWrapper isOpen={open} onClick={handleClickListItem}>
          <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
            {selectedCustomer ? selectedCustomer?.customerName : 'No Customer'}
          </Typography>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </CustomerSelectWrapper>
      )}

      {isCustomerSelect ? (
        <CustomerSearch
          userSearch={userSearch}
          selectedCustomer={selectedCustomer}
          sortedCustomerOptions={customerOptionsBySearch}
          handleUserSearch={handleUserSearch}
          handleCustomerSelect={handleCustomerSelect}
        />
      ) : (
        <Menu
          id="customer-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: {
                  xs: `${CUSTOMER_MENU.WIDTH_DESKTOP_WIDE - 4}px`,
                  lg: `${CUSTOMER_MENU.WIDTH_DESKTOP_EXTRA_WIDE - 4}px`,
                },
                display: 'flex',
                flexDirection: 'column',
                mt: {
                  xs: '-6px',
                  lg: '2px',
                },
                p: 1,
                borderRadius: `0 0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
                boxShadow: theme.palette.customShadow.popover,
                bgcolor: theme.palette.background.paper3,
              },
            },
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <CustomerSearch
            userSearch={userSearch}
            selectedCustomer={selectedCustomer}
            sortedCustomerOptions={customerOptionsBySearch}
            handleUserSearch={handleUserSearch}
            handleCustomerSelect={handleCustomerSelect}
          />
        </Menu>
      )}
    </Box>
  )
}

export default CustomerSearchSelect
