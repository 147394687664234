import { createShouldForwardProp } from 'common/helpers'

import { Box, BoxProps, Button, styled, TextField } from '@mui/material'

interface LoginTextFiledProps extends BoxProps {
  hasAuthError: boolean
}

const LoginTextFiledForwardProps = createShouldForwardProp(['hasAuthError'])

export const LoginPageWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  background:
    'radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%), linear-gradient(45deg, rgba(114,0,214,1) 10%, rgba(61,146,255,1) 70%)',
}))

export const LoginSectionWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const LoginBoxWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '496px',
  display: 'flex',
  backgroundColor: theme.palette.background.paper3,
  boxShadow: theme.palette.customShadow.popover,
  borderRadius: theme.spacing(1.5),

  [theme.breakpoints.up('lg')]: {
    width: '856px',
  },
}))

export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'none',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: `${theme.spacing(1.5)} 0 0 ${theme.spacing(1.5)}`,
  overflow: 'hidden',
  backgroundColor: '#081831',

  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    width: '428px',
  },
}))
export const LoginWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),

  [theme.breakpoints.up('lg')]: {
    width: '428px',
    padding: theme.spacing(4),
  },
}))

export const LoginTextFiled = styled(TextField, {
  shouldForwardProp: LoginTextFiledForwardProps,
})<LoginTextFiledProps>(({ theme, hasAuthError }) => ({
  '& .MuiOutlinedInput-root': {
    height: '52px',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper2,
    '& fieldset': {
      borderColor: hasAuthError ? theme.palette.error.main : 'transparent',
    },
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
}))

export const CustomWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(0.6)} ${theme.spacing(2)}`,
  color: theme.palette.text.secondary,
}))

export const RememberMeWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  color: theme.palette.text.secondary,
}))

export const CustomCheckBox = styled(Box)(({ theme }) => ({
  minWidth: '16px',
  minHeight: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: theme.spacing(0.5),
}))

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(4),
}))

export const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: '136px',
  height: '40px',
  borderRadius: theme.spacing(5),
  textTransform: 'inherit',
}))
