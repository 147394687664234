import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    value: { type: '', activeComponent: '' },
  },
  reducers: {
    setModal: (state, { payload }) => {
      state.value = {
        type: payload.type,
        activeComponent: payload.activeComponent,
      }
    },
  },
})

export const selectModal = (state: RootState) => state.modal

export const { setModal } = modalSlice.actions

export default modalSlice.reducer
