import { createShouldForwardProp, getNavigationTextColor } from 'common/helpers'

import { Box, BoxProps, styled } from '@mui/material'

interface NavMobileSubsectionProps extends BoxProps {
  isSelect: boolean
}
interface NavMobileKpiPathProps extends BoxProps {
  isSelect: boolean
}

const NavMobileSubsectionForwardProps = createShouldForwardProp(['isSelect'])
const NavMobileKpiPathForwardProps = createShouldForwardProp(['isSelect'])

interface NavSectionWrapperProps extends BoxProps {
  isSelect: boolean
  isAccessible: boolean
}
const NavSectionWrapperForwardProps = createShouldForwardProp([
  'isSelect',
  'isAccessible',
])

export const NavSectionWrapper = styled(Box, {
  shouldForwardProp: NavSectionWrapperForwardProps,
})<NavSectionWrapperProps>(({ theme, isSelect, isAccessible }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: isSelect ? theme.palette.background.paper2 : 'inherit',
  color: getNavigationTextColor(
    isSelect,
    isAccessible,
    theme.palette.primary.main,
    theme.palette.text.disabled
  ),
  filter:
    !isSelect || (!isAccessible && isSelect) ? 'grayScale(100%)' : 'inherit',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.background.paper2,
    filter: 'grayScale(0%)',
  },
}))

export const NavSubsection = styled(Box, {
  shouldForwardProp: NavMobileSubsectionForwardProps,
})<NavMobileSubsectionProps>(({ theme, isSelect }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: isSelect ? theme.palette.primary.main : 'inherit',
}))

export const NavKpiPath = styled(Box, {
  shouldForwardProp: NavMobileKpiPathForwardProps,
})<NavMobileKpiPathProps>(({ theme, isSelect }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  padding: theme.spacing(1),
  paddingLeft: theme.spacing(5),
  borderRadius: theme.spacing(1.5),
  color: isSelect ? theme.palette.primary.main : 'inherit',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.background.paper2,
  },
}))
