import { Box, styled } from '@mui/material'

export const NavMobileWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(1)}`,
}))

export const NavMobileSubsectionHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  paddingTop: theme.spacing(2),
  gap: theme.spacing(1),
}))
