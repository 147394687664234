import { useLayoutEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectTheme, setMode } from 'store/features/theme-slice'

import { getIsRememberMe, getPersistedItem, persistItem } from 'common/helpers'
import { MODE } from 'common/constants'

import { Box, Menu, Typography, useTheme } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

const ChangeMode = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const { mode } = useAppSelector(selectTheme)
  const persistedTheme = getPersistedItem('theme')

  const [selectedMode, setSelectedMode] = useState(
    persistedTheme?.mode === MODE.dark ? 'DARK_MODE' : 'LIGHT_MODE'
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openModeDropdown = Boolean(anchorEl)
  const boxRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    dispatch(setMode(selectedMode === 'DARK_MODE' ? MODE.dark : MODE.light))
    // eslint-disable-next-line
  }, [])

  const handleMode = (mode: string) => {
    const isRememberMe = getIsRememberMe()

    setSelectedMode(mode)
    dispatch(setMode(mode === 'DARK_MODE' ? MODE.dark : MODE.light))

    persistItem(
      'theme',
      {
        ...persistedTheme,
        mode: mode === 'DARK_MODE' ? MODE.dark : MODE.light,
      },
      isRememberMe
    )

    setAnchorEl(null)
  }

  const handleOpenModeDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeModeDropdown = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Box
        ref={boxRef}
        onClick={handleOpenModeDropdown}
        sx={{
          width: '100%',
          height: '56px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper2,
          padding: `0 ${theme.spacing(2)}`,
          borderRadius: theme.spacing(2),
          cursor: 'pointer',
        }}
      >
        {t(`${mode === MODE.light ? 'LIGHT_MODE' : 'DARK_MODE'}`, {
          ns: 'common',
        })}
        {openModeDropdown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Box>
      <Menu
        id="theme-mode"
        anchorEl={anchorEl}
        open={openModeDropdown}
        onClose={closeModeDropdown}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: boxRef.current?.clientWidth || 'auto',
              maxHeight: '284px',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              mt: '6px',
              px: 1.5,
              borderRadius: 4,
              boxShadow: theme.palette.customShadow.popover,
              bgcolor: theme.palette.background.paper2,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {['LIGHT_MODE', 'DARK_MODE'].map((mode) => (
          <Box
            key={mode}
            onClick={() => handleMode(mode)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              py: 1,
              px: 2,
              my: 0.5,
              borderRadius: 2,
              cursor: 'pointer',
              color:
                selectedMode === mode ? theme.palette.primary.main : 'inherit',
              ':hover': {
                bgcolor: theme.palette.background.paper3,
              },
            }}
          >
            <Typography variant="body1">
              {t(`${mode}`, {
                ns: 'common',
              })}
            </Typography>
          </Box>
        ))}
      </Menu>
    </Box>
  )
}

export default ChangeMode
