import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SourcesLineChartTravellersSelect = ({
  allValue,
  selectRange,
  handleRange,
}: {
  allValue: number
  selectRange: number
  handleRange: any
}) => {
  const { t } = useTranslation()

  const selectOptions: { label: string; value: number }[] = [
    { label: 'ALL', value: allValue },
    { label: '7', value: 7 },
    { label: '14', value: 14 },
    { label: '30', value: 30 },
    { label: '90', value: 90 },
    { label: '182', value: 182 },
    { label: '365', value: 365 },
    { label: 'CUSTOM', value: 0 },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 2,
        px: { xs: 2, md: 8 },
      }}
    >
      <Box
        sx={{
          maxHeight: '80px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 0.5,
          p: 0.5,
          borderRadius: { xs: 4, md: 30 },
          backgroundColor: 'background.paper2',
          overflow: 'auto',
        }}
      >
        {selectOptions.map(
          (option) =>
            option.value <= allValue && (
              <Box
                key={option.label}
                sx={{
                  width: '112px',
                  height: '32px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                  cursor: option.value === 0 ? 'default' : 'pointer',
                  color: 'text.primary',
                  backgroundColor:
                    selectRange === option.value
                      ? 'background.paper'
                      : 'background.paper2',
                }}
                onClick={() => handleRange(option.value)}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight:
                      selectRange === option.value ? 'bold' : 'inherit',
                  }}
                >
                  {t(`${option.label}`, {
                    ns: 'dateRange',
                  })}{' '}
                  {option.label === 'ALL' || option.label === 'CUSTOM'
                    ? ''
                    : t('DAYS', {
                        ns: 'dateRange',
                      })}
                </Typography>
              </Box>
            )
        )}
      </Box>
    </Box>
  )
}

export default SourcesLineChartTravellersSelect
