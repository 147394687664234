import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { getPersistedItem } from 'common/helpers'
import { useAppDispatch } from 'store/redux-hooks'
import { setAuth } from 'store/features/auth-slice'

const RequireAuth = ({ children }: any) => {
  const dispatch = useAppDispatch()
  const persistedAuth = getPersistedItem('auth')

  useEffect(() => {
    dispatch(setAuth(persistedAuth))
    // eslint-disable-next-line
  }, [])

  if (!persistedAuth?.accessToken) {
    return <Navigate to="/login" replace />
  }

  return children
}

export default RequireAuth
