import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'

import LogoLargeLight from 'assets/logo-large-light.png'
import LogoLargeDark from 'assets/logo-large-dark.png'
import LoginBackground from 'assets/login-background.jpg'

import { MODE, STATUS } from 'common/constants'
import { HOME } from 'common/constants-navigation'
import { getPersistedItem, persistItem } from 'common/helpers'

import { authenticate, selectAuth, setAuth } from 'store/features/auth-slice'
import { useAppDispatch, useAppSelector } from 'store/redux-hooks'

import { Footer } from 'layout/footer'

import {
  Check,
  Person,
  Security,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Slide,
  Typography,
  useTheme,
} from '@mui/material'

import {
  ButtonsWrapper,
  CustomButton,
  CustomCheckBox,
  CustomWrapper,
  ImageWrapper,
  LoginBoxWrapper,
  LoginPageWrapper,
  LoginSectionWrapper,
  LoginTextFiled,
  LoginWrapper,
  RememberMeWrapper,
} from './styles'

const bookDemoAddress =
  'https://meetings-eu1.hubspot.com/kim-schubert?uuid=ae50e591-2eab-4289-9546-473969099bc1'

const Login = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { value: authValue, status, errorMessage } = useAppSelector(selectAuth)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [hasAuthError, setHasAuthError] = useState(false)
  const [isRememberMe, setIsRememberMe] = useState(false)
  const [form, setForm] = useState({
    email: '',
    password: '',
  })

  useEffect(() => {
    if (authValue.accessToken) {
      persistItem('auth', authValue, isRememberMe)
      navigate(HOME.address, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authValue])

  useEffect(() => {
    const persistedAuth = getPersistedItem('auth')

    if (persistedAuth?.accessToken) {
      dispatch(setAuth(persistedAuth))
      navigate(HOME.address)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (status === STATUS.rejected) {
      setHasAuthError(true)
    }
  }, [status])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const credential = {
      email: (data.get('email') as string) || '',
      password: (data.get('password') as string) || '',
    }

    dispatch(authenticate(credential))
  }

  const onFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event
    setForm({ ...form, [name]: value })

    if (hasAuthError) setHasAuthError(false)
  }

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev)
  }

  const toggleRememberMe = () => {
    setIsRememberMe((prev) => !prev)
    TagManager.dataLayer({
      dataLayer: {
        event: 'remember_me',
      },
    })
  }

  const onBookDemo = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'book_demo',
      },
    })
  }

  const handleErrorMessage = (error: string) => {
    if (error.includes('401') || error.includes('403')) {
      return t('WRONG_CREDENTIAL_TEXT', { ns: 'common' })
    }
    if (error.includes('429')) {
      return t('TOO_MANY_REQUEST_TEXT', { ns: 'common' })
    }
    return t('SOMETHING_WENT_WRONG', { ns: 'common' })
  }

  const renderStatusMessage = () => {
    if (status === STATUS.pending) {
      return (
        <Typography>
          <CircularProgress size="1rem" sx={{ mr: 1 }} />
          {t('PLEASE_WAIT', { ns: 'common' })}
        </Typography>
      )
    }
    if (hasAuthError) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'login_failed',
        },
      })
      return (
        <Typography sx={{ color: theme.palette.error.main }}>
          {handleErrorMessage(errorMessage)}
        </Typography>
      )
    }
    return <Typography>{t('WELCOME_BACK', { ns: 'common' })}</Typography>
  }

  if (status === STATUS.fulfilled) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'login_success',
      },
    })
    return null
  }

  return (
    <LoginPageWrapper>
      <LoginSectionWrapper>
        <Slide in timeout={1000}>
          <LoginBoxWrapper>
            <ImageWrapper>
              <img
                src={LoginBackground}
                alt="company logo"
                style={{
                  width: 'auto',
                  height: '456px',
                }}
              />
            </ImageWrapper>
            <LoginWrapper>
              <Box
                mb={6}
                sx={{
                  maxWidth: '68px',
                  display: { xs: 'none', lg: 'block' },
                }}
              >
                <img
                  src={
                    theme.palette.mode === MODE.light
                      ? LogoLargeLight
                      : LogoLargeDark
                  }
                  alt="company logo"
                  style={{
                    height: '16px',
                    width: 'auto',
                  }}
                />
              </Box>

              <Typography variant="h4" sx={{ fontWeight: 'bold', pb: 1 }}>
                {t('LOG_IN_TEXT', { ns: 'common' })}
              </Typography>

              <Box>{renderStatusMessage()}</Box>
              <Box component="form" onSubmit={handleSubmit} sx={{ pt: 3 }}>
                <LoginTextFiled
                  id="email"
                  name="email"
                  value={form.email}
                  placeholder={t('ENTER_YOUR_EMAIL', { ns: 'common' })}
                  required
                  fullWidth
                  margin="dense"
                  autoComplete="email"
                  onChange={onFormChange}
                  hasAuthError={hasAuthError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person sx={{ color: theme.palette.text.secondary }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <LoginTextFiled
                  id="password"
                  name="password"
                  value={form.password}
                  placeholder={t('ENTER_YOUR_PASSWORD', { ns: 'common' })}
                  required
                  fullWidth
                  margin="dense"
                  type={isPasswordVisible ? 'text' : 'password'}
                  autoComplete="current-password"
                  onChange={onFormChange}
                  hasAuthError={hasAuthError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Security
                          sx={{ color: theme.palette.text.secondary }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          sx={{
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {isPasswordVisible ? (
                            <VisibilityOutlined />
                          ) : (
                            <VisibilityOffOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomWrapper>
                  <RememberMeWrapper onClick={toggleRememberMe}>
                    <CustomCheckBox>
                      {isRememberMe && (
                        <Check
                          sx={{
                            fontSize: theme.typography.body2.fontSize,
                            opacity: isRememberMe ? 1 : 0,
                          }}
                        />
                      )}
                    </CustomCheckBox>
                    <Typography variant="body1">
                      {t('REMEMBER_ME', { ns: 'common' })}
                    </Typography>
                  </RememberMeWrapper>
                  {/* <Link
                  to="#"
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {t('FORGOT_PASSWORD', { ns: 'common' })}
                </Link> */}
                </CustomWrapper>

                <ButtonsWrapper onClick={onBookDemo}>
                  <Link
                    to={bookDemoAddress}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <CustomButton variant="outlined">
                      {t('BOOK_DEMO', { ns: 'common' })}
                    </CustomButton>
                  </Link>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ fontWeight: 'bold' }}
                    disabled={
                      !form.email || !form.password || status === STATUS.pending
                    }
                  >
                    {t('LOGIN', { ns: 'common' })}
                  </CustomButton>
                </ButtonsWrapper>
              </Box>
            </LoginWrapper>
          </LoginBoxWrapper>
        </Slide>
      </LoginSectionWrapper>
      <Box sx={{ width: '100%' }}>
        <Footer />
      </Box>
    </LoginPageWrapper>
  )
}

export default Login
