import { useTranslation } from 'react-i18next'

import DateCompareInfoIcon from './date-compare-info-icon'

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography,
} from '@mui/material'

interface DateCompareOptionProps {
  monthToCompareOption: 'PREVIOUS_MONTH' | 'SAME_MONTH_PREVIOUS_YEAR'
  handleMonthToCompareOption: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
}

const DateCompareOption: React.FC<DateCompareOptionProps> = ({
  monthToCompareOption,
  handleMonthToCompareOption,
}) => {
  const { t } = useTranslation()
  const monthCompareWithInfoText = {
    title: t('MONTH_COMPARE_WITH', {
      ns: 'filter',
    }),
    text: t('MONTH_COMPARE_WITH_INFO_TEXT', {
      ns: 'filter',
    }),
  }
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="caption"
          sx={{
            wordSpacing: '0.1em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {t('MONTH_COMPARE_WITH', {
            ns: 'filter',
          })}
        </Typography>
        <DateCompareInfoIcon
          title={monthCompareWithInfoText.title}
          text={monthCompareWithInfoText.text}
        />
      </Box>

      <RadioGroup
        aria-label="options"
        name="options"
        value={monthToCompareOption}
        onChange={handleMonthToCompareOption}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
          <FormControlLabel
            value="PREVIOUS_MONTH"
            control={<Radio disableRipple size="small" />}
            label={t('PREVIOUS_MONTH', {
              ns: 'filter',
            })}
          />
          <FormControlLabel
            value="SAME_MONTH_PREVIOUS_YEAR"
            control={<Radio disableRipple size="small" />}
            label={t('SAME_MONTH_PREVIOUS_YEAR', {
              ns: 'filter',
            })}
          />
        </Box>
      </RadioGroup>
    </Box>
  )
}

export default DateCompareOption
