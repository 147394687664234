import {
  subDays,
  subWeeks,
  subMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfDay,
  format,
  Locale,
} from 'date-fns'
import { DateRange } from 'react-day-picker'

export const dateRangePresetOptions = [
  'YESTERDAY',
  'LAST_7_DAYS',
  'LAST_14_DAYS',
  'LAST_30_DAYS',
  'LAST_WEEK',
  'CURRENT_MONTH',
  'LAST_MONTH',
  'LAST_3_MONTHS',
  'LAST_6_MONTHS',
  'LAST_12_MONTHS',
  'CURRENT_YEAR',
]

export const getPresetDateRange = (value: string): DateRange => {
  const today = new Date()
  const yesterday = subDays(today, 1)
  const startOfCurrentMonth = startOfMonth(today)

  switch (value) {
    case 'Yesterday':
      return { from: yesterday, to: yesterday }
    case 'LAST_7_DAYS':
      return { from: subDays(today, 7), to: yesterday }
    case 'LAST_14_DAYS':
      return { from: subDays(today, 14), to: yesterday }
    case 'LAST_30_DAYS':
      return { from: subDays(today, 30), to: yesterday }
    case 'LAST_WEEK':
      return {
        from: startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
        to: endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
      }
    case 'CURRENT_MONTH':
      return { from: startOfCurrentMonth, to: endOfDay(today) }
    case 'LAST_MONTH':
      return {
        from: startOfMonth(subMonths(today, 1)),
        to: endOfMonth(subMonths(today, 1)),
      }
    case 'LAST_3_MONTHS':
      return {
        from: startOfMonth(subMonths(today, 3)),
        to: endOfMonth(subMonths(today, 1)),
      }
    case 'LAST_6_MONTHS':
      return {
        from: startOfMonth(subMonths(today, 6)),
        to: endOfMonth(subMonths(today, 1)),
      }
    case 'LAST_12_MONTHS':
      return {
        from: startOfMonth(subMonths(today, 12)),
        to: endOfMonth(subMonths(today, 1)),
      }
    case 'CURRENT_YEAR':
      return { from: startOfYear(yesterday), to: endOfDay(yesterday) }
    default:
      return { from: yesterday, to: yesterday }
  }
}

export const formatDateRange = (
  range: DateRange | undefined,
  locale: Locale
): string => {
  const dateFormat = `d MMMM yyyy`
  if (!range?.from) return ''
  return `${format(range.from, dateFormat, { locale })} - ${range.to ? format(range.to, dateFormat, { locale }) : ''}`
}

export const serializeDateRange = (range: DateRange | undefined): string => {
  if (!range) {
    return JSON.stringify({ from: null, to: null })
  }
  return JSON.stringify({
    from: range.from ? range.from.toISOString() : null,
    to: range.to ? range.to.toISOString() : null,
  })
}

export const deserializeDateRange = (rangeString: string): DateRange => {
  const parsed = JSON.parse(rangeString)
  return {
    from: parsed.from ? new Date(parsed.from) : undefined,
    to: parsed.to ? new Date(parsed.to) : undefined,
  }
}

export const getDateRange = (startDate: Date, endDate: Date, data: any[]) => {
  const dateMap = new Map()

  data.forEach((item: { date: Date }) => {
    dateMap.set(item.date, item)
  })

  const datesInRange = []
  const currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    const date = formatDateToYearMonthDayString(currentDate)
    const dataPoint = dateMap.get(date)
    datesInRange.push(dataPoint || { date, noData: true })
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return datesInRange
}

export const formatDateToYearMonthDayString = (date?: Date): string | null =>
  date ? format(date, 'yyyy-MM-dd') : null

export const formatDatesToString = (inputDates?: {
  from?: Date
  to?: Date
}) => ({
  from: formatDateToYearMonthDayString(inputDates?.from),
  to: formatDateToYearMonthDayString(inputDates?.to),
})
