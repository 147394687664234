import { useTranslation } from 'react-i18next'

import AdvancedFilterSet from './advanced-filter-set'

import { AddCircle } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

interface FilterRow {
  [key: string]: {
    id: string
    filterType: string
    filterValue: string
  }
}

interface ConditionSet {
  id: string
  or: FilterRow[]
}

const AdvancedFilter = ({
  dimensionId,
  conditionSets,
  handleAddSet,
  handleRemoveSet,
  handleUpdateSet,
}: {
  dimensionId: string
  conditionSets: ConditionSet[]
  handleAddSet: any
  handleRemoveSet: any
  handleUpdateSet: any
}) => {
  const { t } = useTranslation()
  const isMoreThanOneSet = conditionSets.length > 1

  return (
    <Box sx={{ pt: 3.5 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            wordSpacing: '0.1em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {t('ELEMENTS_IF_THE_FOLLOWING_APPLIES', {
            ns: 'filter',
          })}
        </Typography>
        <Box
          sx={{
            maxHeight: '350px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1.5,
          }}
        >
          {conditionSets.map((set) => (
            <AdvancedFilterSet
              key={set.id}
              dimensionId={dimensionId}
              setId={set.id}
              isMoreThanOneSet={isMoreThanOneSet}
              rowDetails={set.or}
              handleRemoveSet={handleRemoveSet}
              handleUpdateSet={handleUpdateSet}
            />
          ))}
          <Button
            onClick={handleAddSet}
            variant="contained"
            startIcon={<AddCircle />}
            sx={{
              borderRadius: 5,
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            {t('ADD', {
              ns: 'common',
            })}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AdvancedFilter
