import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AdvancedFilterRow from './advanced-filter-row'

import { Delete } from '@mui/icons-material'
import { Box, IconButton, Typography, useTheme } from '@mui/material'

import { AdvancedFilterSetWrapper } from './styles'

interface FilterRow {
  [key: string]: {
    id: string
    filterType: string
    filterValue: string
  }
}

const AdvancedFilterSet = ({
  dimensionId,
  setId,
  isMoreThanOneSet,
  rowDetails,
  handleRemoveSet,
  handleUpdateSet,
}: {
  dimensionId: string
  setId: string
  isMoreThanOneSet: boolean
  rowDetails: FilterRow[]
  handleRemoveSet: any
  handleUpdateSet: any
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [conditionRows, setConditionRows] = useState<FilterRow[]>(
    rowDetails.length === 0
      ? [
          {
            [dimensionId]: {
              id: `${Date.now()}-${Math.random()}`,
              filterType: 'contains',
              filterValue: '',
            },
          },
        ]
      : rowDetails
  )

  useEffect(() => {
    handleUpdateSet(setId, conditionRows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionRows])

  const handleAddConditionRow = () => {
    const newConditionRow: FilterRow = {
      [dimensionId]: {
        id: `${Date.now()}-${Math.random()}`,
        filterType: 'contains',
        filterValue: '',
      },
    }
    setConditionRows((prevRows) => [...prevRows, newConditionRow])
  }

  const handleRemoveConditionRow = (id: string) => {
    setConditionRows((prevRows) =>
      prevRows.filter((row) => row[dimensionId].id !== id)
    )
  }

  const handleConditionChange = (id: string, filterType: string) => {
    setConditionRows((prevRows) =>
      prevRows.map((row) =>
        row[dimensionId].id === id
          ? { ...row, [dimensionId]: { ...row[dimensionId], filterType } }
          : row
      )
    )
  }

  const handleValueChange = (id: string, filterValue: string) => {
    setConditionRows((prevRows) =>
      prevRows.map((row) =>
        row[dimensionId].id === id
          ? {
              ...row,
              [dimensionId]: {
                ...row[dimensionId],
                filterValue,
              },
            }
          : row
      )
    )
  }

  return (
    <AdvancedFilterSetWrapper>
      {isMoreThanOneSet && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: '-12px' }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            {t('AND', {
              ns: 'filter',
            })}
          </Typography>

          <IconButton
            onClick={() => handleRemoveSet(setId)}
            sx={{ color: theme.palette.text.legend }}
          >
            <Delete />
          </IconButton>
        </Box>
      )}

      {conditionRows.map((row, index) => (
        <AdvancedFilterRow
          key={row[dimensionId].id}
          isLastRow={conditionRows.length - 1 === index}
          rowId={row[dimensionId].id}
          rowFilterDetails={row[dimensionId]}
          handleAddConditionRow={handleAddConditionRow}
          handleRemoveConditionRow={handleRemoveConditionRow}
          handleConditionChange={handleConditionChange}
          handleValueChange={handleValueChange}
          allowRemove={conditionRows.length > 1}
        />
      ))}
    </AdvancedFilterSetWrapper>
  )
}

export default AdvancedFilterSet
