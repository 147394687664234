import axios from 'axios'
import { getIsRememberMe, getPersistedItem, persistItem } from 'common/helpers'

const { REACT_APP_ORBIT_ADS_URL } = process.env

const getConfig = (token: string) => {
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  }
  return config
}

export const API = axios.create({
  baseURL: REACT_APP_ORBIT_ADS_URL,
  withCredentials: true,
})

export const AuthorizeAPI = axios.create({
  baseURL: REACT_APP_ORBIT_ADS_URL,
  withCredentials: true,
})

export const RefreshTokenAPI = axios.create({
  baseURL: REACT_APP_ORBIT_ADS_URL,
  withCredentials: true,
})

API.interceptors.request.use(
  (config) => {
    const persistedAuth = getPersistedItem('auth')
    const token = persistedAuth?.accessToken

    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      try {
        const res = await RefreshTokenAPI.post('/api/user/refresh')

        const newAccessToken =
          res.headers['authorization'] || res.headers['Authorization']

        const persistedAuth = getPersistedItem('auth') || {}
        const isRememberMe = getIsRememberMe()
        persistItem(
          'auth',
          {
            ...persistedAuth,
            accessToken: newAccessToken,
          },
          isRememberMe
        )

        originalRequest.headers['Authorization'] = newAccessToken

        return API(originalRequest)
      } catch (refreshError) {
        localStorage.removeItem('auth')
        localStorage.removeItem('isRememberMe')
        sessionStorage.removeItem('auth')
        window.location.href = '/login'
      }
    }

    return Promise.reject(error)
  }
)

export const authenticateUser = async ({
  email,
  password,
}: {
  email: string
  password: string
}) => {
  return AuthorizeAPI.post('/api/user/login', { email, password })
}

export const getCustomersDetails = (accessToken: string) => {
  const config = getConfig(accessToken)
  return API.get('/api/customer/list', config)
}

export const getChartDetails = ({ payload }: { payload: any }) => {
  return API.post('/api', payload)
}

export const getDimensionsByFilter = ({ payload }: { payload: any }) => {
  return API.post('/api/filter', payload)
}
