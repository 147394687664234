import { MouseEvent, useState } from 'react'

import { Box, IconButton, Popover, Typography, useTheme } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

const SourcesLineChartInfoButton = () => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'pro-popover' : undefined

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-4px',
        right: '-28px',
      }}
    >
      <IconButton aria-describedby={id} onClick={handleClick} disableRipple>
        <InfoOutlined
          sx={{ fontSize: '16px', color: theme.palette.text.legend }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: 3,
            boxShadow: theme.palette.customShadow.popover,
          },
        }}
      >
        <Box sx={{ width: '320px', p: 2, backgroundColor: 'background.paper' }}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Ich bin ein Infotextkasten
          </Typography>
          <Typography sx={{ pt: 2 }}>
            Ich erkläre Dir hier die Funktionsweise des Zoom-Slicers :D
          </Typography>
        </Box>
      </Popover>
    </Box>
  )
}

export default SourcesLineChartInfoButton
