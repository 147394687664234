import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { de, enUS } from 'date-fns/locale'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectModal, setModal } from 'store/features/modal-slice'
import {
  resetFilters,
  selectFilter,
  setFilter,
} from 'store/features/filter-slice'

import { FILTER_MODAL } from 'common/constants'
import { deserializeDateRange, formatDateRange } from 'common/helpers-date'

import {
  FilterDimension,
  dateFilterSection,
  dimensionFilterSections,
} from 'components/filter-modal/filter-modal'
import { FilterModal } from 'components/filter-modal'

import { Add, CalendarMonth, Clear, DeleteOutline } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'

import { CustomChip, FilterSectionWrapper } from './styles'

interface FilterRow {
  [key: string]: {
    id: string
    filterType: string
    filterValue: string
  }
}

interface ConditionSet {
  id: string
  or: FilterRow[]
}

const SourcesBarChartFilter = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]
  const locale = currentlanguage === 'de' ? de : enUS

  const savedFilter = useAppSelector(selectFilter)
  const {
    value: { type: modalType },
  } = useAppSelector(selectModal)

  const isReportingUnit = true
  const isFilterModalOpen = modalType === FILTER_MODAL
  const [filterSectionInView, setFilterSectionInView] =
    useState<FilterDimension>(dimensionFilterSections[0])

  const basicFilteredDimensions = Object.fromEntries(
    Object.entries(savedFilter.basic).filter(
      ([_, dimensionvalue]: [dimensionName: any, dimensionvalue: any]) =>
        dimensionvalue.include.length !== 0
    )
  )

  const advancedFilteredDimensions = Object.entries(
    savedFilter.advanced
  ).filter(([_, filters]) =>
    (filters as ConditionSet[]).some((filterGroup) =>
      filterGroup.or.some((filterObj) =>
        Object.values(filterObj as FilterRow).some(
          (filter) => filter.filterValue.trim() !== ''
        )
      )
    )
  )

  const basicFilteredDimensionsList = Object.keys(basicFilteredDimensions).map(
    (dimension) => dimension
  )

  const advancedFilteredDimensionsList = advancedFilteredDimensions.map(
    ([dimension]) => dimension
  )

  const filteredDimensions = [
    ...basicFilteredDimensionsList,
    ...advancedFilteredDimensionsList,
  ]

  const openFilterModal = (filterSection: FilterDimension) => {
    setFilterSectionInView(filterSection)
    dispatch(setModal({ type: FILTER_MODAL, activeComponent: '' }))
  }

  const resetFilter = () => {
    dispatch(resetFilters())
  }

  const resetDimnesionFilter = (dimensionId: string) => {
    const resetBasicFilter = {
      filterType: 'excl',
      filterValue: [],
      include: [],
      exclude: [],
    }

    const updateFilter = {
      ...savedFilter,
      basic: {
        ...savedFilter.basic,
        [dimensionId]: resetBasicFilter,
      },
      advanced: {
        ...savedFilter.advanced,
        [dimensionId]: [
          {
            id: `${Date.now()}-${Math.random()}`,
            or: [],
          },
        ],
      },
    }

    dispatch(setFilter(updateFilter))
  }

  return (
    <>
      <FilterSectionWrapper>
        <Box sx={{ minWidth: '100px' }}>
          <Typography
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {t('FILTER', {
              ns: 'filter',
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {filteredDimensions.map((dimension) => (
              <Box key={dimension} sx={{ position: 'relative' }}>
                <CustomChip
                  onClick={() =>
                    openFilterModal(
                      dimensionFilterSections.find(
                        (section) => section.value === dimension
                      ) || dimensionFilterSections[0]
                    )
                  }
                >
                  <Typography variant="body2" noWrap sx={{ pr: 4 }}>
                    {t(
                      `${
                        dimensionFilterSections.find(
                          (obj) => obj.value === dimension
                        )?.title || dimensionFilterSections[0].title
                      }`,
                      {
                        ns: 'filter',
                      }
                    )}
                  </Typography>
                </CustomChip>
                <Clear
                  onClick={() => resetDimnesionFilter(dimension)}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 12,
                    color: theme.palette.text.legend,
                    cursor: 'pointer',
                  }}
                />
              </Box>
            ))}
            <CustomChip
              onClick={() =>
                openFilterModal(
                  isReportingUnit
                    ? dimensionFilterSections[0]
                    : dimensionFilterSections[1]
                )
              }
              sx={{
                border: `1px solid ${theme.palette.text.legend}`,
                bgcolor: theme.palette.background.paper,
              }}
            >
              <Typography
                variant="body2"
                color={theme.palette.text.legend}
                noWrap
              >
                {t('ADD_FILTER', {
                  ns: 'filter',
                })}
              </Typography>
              <Add sx={{ color: theme.palette.text.legend }} />
            </CustomChip>
          </Box>
          <Box display="flex" gap={1}>
            <CustomChip onClick={() => openFilterModal(dateFilterSection)}>
              <Typography variant="body2" noWrap>
                {savedFilter.date.isCustomDateRange
                  ? formatDateRange(
                      deserializeDateRange(savedFilter.date.range),
                      locale
                    )
                  : t(`${savedFilter.date.label}`, {
                      ns: 'dateRange',
                    })}
              </Typography>
              <CalendarMonth sx={{ color: theme.palette.text.legend }} />
            </CustomChip>
            <CustomChip onClick={resetFilter}>
              <Typography variant="body2" noWrap>
                {t('RESET_ALL', {
                  ns: 'filter',
                })}
              </Typography>
              <DeleteOutline sx={{ color: theme.palette.text.legend }} />
            </CustomChip>
          </Box>
        </Box>
      </FilterSectionWrapper>
      {isFilterModalOpen && (
        <FilterModal
          open
          filterSectionInView={filterSectionInView}
          isReportingUnit={isReportingUnit}
        />
      )}
    </>
  )
}

export default SourcesBarChartFilter
