import { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'
import { selectFilter } from 'store/features/filter-slice'
import {
  fetchBarChartDetails,
  fetchLineChartDetails,
  selectChartDetails,
} from 'store/features/chart-details-slice'

import { FeatureType, STATUS } from 'common/constants'
import {
  deserializeDateRange,
  formatDateToYearMonthDayString,
  getDateRange,
} from 'common/helpers-date'

import SourcesBarChartFilter from 'components/sources-bar-chart/sources-bar-chart-filter'
import SourcesBarChartDimension from 'components/sources-bar-chart/sources-bar-chart-dimension'
import {
  SourcesBarChart,
  SourceBarChartNoData,
} from 'components/sources-bar-chart'
import {
  SourcesLineChart,
  SourceLineChartNoData,
} from 'components/sources-line-chart'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material'

interface Filter {
  filterType: string
  filterValue: string[]
  [key: string]: any
}

interface FilterRow {
  [key: string]: {
    id: string
    filterType: string
    filterValue: string
  }
}

interface ConditionSet {
  id: string
  or: FilterRow[]
}

const SourcesPage = ({
  kpiPath: { kpi1, kpi2, kpi3 },
  reportType,
  sourceName,
}: any) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const {
    value: { accessToken, currentCustomer },
  } = useAppSelector(selectAuth)

  const {
    basic,
    advanced,
    date: { range },
  } = useAppSelector(selectFilter)

  const { from, to } = deserializeDateRange(range)

  const {
    value: { lineChart, barChart },
    lineChartStatus,
    barChartStatus,
  } = useAppSelector(selectChartDetails)

  const [dimensionFilter, setDimensionFilter] = useState(
    FeatureType.TrafficSource
  )

  const isDimentionCampaignType = dimensionFilter === FeatureType.CampaignType

  const startDate = from ? formatDateToYearMonthDayString(from) : ''
  const endDate = to ? formatDateToYearMonthDayString(to) : ''

  const barChartData = barChart?.data || []

  const apiLineChartData = lineChart?.data || []

  const lineChartDataSortedByDate =
    apiLineChartData.length > 0
      ? [...apiLineChartData].sort(
          (a: { date: string }, b: { date: string }) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime()
          }
        )
      : []

  const lineChartData =
    lineChartDataSortedByDate.length > 0 && from && to
      ? getDateRange(from, to, lineChartDataSortedByDate)
      : []

  const basicFilteredDimensions = Object.fromEntries(
    Object.entries(basic).filter(
      ([_, dimensionvalue]: [dimensionName: any, dimensionvalue: any]) =>
        dimensionvalue.include.length !== 0
    )
  )

  const basicFilter = Object.fromEntries(
    Object.entries(basicFilteredDimensions as Filter).map(([key, filter]) => [
      key,
      {
        filterType: filter.filterType,
        filterValue: filter.filterValue,
      },
    ])
  )

  const flatTemporaryFilterAdvanced = Object.values(advanced).flatMap(
    (entryArray) => entryArray
  )

  const advancedFilter = (flatTemporaryFilterAdvanced as ConditionSet[]).filter(
    (item) =>
      item.or.some((subItem) => {
        const filterValue = Object.values(subItem)[0]?.filterValue
        return filterValue && filterValue.length > 0
      })
  )

  const payloadForBarChart = {
    customerId: currentCustomer?.customerId,
    source: {
      name: sourceName,
      dimension:
        dimensionFilter === FeatureType.CampaignType
          ? [FeatureType.TrafficSource, FeatureType.CampaignType]
          : [FeatureType.TrafficSource, FeatureType.ReportingUnit],
      metric: [kpi1.id, kpi2.id],
    },
    filter: {
      basic: {
        report_type: {
          filterType: 'incl',
          filterValue: [reportType],
        },
        ...basicFilter,
      },
      advanced: advancedFilter,
    },
    dateRange: {
      start: startDate,
      end: endDate,
      aggregation: 'day',
    },
  }

  const payloadForLineChart = {
    customerId: currentCustomer?.customerId,
    source: {
      name: sourceName,
      dimension: [FeatureType.Date],
      metric: [kpi1.id, kpi2.id, kpi3.id],
    },
    filter: {
      basic: {
        report_type: {
          filterType: 'incl',
          filterValue: [reportType],
        },
        ...basicFilter,
      },
      advanced: advancedFilter,
    },
    dateRange: {
      start: startDate,
      end: endDate,
      aggregation: 'day',
    },
  }

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchBarChartDetails({ payload: payloadForBarChart }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCustomer,
    accessToken,
    kpi1,
    kpi2,
    kpi3,
    startDate,
    endDate,
    isDimentionCampaignType,
    basic,
    advanced,
  ])

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchLineChartDetails({ payload: payloadForLineChart }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCustomer,
    accessToken,
    kpi1,
    kpi2,
    kpi3,
    startDate,
    endDate,
    isDimentionCampaignType,
    basic,
    advanced,
  ])

  const handleChartDetails = () => {
    dispatch(fetchBarChartDetails({ payload: payloadForBarChart }))
    dispatch(fetchLineChartDetails({ payload: payloadForLineChart }))
  }

  const handleDimensionFilter = (
    event: MouseEvent<HTMLButtonElement>,
    value: FeatureType
  ) => {
    if (value) {
      setDimensionFilter(value)
    }
  }

  if (lineChartStatus === STATUS.pending || barChartStatus === STATUS.pending) {
    return <LinearProgress sx={{ borderRadius: 2 }} />
  }

  if (
    lineChartStatus === STATUS.rejected ||
    barChartStatus === STATUS.rejected
  ) {
    return (
      <Alert severity="error" sx={{ borderRadius: 3, padding: 2 }}>
        <AlertTitle sx={{ fontWeight: 'bold' }}>
          {t('PAGE_NOT_LOADIND_ERROR_TITLE', { ns: 'common' })}
        </AlertTitle>
        <Typography>
          {t('PAGE_NOT_LOADIND_ERROR_MESSAGE', { ns: 'common' })}
        </Typography>
        <Button
          onClick={handleChartDetails}
          variant="contained"
          sx={{
            minWidth: '136px',
            height: '40px',
            borderRadius: 2,
            fontWeight: 'bold',
            textTransform: 'capitalize',
            mt: 3,
          }}
        >
          {t('TRY_AGAIN', { ns: 'common' })}
        </Button>
      </Alert>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mx: { xs: 2, md: 0 },
        mb: 3,
      }}
    >
      <SourcesBarChartFilter />
      <SourcesBarChartDimension
        dimensionFilter={dimensionFilter}
        handleDimensionFilter={handleDimensionFilter}
      />
      {barChartData.length > 0 ? (
        <SourcesBarChart
          chartData={barChartData}
          kpi1={kpi1}
          kpi2={kpi2}
          kpi3={kpi3}
          dimensionFilter={dimensionFilter}
        />
      ) : (
        <SourceBarChartNoData
          titleKpi1={kpi1.title}
          titleKpi2={kpi2.title}
          titleKpi3={kpi3.title}
        />
      )}
      {lineChartData.length > 0 ? (
        <SourcesLineChart
          chartData={lineChartData}
          kpi1={kpi1}
          kpi2={kpi2}
          kpi3={kpi3}
        />
      ) : (
        <SourceLineChartNoData
          titleKpi1={kpi1.title}
          titleKpi2={kpi2.title}
          titleKpi3={kpi3.title}
        />
      )}
    </Box>
  )
}

export default SourcesPage
