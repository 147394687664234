import i18next from 'i18next'
import { parseISO, format, Locale } from 'date-fns'

import { getUserCurrency } from './helpers'
import { KPI_FORMAT } from './constants'

export const formatDateTick = (
  tickItem: string,
  leftIndex: number,
  rightIndex: number,
  locale: Locale
) => {
  const date = parseISO(tickItem)
  const dateRange = rightIndex - leftIndex
  const dateFormat = dateRange < 100 ? `dd MMM yyyy` : `MMM yyyy`
  return format(date, dateFormat, { locale })
}

export const formatNumberTick = (tickItem: number) => {
  if (typeof tickItem !== 'number' || (tickItem !== 0 && !tickItem)) {
    return '-'
  }
  if (tickItem < 1e3) {
    return tickItem.toFixed(0)
  }
  if (tickItem < 1e6) {
    return `${Math.ceil(tickItem / 1e3).toFixed()}${i18next.t('THOUSAND_SUFFIX')}`
  }
  if (tickItem < 1e9) {
    return `${(tickItem / 1e6).toFixed(0)}${i18next.t('MILLION_SUFFIX')}`
  }
  return `${(tickItem / 1e9).toFixed(0)}${i18next.t('BILLION_SUFFIX')}`
}

const formatCurrencyTick = (tickItem: number, currency: string) => {
  return `${formatNumberTick(tickItem)}${getUserCurrency(currency)}`
}

export const formatPercentTick = (
  tickItem: number,
  currentlanguage?: string
) =>
  (tickItem !== 0 && !tickItem) || typeof tickItem === 'string'
    ? '- %'
    : `${(tickItem * 100).toLocaleString(currentlanguage, {
        maximumFractionDigits: 2,
      })}%`

const formatTimeTick = (tickItem: number) => {
  const sec = Math.floor(tickItem % 60)
  const min = Math.floor((tickItem / 60) % 60)
  const hours = Math.floor(tickItem / 3600)

  if (hours > 99) {
    return `${hours}h`
  }
  if (hours > 0) {
    return `${hours}:${min < 10 ? `0${min}` : min}h`
  }
  if (min > 0) {
    return `${min}:${sec < 10 ? `0${sec}` : sec}m`
  }

  return `${sec < 10 ? `0${sec}` : sec}s`
}

export const getFormat = (
  kpiFormat: string,
  currency: string,
  currentlanguage?: string
) => {
  switch (kpiFormat) {
    case KPI_FORMAT.integer:
      return (tickItem: number) => formatNumberTick(tickItem)
    case KPI_FORMAT.percent:
      return (tickItem: number) => formatPercentTick(tickItem, currentlanguage)
    case KPI_FORMAT.currency:
      return (tickItem: number) => formatCurrencyTick(tickItem, currency)
    case KPI_FORMAT.time:
      return (tickItem: number) => formatTimeTick(tickItem)
    default:
      return formatNumberTick
  }
}
