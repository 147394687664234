import { createSlice } from '@reduxjs/toolkit'
import { MODE } from 'common/constants'
import type { RootState } from 'store/store'

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    mode: MODE.light,
    isSidebarFixed: false,
    isGlobalView: false,
  },
  reducers: {
    setMode: (state, { payload }) => {
      state.mode = payload
    },
    setIsSidebarFixed: (state, { payload }) => {
      state.isSidebarFixed = payload
    },
    setIsGlobalView: (state, { payload }) => {
      state.isGlobalView = payload
    },
  },
})

export const selectTheme = (state: RootState) => state.theme

export const { setMode, setIsSidebarFixed, setIsGlobalView } =
  themeSlice.actions

export default themeSlice.reducer
