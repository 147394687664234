import { Link } from 'react-router-dom'

import LogoSmallLight from 'assets/logo-small-light.png'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectModal, setModal } from 'store/features/modal-slice'

import {
  ACCOUNT_MENU,
  CUSTOMER_SELECT,
  DRAWER,
  MOBILE_NAVIGATION,
  SIDEBAR_DRAWER_MOBILE,
} from 'common/constants'
import { HOME } from 'common/constants-navigation'

import { Navigation } from 'layout/navigation'
import HeaderPanel from 'layout/header/header-panel'
import { UserMenu } from 'layout/header/header-user'
import { CustomerSearchSelect } from 'layout/header/header-customer'

import { ChevronLeft, CloseOutlined } from '@mui/icons-material'
import { Box, Drawer, IconButton } from '@mui/material'

import { DrawerContainer, SidebarMobileHeaderWrapper } from './styles'

const SidebarMobile = () => {
  const dispatch = useAppDispatch()

  const {
    value: { type: modalType, activeComponent: modalActiveComponent },
  } = useAppSelector(selectModal)

  const isOpenSidebarDrawerMobile = modalType === SIDEBAR_DRAWER_MOBILE

  const closeSidebarDrawerMobile = () => {
    dispatch(setModal({ type: '', activeComponent: '' }))
  }

  const handleSidebarDrawerMobileBack = () => {
    dispatch(
      setModal({
        type: SIDEBAR_DRAWER_MOBILE,
        activeComponent: MOBILE_NAVIGATION,
      })
    )
  }

  const isMobileNavigation = modalActiveComponent === MOBILE_NAVIGATION
  const isAccountMenu = modalActiveComponent === ACCOUNT_MENU
  const isCustomerSelect = modalActiveComponent === CUSTOMER_SELECT

  return (
    <div>
      <Drawer
        open={isOpenSidebarDrawerMobile}
        onClose={closeSidebarDrawerMobile}
      >
        <DrawerContainer>
          <SidebarMobileHeaderWrapper>
            <Link to={HOME.address} onClick={closeSidebarDrawerMobile}>
              <img
                src={LogoSmallLight}
                alt="company logo"
                style={{
                  width: `${DRAWER.WIDTH_LOGO_SMALL}px`,
                  height: 'auto',
                }}
              />
            </Link>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <HeaderPanel />
              <CloseOutlined
                onClick={closeSidebarDrawerMobile}
                sx={{ cursor: 'pointer' }}
              />
            </Box>
          </SidebarMobileHeaderWrapper>
          {isMobileNavigation ? (
            <Navigation isOpenSidebarDrawer={false} />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 2,
              }}
            >
              <Box>
                <IconButton onClick={handleSidebarDrawerMobileBack}>
                  <ChevronLeft />
                </IconButton>
              </Box>
              {isAccountMenu && <UserMenu />}
              {isCustomerSelect && <CustomerSearchSelect />}
            </Box>
          )}
        </DrawerContainer>
      </Drawer>
    </div>
  )
}

export default SidebarMobile
