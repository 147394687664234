import React from 'react'

import { format, parseISO } from 'date-fns'
import { enUS, de } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { TooltipProps } from 'recharts'

import { Kpi } from 'common/constants-metrics'

import { Box, Typography, useTheme } from '@mui/material'
import { getKpiValueAndSign } from 'common/helpers'

interface CustomTooltipProps extends TooltipProps<number, string> {
  kpi1: Kpi
  kpi2: Kpi
  kpi3: Kpi
  selectLines: { [key: string]: boolean }
  currency: string
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  kpi1,
  kpi2,
  kpi3,
  selectLines,
  currency,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]
  const locale = currentlanguage === 'de' ? de : enUS

  const kpiData1 = payload?.find((item) => item.dataKey === kpi1.id)?.value
  const kpiData2 = payload?.find((item) => item.dataKey === kpi2.id)?.value
  const kpiData3 = payload?.find((item) => item.dataKey === kpi3.id)?.value

  const isKpi1 = selectLines.isKpi1 ? selectLines.kpi1 : false
  const isKpi2 = selectLines.isKpi2 ? selectLines.kpi2 : false
  const isKpi3 = selectLines.isKpi3 ? selectLines.kpi3 : false
  const isAnyKpiAvailable = isKpi1 || isKpi2 || isKpi3
  const isTooltipAvailable = active && payload && payload.length

  const kpiData = [
    {
      key: 'kpi1',
      isAvailable: isKpi1,
      data: kpiData1,
      kpiFormat: kpi1.format,
      title: kpi1.title,
      color: 'primary',
    },
    {
      key: 'kpi2',
      isAvailable: isKpi2,
      data: kpiData2,
      kpiFormat: kpi2.format,
      title: kpi2.title,
      color: 'secondary',
    },
    {
      key: 'kpi3',
      isAvailable: isKpi3,
      data: kpiData3,
      kpiFormat: kpi3.format,
      title: kpi3.title,
      color: 'tertiary',
    },
  ] as const

  if (isTooltipAvailable) {
    const date = parseISO(label)
    const formattedDate = format(date, `EEEE, d MMMM yyyy`, {
      locale,
    })
    return (
      <Box
        sx={{
          width: '300px',
          p: 2,
          borderRadius: 2,
          bgcolor: theme.palette.background.paper3,
          boxShadow: theme.palette.customShadow.popover,
        }}
      >
        <Typography fontWeight={900}>{formattedDate}</Typography>
        {isAnyKpiAvailable && (
          <Box display="flex" flexDirection="column" mt={1}>
            {kpiData.map(
              ({ key, isAvailable, data, kpiFormat, title, color }) =>
                isAvailable && (
                  <Box key={key} display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box
                        minWidth="16px"
                        height="4px"
                        bgcolor={theme.palette[color].main}
                      />
                      <Typography variant="body2">
                        {t(`${title}`, { ns: 'kpi' })}:{' '}
                      </Typography>
                    </Box>
                    <Typography fontWeight={700}>
                      {getKpiValueAndSign(
                        data,
                        kpiFormat,
                        currentlanguage,
                        currency
                      )}
                    </Typography>
                  </Box>
                )
            )}
          </Box>
        )}
      </Box>
    )
  }

  return null
}

export default CustomTooltip
