import React from 'react'
import { useTranslation } from 'react-i18next'

import { Check, Close } from '@mui/icons-material'
import { Box, Tooltip, Typography, useTheme } from '@mui/material'

import { LegendWrapper } from './styles'
import SourcesLineChartInfoButton from './sources-line-chart-info-button'

interface Props {
  titleKpi1: string
  titleKpi2: string
  titleKpi3: string
  selectLines?: { [key: string]: boolean }
  handleSelectLine?: any
}

const SourcesLineChartLegend: React.FC<Props> = ({
  titleKpi1,
  titleKpi2,
  titleKpi3,
  selectLines,
  handleSelectLine,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const kpiData = [
    { key: 'kpi1', isAvailable: 'isKpi1', title: titleKpi1, color: 'primary' },
    {
      key: 'kpi2',
      isAvailable: 'isKpi2',
      title: titleKpi2,
      color: 'secondary',
    },
    { key: 'kpi3', isAvailable: 'isKpi3', title: titleKpi3, color: 'tertiary' },
  ] as const

  return (
    <LegendWrapper>
      <Box sx={{ position: 'relative', display: 'flex', mr: { sm: 3 } }}>
        <Typography variant="h5" fontWeight="bold">
          {t('DEVELOPMENT_OVER_TIME', { ns: 'common' })}
        </Typography>
        <SourcesLineChartInfoButton />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {kpiData.map(({ key, isAvailable, title, color }) => (
          <Tooltip
            key={key}
            title={
              !!selectLines && !selectLines[isAvailable] ? (
                <Box>
                  <Typography>{t('NO_RECORDS', { ns: 'common' })}</Typography>
                </Box>
              ) : undefined
            }
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  p: 2,
                  borderRadius: 3,
                  boxShadow: theme.palette.customShadow.popover,
                  '& .MuiTooltip-arrow': {
                    color: 'background.paper',
                  },
                },
              },
            }}
          >
            <Box
              onClick={() => (selectLines ? handleSelectLine(key) : null)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                cursor: 'pointer',
                color:
                  !!selectLines && selectLines[isAvailable]
                    ? theme.palette[color].main
                    : theme.palette.text.disabled,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: `1px solid ${
                    !!selectLines && selectLines[isAvailable]
                      ? theme.palette[color].main
                      : theme.palette.text.disabled
                  }`,

                  borderRadius: 1,
                }}
              >
                {!!selectLines && selectLines[isAvailable] ? (
                  <Check
                    sx={{
                      color: theme.palette[color].main,
                      opacity: !!selectLines && selectLines[key] ? 1 : 0,
                      fontSize: '12px',
                    }}
                  />
                ) : (
                  <Close
                    sx={{
                      color: theme.palette.text.disabled,
                      fontSize: '12px',
                    }}
                  />
                )}
              </Box>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.primary }}
              >
                {t(`${title}`, { ns: 'kpi' })}
              </Typography>
            </Box>
          </Tooltip>
        ))}
      </Box>
    </LegendWrapper>
  )
}

export default SourcesLineChartLegend
