import { Box, Typography } from '@mui/material'

const Explorer = () => {
  return (
    <Box p={4}>
      <Typography variant="h5" color="secondary">
        Explorer
      </Typography>
    </Box>
  )
}

export default Explorer
