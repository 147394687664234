import { useState } from 'react'

import { de, enUS } from 'date-fns/locale'
import { subDays, subMonths } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker'

import {
  getPresetDateRange,
  dateRangePresetOptions,
  deserializeDateRange,
  serializeDateRange,
  formatDateRange,
} from 'common/helpers-date'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Menu, Typography, useTheme } from '@mui/material'

import DatePickerStyle from './date-picker-style'
import { DateDropdownSelectWrapper } from './styles'

const DatePicker = ({
  temporaryFilter,
  handleTemporaryFilter,
}: {
  temporaryFilter: any
  handleTemporaryFilter: any
}) => {
  const theme = useTheme()

  const { t, i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]
  const locale = currentlanguage === 'de' ? de : enUS

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openDateRangeDropDown = Boolean(anchorEl)

  const today = new Date()
  const yesterday = subDays(today, 1)
  const previousMonth = subMonths(today, 1)
  const currentYear = today.getFullYear()

  const initialIsCustomRange = temporaryFilter.date.isCustomDateRange
  const initialDateRangeLabel = temporaryFilter.date.label
  const initialDateRangeValue = deserializeDateRange(temporaryFilter.date.range)

  const [range, setRange] = useState<DateRange | undefined>(
    initialDateRangeValue
  )

  const [month, setMonth] = useState(range?.from || previousMonth)

  const [isCustomRange, setIsCustomRange] =
    useState<boolean>(initialIsCustomRange)
  const [selectPresetRangeLabel, setSelecPresetRangeLabel] = useState(
    initialDateRangeLabel
  )

  const isSecondRange = false
  const isOnlyStartDate = !range?.to
  const isRangeSameDate = range
    ? range?.from?.getTime() === range?.to?.getTime()
    : false

  const updateFilterOptions = (
    isCustomDateRange: boolean,
    label: string | undefined,
    dateRange: DateRange | undefined
  ) => {
    setRange(dateRange)
    handleTemporaryFilter({
      ...temporaryFilter,
      date: {
        isCustomDateRange,
        label,
        range: serializeDateRange(dateRange),
      },
    })
  }
  const handlePresetSelectRange = (option: string) => {
    const dateRange = getPresetDateRange(option)
    updateFilterOptions(false, option, dateRange)
    setSelecPresetRangeLabel(option)
    setIsCustomRange(false)
    setAnchorEl(null)
  }

  const handleCustomSelectRange: SelectRangeEventHandler = (customRange) => {
    updateFilterOptions(true, undefined, customRange)
    setIsCustomRange(true)
  }

  const handleMonthChange = (newMonth: Date) => {
    setMonth(newMonth)
  }

  const handleOpenPresetSelectRange = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const closeDateRangeDropDown = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            wordSpacing: '0.1em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {t('SELECT_DATE_PERIOD', {
            ns: 'filter',
          })}
        </Typography>

        <DateDropdownSelectWrapper onClick={handleOpenPresetSelectRange}>
          {isCustomRange ? (
            <Box>
              {range ? (
                <Typography variant="body1">
                  {formatDateRange(range, locale)}
                </Typography>
              ) : (
                <Typography variant="body1">
                  {t('SELECT_DATE_PERIOD', {
                    ns: 'filter',
                  })}
                </Typography>
              )}
            </Box>
          ) : (
            t(`${selectPresetRangeLabel}`, {
              ns: 'dateRange',
            })
          )}

          {openDateRangeDropDown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </DateDropdownSelectWrapper>

        <Menu
          id="date-range-menu"
          anchorEl={anchorEl}
          open={openDateRangeDropDown}
          onClose={closeDateRangeDropDown}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: '638px',
                maxHeight: '284px',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                mt: '6px',
                px: 1.5,
                borderRadius: 4,
                boxShadow: theme.palette.customShadow.popover,
                bgcolor: theme.palette.background.paper2,
              },
            },
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          {dateRangePresetOptions.map((presetOption) => (
            <Box
              key={presetOption}
              onClick={() => handlePresetSelectRange(presetOption)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                py: 1,
                px: 2,
                my: 0.5,
                borderRadius: 2,
                cursor: 'pointer',
                color:
                  presetOption === selectPresetRangeLabel
                    ? theme.palette.primary.main
                    : 'inherit',
                ':hover': {
                  bgcolor: theme.palette.background.paper3,
                },
              }}
            >
              <Typography variant="body1">
                {t(`${presetOption}`, {
                  ns: 'dateRange',
                })}
              </Typography>
            </Box>
          ))}
        </Menu>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          mt: 3.5,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            wordSpacing: '0.1em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {isSecondRange
            ? t('FIRST_DATE_RANGE', {
                ns: 'filter',
              })
            : t('SELECT_DATE_RANGE', {
                ns: 'filter',
              })}
        </Typography>

        <DayPicker
          mode="range"
          numberOfMonths={2}
          month={month}
          selected={range}
          onSelect={handleCustomSelectRange}
          onMonthChange={handleMonthChange}
          className="custom-picker"
          disabled={{ after: yesterday }}
          weekStartsOn={1}
          locale={locale}
          captionLayout="dropdown-buttons"
          fromYear={2020}
          toYear={currentYear}
          pagedNavigation
        />
        <DatePickerStyle
          isRangeSameDate={isRangeSameDate}
          isOnlyStartDate={isOnlyStartDate}
        />
      </Box>
    </Box>
  )
}

export default DatePicker
