import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { KPI_FORMAT } from 'common/constants'

import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { getKpiValueAndSign } from 'common/helpers'

const BarchartTotal = ({
  kpi,
  total,
  format,
  handleSortConfig,
  sortConfig,
}: {
  kpi: { id: string; title: string; format: string }
  total: number
  format: keyof typeof KPI_FORMAT
  handleSortConfig: any
  sortConfig: {
    kpiId: string
    kpiTitle: string
    ascending: boolean
  }
}) => {
  const { t, i18n } = useTranslation()
  const {
    value: { currentCustomer },
  } = useAppSelector(selectAuth)
  const currentlanguage = i18n.language.split('-')[0]
  const currency = currentCustomer ? currentCustomer?.currency : ''

  const [isHover, setIsHover] = useState(false)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 0.5,
        mb: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => handleSortConfig(kpi.id, kpi.title)}
      >
        <Box sx={{ position: 'relative' }}>
          <Typography variant="h5" fontWeight="bold" sx={{ mr: 0.5 }}>
            {t(`${kpi.title}`, {
              ns: 'kpi',
            })}
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: '6px',
              right: sortConfig.kpiId !== kpi.id ? '-40px' : '-20px',
              display: 'flex',
              opacity: sortConfig.kpiId === kpi.id || isHover ? 1 : 0,
            }}
          >
            {sortConfig.kpiId !== kpi.id && (
              <ArrowUpward fontSize="small" cursor="pointer" opacity="0.5" />
            )}
            {sortConfig.kpiId === kpi.id && sortConfig.ascending ? (
              <ArrowUpward fontSize="small" cursor="pointer" />
            ) : (
              <ArrowDownward
                fontSize="small"
                cursor="pointer"
                opacity={sortConfig.kpiId !== kpi.id ? 0.5 : 1}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Typography variant="h5" fontWeight="bold">
        {getKpiValueAndSign(total, format, currentlanguage, currency)}
      </Typography>
    </Box>
  )
}

export default BarchartTotal
