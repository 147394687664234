import { Outlet } from 'react-router-dom'

import { Footer } from './footer'
import { Header } from './header'
import { Sidebar } from './sidebar'

import {
  DashboardLayoutContainer,
  DashboardLayoutMain,
  DashboardLayoutWrapper,
  OutletWrapper,
} from './styles'

const DashboardLayout = () => {
  return (
    <DashboardLayoutWrapper>
      <DashboardLayoutContainer>
        <Sidebar />
        <DashboardLayoutMain>
          <Header />
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
          <Footer />
        </DashboardLayoutMain>
      </DashboardLayoutContainer>
    </DashboardLayoutWrapper>
  )
}

export default DashboardLayout
