import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Typography, useTheme } from '@mui/material'

const LegalLinks = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <>
      <Link
        to="https://www.mso-digital.de/impressum/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="body2" color={theme.palette.text.legend}>
          {t('IMPRINT', { ns: 'common' })}
        </Typography>
      </Link>
      <Link
        to="https://www.mso-digital.de/agb_msodigital/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="body2" color={theme.palette.text.legend}>
          {t('TERMS_OF_USE', { ns: 'common' })}
        </Typography>
      </Link>
      <Link
        to="https://www.mso-digital.de/datenschutz/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="body2" color={theme.palette.text.legend}>
          {t('DATA_PRIVACY', { ns: 'common' })}
        </Typography>
      </Link>
    </>
  )
}

export default LegalLinks
