import { useMemo } from 'react'

const useHighestNumber = (data: any[], key: string) => {
  const highestNumber = useMemo(
    () => Math.max(...data.map((obj) => obj[key])),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  return highestNumber as number
}

export default useHighestNumber
