import { useState } from 'react'

import useAggregateData from 'hooks/useAggregateData'
import useCalculateTotal from 'hooks/useCalculateTotal'
import useCalculateTotalKpi3 from 'hooks/useCalculateTotalKpi3'
import useHighestNumber from 'hooks/useHighestNumber'
import useSortData from 'hooks/useSortData'

import { Kpi } from 'common/constants-metrics'
import { KPI_PATH } from 'common/constants-kpi-path'

import CustomSourcesBarChart from './sources-custom-bar-chart'
import SortStatusText from './sources-bar-chart-sort-status'

import { useTheme } from '@mui/material'

import { BarChartsWrapper, SourcesBarChartWrapper } from './styles'

const SourcesBarChart = ({
  chartData,
  kpi1,
  kpi2,
  kpi3,
  dimensionFilter,
}: {
  chartData: any
  kpi1: Kpi
  kpi2: Kpi
  kpi3: Kpi
  dimensionFilter: any
}) => {
  const theme = useTheme()

  const totalKpi1 = useCalculateTotal(chartData, kpi1.id)
  const totalKpi2 = useCalculateTotal(chartData, kpi2.id)
  const totalKpi3 = useCalculateTotalKpi3(totalKpi1, totalKpi2, kpi3.format)

  const aggregatedData = useAggregateData({
    data: chartData,
    dimension: dimensionFilter,
    kpi1,
    kpi2,
    kpi3,
    totalKpi1,
    totalKpi2,
  })

  const highestNumberKpi1 = useHighestNumber(aggregatedData, kpi1.id)
  const highestNumberKpi2 = useHighestNumber(aggregatedData, kpi2.id)

  const isKpi3Ascending =
    kpi3.title === KPI_PATH.ECOMMERCE_COST_REVENUE_RATIO.title ||
    kpi3.title === KPI_PATH.BRANDING_COST_PER_MILLE.title

  const [sortConfig, setSortConfig] = useState({
    kpiId: kpi3.id,
    kpiTitle: kpi3.title,
    ascending: isKpi3Ascending,
  })
  const sortedData = useSortData(
    aggregatedData,
    sortConfig.kpiId,
    sortConfig.ascending
  )

  const handleSortConfig = (kpiId: string, kpiTitle: string) => {
    setSortConfig((prev) =>
      prev.kpiId === kpiId
        ? { kpiId, kpiTitle, ascending: !prev.ascending }
        : { kpiId, kpiTitle, ascending: true }
    )
  }

  return (
    <SourcesBarChartWrapper>
      <BarChartsWrapper>
        <CustomSourcesBarChart
          data={sortedData}
          dimension={dimensionFilter}
          kpi={kpi1}
          total={totalKpi1}
          highestNumber={highestNumberKpi1}
          format={kpi1.format}
          color={theme.palette.primary.main}
          sortConfig={sortConfig}
          handleSortConfig={handleSortConfig}
        />
        <CustomSourcesBarChart
          data={sortedData}
          dimension={dimensionFilter}
          kpi={kpi2}
          total={totalKpi2}
          highestNumber={highestNumberKpi2}
          format={kpi2.format}
          color={theme.palette.secondary.main}
          sortConfig={sortConfig}
          handleSortConfig={handleSortConfig}
        />
        <CustomSourcesBarChart
          data={sortedData}
          dimension={dimensionFilter}
          kpi={kpi3}
          total={totalKpi3}
          format={kpi3.format}
          color={theme.palette.tertiary.main}
          sortConfig={sortConfig}
          handleSortConfig={handleSortConfig}
          isKpi3
        />
      </BarChartsWrapper>
      <SortStatusText
        titleKpi1={kpi1.title}
        titleKpi2={kpi2.title}
        titleKpi3={kpi3.title}
        sortConfig={sortConfig}
      />
    </SourcesBarChartWrapper>
  )
}

export default SourcesBarChart
