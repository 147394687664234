import { isPageAccessible } from 'common/helpers'
import { PageType } from 'common/constants'
import {
  BRANDING,
  E_COMMERCE,
  EXPLORER,
  HOME,
  LEAD,
  PROFILE,
} from 'common/constants-navigation'

const reportTypes: { [key: number]: string } = {
  1: E_COMMERCE.address,
  2: LEAD.address,
  3: BRANDING.address,
}

export const useSectionAccess = (
  userPackage: any,
  isGlobalView: boolean,
  customerReportTypes: number[]
) => {
  const isReportTypeAvailable = (sectionAddress: string): boolean => {
    if (
      sectionAddress === HOME.address ||
      sectionAddress === EXPLORER.address ||
      sectionAddress === PROFILE.address
    ) {
      return true
    }

    const typesToCheck = customerReportTypes.map(
      (typeId: number) => reportTypes[typeId]
    )

    return typesToCheck.includes(sectionAddress)
  }

  const isSectionActive = (sectionAddress: string | undefined): boolean => {
    if (!sectionAddress) return false

    return (
      isReportTypeAvailable(sectionAddress) &&
      isPageAccessible(userPackage, sectionAddress as PageType, isGlobalView)
    )
  }

  return { isReportTypeAvailable, isSectionActive }
}

export default useSectionAccess
