import { MouseEvent, useState } from 'react'

import { Box, IconButton, Popover, Typography, useTheme } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

const TrendChartInfoIcon = ({
  title,
  text,
}: {
  title: string
  text: string
}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'pro-popover' : undefined

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-28px',
        right: 0,
      }}
    >
      <IconButton aria-describedby={id} onClick={handleClick} disableRipple>
        <InfoOutlined
          sx={{
            transform: 'rotate(-90deg)',
            fontSize: '16px',
            color: theme.palette.text.legend,
          }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: 3,
            boxShadow: theme.palette.customShadow.popover,
          },
        }}
      >
        <Box
          sx={{
            width: { xs: '320px', md: '360px' },
            p: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: text }}
            sx={{ pt: 2 }}
          />
        </Box>
      </Popover>
    </Box>
  )
}

export default TrendChartInfoIcon
