import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'

import { authenticateUser, getCustomersDetails } from 'api'
import type { RootState } from 'store/store'

import { STATUS } from 'common/constants'

interface Customer {
  customerId?: number
  customerName?: string
  currency?: string
  reportingUnits?: number
}

export const authenticate = createAsyncThunk(
  'auth/authenticate',
  async (payload: { email: string; password: string }) => {
    const response = await authenticateUser({
      email: payload.email,
      password: payload.password,
    })
    const accessToken = response.headers['authorization']
    const decodedUserDetails = jwtDecode(accessToken)

    const { data: customers } = await getCustomersDetails(accessToken)

    const customerList: Customer[] = Object.values(customers)
    const sortedCustomerOptions = customerList
      .filter((item) => item !== null)
      .sort((a, b) => {
        const nameA = a.customerName?.toLowerCase() || ''
        const nameB = b.customerName?.toLowerCase() || ''
        return nameA.localeCompare(nameB)
      })

    return {
      accessToken,
      customers: sortedCustomerOptions,
      currentCustomer: sortedCustomerOptions[0],
      ...decodedUserDetails,
    }
  }
)

const initialState: any = {
  value: {},
  status: 0,
  errorMessage: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.value = { ...state.value, ...action.payload }
      state.status = STATUS.idle
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        state.status = STATUS.pending
        state.errorMessage = ''
      })
      .addCase(authenticate.fulfilled, (state, { payload }) => {
        state.status = STATUS.fulfilled
        state.value = payload
        state.errorMessage = ''
      })
      .addCase(authenticate.rejected, (state, { error }) => {
        state.status = STATUS.rejected
        state.errorMessage = error.message
      })
  },
})

export const selectAuth = (state: RootState) => state.auth
export const { setAuth } = authSlice.actions
export default authSlice.reducer
