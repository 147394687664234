import { useTranslation } from 'react-i18next'

import { Clear, Search } from '@mui/icons-material'
import { Box, InputAdornment, Typography, useTheme } from '@mui/material'

import {
  BasicFilterSearchTextFiled,
  CheckboxWrapper,
  CustomCheckbox,
  CustomCheckboxLabel,
  NoItemText,
  SelectionColumnBody,
  SelectionColumnHeader,
  SelectionColumnWrapper,
  SelectionDivider,
  SelectionSectionWrapper,
} from './styles'

const BasicFilter = ({
  selectedItems,
  searchQuery,
  filteredItemsBySearch,
  handleSelect,
  handleSelectAll,
  handleDeselectAll,
  handleSearch,
}: {
  selectedItems: string[]
  searchQuery: any
  filteredItemsBySearch: any
  handleSelect: any
  handleSelectAll: any
  handleDeselectAll: any
  handleSearch: any
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box sx={{ pt: 3.5 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            wordSpacing: '0.1em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {t('SEARCH', {
            ns: 'common',
          })}
        </Typography>
        <BasicFilterSearchTextFiled
          fullWidth
          autoComplete="off"
          variant="outlined"
          placeholder={t('TYPE_TO_SEARCH', {
            ns: 'common',
          })}
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {searchQuery !== '' ? (
                  <Clear
                    sx={{
                      color: theme.palette.text.legend,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSearch({ target: { value: '' } })}
                  />
                ) : (
                  <Search sx={{ color: theme.palette.text.legend }} />
                )}
              </InputAdornment>
            ),
          }}
        />

        <SelectionSectionWrapper>
          <SelectionColumnWrapper>
            <SelectionColumnHeader>
              <Typography
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {t('SELECT', {
                  ns: 'filter',
                })}
              </Typography>
              <SelectionDivider />
            </SelectionColumnHeader>
            <SelectionColumnBody>
              {!!filteredItemsBySearch.length && (
                <CheckboxWrapper>
                  <CustomCheckbox
                    size="small"
                    checked={
                      selectedItems.length === filteredItemsBySearch.length
                    }
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < filteredItemsBySearch.length
                    }
                    onChange={handleSelectAll}
                  />
                  <CustomCheckboxLabel variant="body2">
                    {t(
                      `${!searchQuery ? 'SELECT_ALL' : 'SELECT_ALL_SEARCH_RESULTS'}`,
                      {
                        ns: 'filter',
                      }
                    )}
                  </CustomCheckboxLabel>
                </CheckboxWrapper>
              )}
              {!filteredItemsBySearch.length ? (
                <NoItemText variant="body2">
                  {t(
                    `${!searchQuery ? 'NOTHING_TO_SELECT' : 'NOTHING_TO_SELECT_BASED_ON_SEARCH'}`,
                    {
                      ns: 'filter',
                    }
                  )}
                </NoItemText>
              ) : (
                <Box>
                  {filteredItemsBySearch.map((item: string) => (
                    <CheckboxWrapper key={item}>
                      <CustomCheckbox
                        size="small"
                        checked={selectedItems.includes(item)}
                        onChange={() => handleSelect(item)}
                      />
                      <CustomCheckboxLabel variant="body2">
                        {item}
                      </CustomCheckboxLabel>
                    </CheckboxWrapper>
                  ))}
                </Box>
              )}
            </SelectionColumnBody>
          </SelectionColumnWrapper>

          <SelectionColumnWrapper>
            <SelectionColumnHeader>
              <Typography
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {t('SELECTED', {
                  ns: 'filter',
                })}
              </Typography>
              <SelectionDivider />
            </SelectionColumnHeader>
            <SelectionColumnBody>
              {selectedItems.length > 0 && (
                <CheckboxWrapper>
                  <CustomCheckbox
                    size="small"
                    checked={false}
                    onChange={handleDeselectAll}
                  />
                  <CustomCheckboxLabel variant="body2">
                    {t(
                      `${!searchQuery ? 'DESELECT_ALL' : 'DESELECT_ALL_SEARCH_RESULTS'}`,
                      {
                        ns: 'filter',
                      }
                    )}
                  </CustomCheckboxLabel>
                </CheckboxWrapper>
              )}

              {selectedItems.length > 0 ? (
                <Box>
                  {selectedItems.map((item: string) => (
                    <CheckboxWrapper key={item}>
                      <CustomCheckbox
                        size="small"
                        checked
                        onChange={() => handleSelect(item)}
                      />
                      <CustomCheckboxLabel variant="body2">
                        {item}
                      </CustomCheckboxLabel>
                    </CheckboxWrapper>
                  ))}
                </Box>
              ) : (
                <NoItemText variant="body2">
                  {t(
                    `${!searchQuery ? 'NOTHING_SELECTED' : 'NOTHING_SELECTED_BASED_ON_SEARCH'}`,
                    {
                      ns: 'filter',
                    }
                  )}
                </NoItemText>
              )}
            </SelectionColumnBody>
          </SelectionColumnWrapper>
        </SelectionSectionWrapper>
      </Box>
    </Box>
  )
}

export default BasicFilter
