import { useLocation } from 'react-router-dom'

import { useAppSelector } from 'store/redux-hooks'
import { selectTheme } from 'store/features/theme-slice'

import { PageType } from 'common/constants'
import { getPersistedItem, isPageAccessible } from 'common/helpers'

import { Box, Typography } from '@mui/material'

const RequireAccess = ({ children }: any) => {
  const { pathname } = useLocation()
  const address = pathname.split('/')
  const sectionAddress = address[1]

  const { isGlobalView } = useAppSelector(selectTheme)

  const persistedAuth = getPersistedItem('auth')
  const userPackage = persistedAuth?.package || ''

  const isAccessible = isPageAccessible(
    userPackage,
    sectionAddress as PageType,
    isGlobalView
  )

  if (!isAccessible) {
    return (
      <Box
        sx={{
          backgroundColor: 'background.paper',
          color: 'error.main',
          p: 4,
          borderRadius: 3,
        }}
      >
        <Typography variant="h4">ACCESS DENIED!</Typography>
      </Box>
    )
  }

  if (isAccessible) {
    return children
  }

  return undefined
}

export default RequireAccess
