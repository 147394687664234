import { useMemo } from 'react'
import { createTheme } from '@mui/material/'

import { useAppSelector } from 'store/redux-hooks'
import { selectTheme } from 'store/features/theme-slice'

import { baseFontFamily, basePalette } from 'common/constants.theme'
import { getPersistedItem } from 'common/helpers'

export const useCustomTheme = () => {
  const { mode } = useAppSelector(selectTheme)
  const persistedTheme = getPersistedItem('theme')
  const persistedMode: 'dark' | 'light' = persistedTheme?.mode || mode

  const userConfig = getPersistedItem('auth')
  const userPaletteLight = userConfig?.brandTheme?.brandPalette?.light
  const userPaletteDark = userConfig?.brandTheme?.brandPalette?.dark
  const userFontFamily = userConfig?.brandTheme?.brandFontFamily

  const customPalette = {
    light: {
      primary: userPaletteLight?.primary || basePalette.light.primary,
      secondary: userPaletteLight?.secondary || basePalette.light.secondary,
      tertiary: userPaletteLight?.tertiary || basePalette.light.tertiary,
      background: basePalette.light.background,
      text: basePalette.light.text,
      action: basePalette.light.action,
      customShadow: basePalette.light.customShadow,
      divider: basePalette.light.divider,
    },
    dark: {
      primary: userPaletteDark?.primary || basePalette.dark.primary,
      secondary: userPaletteDark?.secondary || basePalette.dark.secondary,
      tertiary: userPaletteDark?.tertiary || basePalette.dark.tertiary,
      background: basePalette.dark.background,
      text: basePalette.dark.text,
      action: basePalette.dark.action,
      customShadow: basePalette.dark.customShadow,
      divider: basePalette.dark.divider,
    },
  }

  const customFontFamily = userFontFamily || baseFontFamily

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: persistedMode,
          ...customPalette[persistedMode],
        },
        typography: {
          fontFamily: [customFontFamily, 'Helvetica', 'Arial'].join(','),
        },
        components: {
          MuiButton: {
            defaultProps: {
              disableElevation: true,
            },
            styleOverrides: {
              root: {
                '&.MuiButton-contained:hover': {
                  backgroundColor:
                    persistedMode === 'dark'
                      ? customPalette.dark.primary.light
                      : customPalette.light.primary.light,
                },
              },
            },
          },
        },
      }),
    // eslint-disable-next-line
    [mode, userConfig]
  )

  return theme
}
