import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectFilter, setFilter } from 'store/features/filter-slice'
import { setModal } from 'store/features/modal-slice'
import { selectAuth } from 'store/features/auth-slice'
import {
  fetchDimensionFilter,
  selectDimensionFilter,
} from 'store/features/dimension-filter-slice'

import { deserializeDateRange } from 'common/helpers-date'

import { SOURCES, TREND } from 'common/constants-navigation'
import { FeatureType, SOURCE_NAME, STATUS } from 'common/constants'

import FilterSettingsPanel from './filter-settings-panel'
import DatePicker from './date-picker'
import DateCompare from './date-compare'

import {
  AccountTreeOutlined,
  CampaignOutlined,
  DateRangeOutlined,
  Delete,
  HubOutlined,
  LayersOutlined,
  MonitorHeartOutlined,
  SvgIconComponent,
  WarningAmberRounded,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogProps,
  LinearProgress,
  Modal,
  Typography,
  useTheme,
} from '@mui/material'

import {
  FilterModalWrapper,
  FilterModalMenuWrapper,
  FilterModalMainWrapper,
  FilterModalMenuItem,
} from './styles'

interface FilterRow {
  [key: string]: {
    id: string
    filterType: string
    filterValue: string
  }
}

interface ConditionSet {
  id: string
  or: FilterRow[]
}

export interface FilterDimension {
  value: string
  title: string
  icon: SvgIconComponent
}

interface Filter {
  filterType: string
  filterValue: string[]
  [key: string]: any
}

export const dimensionFilterSections: FilterDimension[] = [
  {
    value: FeatureType.ReportingUnit,
    title: 'REPORTING_UNIT',
    icon: AccountTreeOutlined,
  },
  {
    value: FeatureType.TrafficSource,
    title: 'TRAFFIC_SOURCE',
    icon: HubOutlined,
  },
  {
    value: FeatureType.CampaignName,
    title: 'CAMPAIGN_NAME',
    icon: CampaignOutlined,
  },
  {
    value: FeatureType.CampaignType,
    title: 'CAMPAIGN_TYPE',
    icon: LayersOutlined,
  },
  {
    value: FeatureType.CampaignStatus,
    title: 'CAMPAIGN_STATUS',
    icon: MonitorHeartOutlined,
  },
]

export const dateFilterSection: FilterDimension = {
  value: FeatureType.Date,
  title: 'DATE_FILTER',
  icon: DateRangeOutlined,
}

const FilterModal = ({
  open,
  filterSectionInView,
  isReportingUnit,
}: {
  open: boolean
  filterSectionInView: FilterDimension
  isReportingUnit: boolean
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const {
    value: { currentCustomer },
  } = useAppSelector(selectAuth)

  const savedFilter = useAppSelector(selectFilter)

  const { value: dimensionItems, status: dimensionItemsStatus } =
    useAppSelector(selectDimensionFilter)

  const [temporaryFilter, setTemporaryFilter] = useState(savedFilter)
  const [isMissingDateRange, setIsMissingDateRange] = useState(false)
  const [dimensionToReset, setDimensionToReset] = useState<string>('')
  const [selectFilterSection, setSelectFilterSection] =
    useState<FilterDimension>(filterSectionInView)

  const reportTypePage = pathname.split('/')[2]
  const isSourcesPage = reportTypePage === SOURCES.address
  const isTrendPage = reportTypePage === TREND.address

  const basicFilteredDimensions = Object.fromEntries(
    Object.entries(temporaryFilter.basic).filter(
      ([_, dimensionvalue]: [dimensionName: any, dimensionvalue: any]) =>
        dimensionvalue.include.length !== 0
    )
  )

  const extractedTemporaryFilterBasic = Object.fromEntries(
    Object.entries(basicFilteredDimensions).filter(
      ([key]) => key !== selectFilterSection.value
    )
  )

  const temporaryFilterBasic = Object.fromEntries(
    Object.entries(extractedTemporaryFilterBasic as Filter).map(
      ([key, filter]) => [
        key,
        {
          filterType: filter.filterType,
          filterValue: filter.filterValue,
        },
      ]
    )
  )

  const extractedTemporaryFilterAdvanced = Object.fromEntries(
    Object.entries(temporaryFilter.advanced).filter(
      ([key]) => key !== selectFilterSection.value
    )
  )

  const flatTemporaryFilterAdvanced = Object.values(
    extractedTemporaryFilterAdvanced
  ).flatMap((entryArray) => entryArray)

  const temporaryFilterAdvanced = (
    flatTemporaryFilterAdvanced as ConditionSet[]
  ).filter((item) =>
    item.or.some((subItem) => {
      const filterValue = Object.values(subItem)[0]?.filterValue
      return filterValue && filterValue.length > 0
    })
  )

  useEffect(() => {
    if (FeatureType.Date !== selectFilterSection.value) {
      handleFetchDimensionFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFilterSection])

  const handleFetchDimensionFilter = () => {
    dispatch(
      fetchDimensionFilter({
        payload: {
          customerId: currentCustomer?.customerId,
          source: {
            name: SOURCE_NAME.channel,
            dimension: selectFilterSection.value,
          },
          filter: {
            basic: temporaryFilterBasic,
            advanced: temporaryFilterAdvanced,
          },
        },
      })
    )
  }

  const availableDimensionFilterSections = dimensionFilterSections.filter(
    (dimension) =>
      dimension.value === FeatureType.ReportingUnit && !isReportingUnit
        ? null
        : dimension
  )

  const advancedFilteredDimensions = Object.entries(
    temporaryFilter.advanced
  ).filter(([_, filters]) =>
    (filters as ConditionSet[]).some((filterGroup) =>
      filterGroup.or.some((filterObj) =>
        Object.values(filterObj as FilterRow).some(
          (filter) => filter.filterValue.trim() !== ''
        )
      )
    )
  )

  const basicFilteredDimensionsList = Object.keys(basicFilteredDimensions).map(
    (dimension) => dimension
  )

  const advancedFilteredDimensionsList = advancedFilteredDimensions.map(
    ([dimension]) => dimension
  )

  const isDimensionFiltered = (dimensionId: string) => {
    return (
      basicFilteredDimensionsList.includes(dimensionId) ||
      advancedFilteredDimensionsList.includes(dimensionId)
    )
  }

  const handleDimensionToReset = (dimensionId: string) => {
    setDimensionToReset(dimensionId)
  }

  const resetDimnesionFilter = (dimensionId: string) => {
    handleDimensionToReset(dimensionId)

    const resetBasicFilter = {
      filterType: 'excl',
      filterValue: [],
      include: [],
      exclude: [],
    }

    const dimensionWithNoFilter: {
      basic: FilterRow
      advanced: ConditionSet[]
    } = {
      basic: {
        ...temporaryFilter.basic,
        [dimensionId]: resetBasicFilter,
      },
      advanced: {
        ...temporaryFilter.advanced,
        [dimensionId]: [
          {
            id: `${Date.now()}-${Math.random()}`,
            or: [],
          },
        ],
      },
    }

    const updatedTemporaryFilter = {
      ...temporaryFilter,
      ...dimensionWithNoFilter,
    }

    handleTemporaryFilter(updatedTemporaryFilter)

    const basicFilteredDimensionsUpdatedByReset = Object.fromEntries(
      Object.entries(temporaryFilter.basic).filter(
        ([_, dimensionvalue]: [dimensionName: any, dimensionvalue: any]) =>
          dimensionvalue.include.length !== 0
      )
    )

    const extractedTemporaryFilterBasicUpdatedByReset = Object.fromEntries(
      Object.entries(basicFilteredDimensionsUpdatedByReset).filter(
        ([key]) => key !== selectFilterSection.value
      )
    )

    const temporaryFilterBasicUpdatedByReset = Object.fromEntries(
      Object.entries(extractedTemporaryFilterBasicUpdatedByReset as Filter).map(
        ([key, filter]) => [
          key,
          {
            filterType: filter.filterType,
            filterValue: filter.filterValue,
          },
        ]
      )
    )

    const extractedTemporaryFilterAdvancedUpdatedByReset = Object.fromEntries(
      Object.entries(updatedTemporaryFilter.advanced).filter(
        ([key]) => key !== selectFilterSection.value
      )
    )

    const flatTemporaryFilterAdvancedUpdatedByReset = Object.values(
      extractedTemporaryFilterAdvancedUpdatedByReset
    ).flatMap((entryArray) => entryArray)

    const temporaryFilterAdvancedUpdatedByReset = (
      flatTemporaryFilterAdvancedUpdatedByReset as ConditionSet[]
    ).filter((item) =>
      item.or.some((subItem) => {
        const filterValue = Object.values(subItem)[0]?.filterValue
        return filterValue && filterValue.length > 0
      })
    )

    const dimensionFilterPayload = {
      payload: {
        customerId: currentCustomer?.customerId,
        source: {
          name: SOURCE_NAME.channel,
          dimension: selectFilterSection.value,
        },
        filter: {
          basic: temporaryFilterBasicUpdatedByReset,
          advanced: temporaryFilterAdvancedUpdatedByReset,
        },
      },
    }

    handleDimensionFilter(dimensionFilterPayload)
  }

  const handleDimensionFilter = (payload: any) => {
    dispatch(fetchDimensionFilter(payload))
  }

  const handleSelectFilterSection = (dimension: FilterDimension) => {
    setSelectFilterSection(dimension)
  }

  const handleTemporaryFilter = (value: any) => {
    setTemporaryFilter(value)
    setIsMissingDateRange(!deserializeDateRange(value.date.range)?.to)
  }

  const closeFilterModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    setTemporaryFilter(savedFilter)
    dispatch(setModal({ type: '', activeComponent: '' }))
  }

  const applyFilter = () => {
    dispatch(setFilter(temporaryFilter))
    dispatch(setModal({ type: '', activeComponent: '' }))
  }

  const renderIcon = (
    SelectIcon: SvgIconComponent,
    isSelect: boolean,
    isHeader?: boolean
  ) => {
    return (
      <SelectIcon
        sx={{
          color: isHeader ? theme.palette.primary.main : 'inherit',
          fontSize: isHeader ? '32px' : '24px',
          fontWeight: isSelect ? 'bold' : 'inherit',
        }}
      />
    )
  }

  return (
    <Modal open={open} onClose={closeFilterModal}>
      <Box>
        <FilterModalWrapper>
          <FilterModalMenuWrapper>
            <Typography variant="h5" fontWeight="bold" sx={{ pl: 1 }}>
              {t('FILTER', {
                ns: 'filter',
              })}
            </Typography>

            {/* Dimension Filter */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  wordSpacing: '0.1em',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  pl: 1,
                  pb: 1,
                }}
              >
                {t('DIMENSION_FILTER', {
                  ns: 'filter',
                })}
              </Typography>
              {availableDimensionFilterSections.map(
                (dimensionFilterSection) => (
                  <Box
                    key={dimensionFilterSection.value}
                    sx={{ position: 'relative' }}
                  >
                    <FilterModalMenuItem
                      key={dimensionFilterSection.value}
                      onClick={() =>
                        handleSelectFilterSection(dimensionFilterSection)
                      }
                      sx={{
                        color:
                          dimensionFilterSection.value ===
                          selectFilterSection.value
                            ? 'primary.main'
                            : 'inherit',
                        bgcolor:
                          dimensionFilterSection.value ===
                          selectFilterSection.value
                            ? 'background.paper2'
                            : 'inherit',
                      }}
                    >
                      {renderIcon(
                        dimensionFilterSection.icon,
                        dimensionFilterSection.value ===
                          selectFilterSection.value
                      )}
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight:
                            dimensionFilterSection.value ===
                            selectFilterSection.value
                              ? 'bold'
                              : 'inherit',
                        }}
                      >
                        {t(`${dimensionFilterSection.title}`, {
                          ns: 'filter',
                        })}
                      </Typography>
                    </FilterModalMenuItem>
                    {isDimensionFiltered(dimensionFilterSection.value) && (
                      <Delete
                        onClick={() =>
                          resetDimnesionFilter(dimensionFilterSection.value)
                        }
                        sx={{
                          position: 'absolute',
                          top: 14,
                          right: 16,
                          color: theme.palette.background.brush,
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  </Box>
                )
              )}
            </Box>

            {/* Date Filter */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  wordSpacing: '0.1em',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  pl: 1,
                }}
              >
                {t('DATE_FILTER', {
                  ns: 'filter',
                })}
              </Typography>
              <FilterModalMenuItem
                onClick={() => handleSelectFilterSection(dateFilterSection)}
                sx={{
                  color:
                    dateFilterSection.value === selectFilterSection.value
                      ? 'primary.main'
                      : 'inherit',
                  bgcolor:
                    dateFilterSection.value === selectFilterSection.value
                      ? 'background.paper2'
                      : 'inherit',
                }}
              >
                {renderIcon(
                  dateFilterSection.icon,
                  dateFilterSection.value === selectFilterSection.value
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight:
                      dateFilterSection.value === selectFilterSection.value
                        ? 'bold'
                        : 'inherit',
                  }}
                >
                  {t(`${dateFilterSection.title}`, {
                    ns: 'filter',
                  })}
                </Typography>
              </FilterModalMenuItem>
            </Box>
          </FilterModalMenuWrapper>

          <FilterModalMainWrapper>
            <Box
              key={selectFilterSection.value}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
              }}
            >
              {renderIcon(selectFilterSection.icon, true, true)}
              <Typography variant="h5" fontWeight="bold" color="primary.main">
                {t(`${selectFilterSection.title}`, {
                  ns: 'filter',
                })}
              </Typography>
            </Box>
            <Box sx={{ overflow: 'auto' }}>
              {availableDimensionFilterSections.map(
                (section) =>
                  section.value === selectFilterSection.value &&
                  (dimensionItemsStatus === STATUS.fulfilled ? (
                    <FilterSettingsPanel
                      key={section.value}
                      dimensionId={section.value}
                      temporaryFilter={temporaryFilter}
                      handleTemporaryFilter={handleTemporaryFilter}
                      handleDimensionToReset={handleDimensionToReset}
                      dimensionToReset={dimensionToReset}
                      dimensionItems={dimensionItems}
                    />
                  ) : dimensionItemsStatus === STATUS.pending ? (
                    <LinearProgress key={section.value} />
                  ) : (
                    <React.Fragment key={section.value}>
                      <Alert
                        severity="error"
                        sx={{ borderRadius: 3, padding: 2 }}
                      >
                        <AlertTitle sx={{ fontWeight: 'bold' }}>
                          {t('PAGE_NOT_LOADIND_ERROR_TITLE', { ns: 'common' })}
                        </AlertTitle>
                        <Typography>
                          {t('PAGE_NOT_LOADIND_ERROR_MESSAGE', {
                            ns: 'common',
                          })}
                        </Typography>
                        <Button
                          onClick={handleFetchDimensionFilter}
                          variant="contained"
                          sx={{
                            minWidth: '136px',
                            height: '40px',
                            borderRadius: 2,
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            mt: 3,
                          }}
                        >
                          {t('TRY_AGAIN', { ns: 'common' })}
                        </Button>
                      </Alert>
                    </React.Fragment>
                  ))
              )}
              {FeatureType.Date === selectFilterSection.value &&
                isSourcesPage && (
                  <DatePicker
                    temporaryFilter={temporaryFilter}
                    handleTemporaryFilter={handleTemporaryFilter}
                  />
                )}
              {FeatureType.Date === selectFilterSection.value &&
                isTrendPage && (
                  <DateCompare
                    temporaryFilter={temporaryFilter}
                    handleTemporaryFilter={handleTemporaryFilter}
                  />
                )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
                mt: 'auto',
              }}
            >
              <Box>
                {isMissingDateRange ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <WarningAmberRounded color="warning" />
                    <Typography variant="body2">
                      {t('DATE_PICKER_WARNING_MESSAGE', {
                        ns: 'filter',
                      })}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                }}
              >
                <Button
                  onClick={(e) => closeFilterModal(e, 'escapeKeyDown')}
                  variant="outlined"
                  sx={{
                    minWidth: '136px',
                    height: '40px',
                    borderRadius: 5,
                    textTransform: 'capitalize',
                  }}
                >
                  {t('CANCEL', {
                    ns: 'common',
                  })}
                </Button>
                <Button
                  onClick={applyFilter}
                  variant="contained"
                  sx={{
                    width: '136px',
                    height: '40px',
                    borderRadius: 5,
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                  disabled={isMissingDateRange}
                >
                  {t('APPLY', {
                    ns: 'common',
                  })}
                </Button>
              </Box>
            </Box>
          </FilterModalMainWrapper>
        </FilterModalWrapper>
      </Box>
    </Modal>
  )
}

export default FilterModal
