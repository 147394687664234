import {
  endOfMonth,
  endOfYear,
  format,
  isSameDay,
  isWithinInterval,
  Locale,
  parseISO,
  setMonth,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'
import { Kpi } from './constants-metrics'
import { KPI_FORMAT } from './constants'
import { formatDateToYearMonthDayString } from './helpers-date'

type KPIData = { date: string; [key: string]: any }
type TransformedKPIData = { [key: string]: any }
type KPIAverageChange = { [key: string]: number }
interface KPICard {
  id: string
  details: any
  color: string
}
type MonthToCompareOption = 'PREVIOUS_MONTH' | 'SAME_MONTH_PREVIOUS_YEAR'
type KPICardData = {
  id: string
  details: any
  pastTotal: number | null
  currentTotal: number | null
  currentTotalChange: number | null
  currentTotalChangeValue: number | null
  forecastTotal: number | null
  forecastTotalChangeValue: number | null
  forecastTotalChange: number | null
  color: string
}

export const checkIsSelectedDateCurrentYear = (date: string): boolean => {
  const selected = new Date(date)
  const currentYear = new Date().getFullYear()

  return selected.getFullYear() === currentYear
}

export const checkIsSelectedDateCurrentMonth = (date: string): boolean => {
  const selected = new Date(date)
  const now = new Date()

  return (
    selected.getFullYear() === now.getFullYear() &&
    selected.getMonth() === now.getMonth()
  )
}

export const generaetDateRange = (
  start: Date | string,
  end: Date | string,
  data: any[],
  kpi1: Kpi,
  kpi2: Kpi,
  kpi3: Kpi
) => {
  const today = new Date()
  const yesterday = subDays(today, 1)

  const dateMap = new Map()
  data.forEach((item: { date: Date }) => {
    dateMap.set(item.date, item)
  })

  const datesInRange = []
  const currentDate = new Date(start)
  const endDate = new Date(end)

  while (currentDate < yesterday && currentDate <= endDate) {
    const date = formatDateToYearMonthDayString(currentDate)
    const dataPoint = dateMap.get(date)
    datesInRange.push(
      dataPoint || {
        date,
        [kpi1.id]: null,
        [kpi2.id]: null,
        [kpi3.id]: null,
      }
    )
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return datesInRange
}

export const addSuffixToKPIs = (
  item: KPIData,
  suffix: string,
  kpi1: Kpi,
  kpi2: Kpi,
  kpi3: Kpi
): TransformedKPIData => {
  const newItem: TransformedKPIData = {}
  const kpis = [kpi1, kpi2, kpi3]

  Object.keys(item).forEach((key) => {
    newItem[`${key}${suffix}`] = item[key]
    kpis.forEach((kpi) => {
      if (key === kpi.id) {
        newItem[`${key}_format`] = kpi.format
      }
    })
  })
  return newItem
}

export const accumulateKPIs = (arr: TransformedKPIData[]) => {
  let currentMonth: string | null = null
  const monthlyTotals: { [key: string]: number } = {}
  const percentageTotals: { [key: string]: { sum: number; count: number } } = {}
  const monthlyPercentageTotals: {
    [key: string]: { sum: number; count: number }
  } = {}

  arr.forEach((item, index) => {
    const dateKey = item.date_past ? 'date_past' : 'date_current'
    const date = new Date(item[dateKey])
    const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`

    if (monthKey !== currentMonth) {
      currentMonth = monthKey
      Object.keys(monthlyTotals).forEach((key) => {
        monthlyTotals[key] = 0
      })
      Object.keys(monthlyPercentageTotals).forEach((key) => {
        monthlyPercentageTotals[key] = { sum: 0, count: 0 }
      })
    }

    Object.keys(item).forEach((key) => {
      if (key.endsWith('_format')) return

      const kpiKey = key.replace(/_current|_past/, '')
      const kpiFormat = item[`${kpiKey}_format`]

      if (
        kpiFormat === KPI_FORMAT.currency ||
        kpiFormat === KPI_FORMAT.integer ||
        kpiFormat === KPI_FORMAT.time
      ) {
        const accumulatedKey = `${key}_accumulated`
        const monthlyTotalKey = `${key}_monthly_total`
        const previousValue = index > 0 ? arr[index - 1][accumulatedKey] : 0
        item[accumulatedKey] = previousValue + item[key]

        if (!monthlyTotals[monthlyTotalKey]) {
          monthlyTotals[monthlyTotalKey] = 0
        }
        monthlyTotals[monthlyTotalKey] += item[key]
        item[monthlyTotalKey] = monthlyTotals[monthlyTotalKey]
      }

      if (kpiFormat === KPI_FORMAT.percent) {
        const accumulatedKey = `${key}_accumulated`
        const monthlyTotalKey = `${key}_monthly_total`

        if (!percentageTotals[key]) {
          percentageTotals[key] = { sum: 0, count: 0 }
        }
        if (!monthlyPercentageTotals[key]) {
          monthlyPercentageTotals[key] = { sum: 0, count: 0 }
        }

        percentageTotals[key].sum += item[key]
        percentageTotals[key].count += 1
        item[accumulatedKey] =
          percentageTotals[key].sum / percentageTotals[key].count

        monthlyPercentageTotals[key].sum += item[key]
        monthlyPercentageTotals[key].count += 1
        item[monthlyTotalKey] =
          monthlyPercentageTotals[key].sum / monthlyPercentageTotals[key].count
      }
    })
  })
}

export const transformData = (
  data: KPIData[],
  kpi1: Kpi,
  kpi2: Kpi,
  kpi3: Kpi,
  selectedMonthStartDate: string,
  compareMonthStartDate: string,
  isDimensionYear: boolean
): { pastData: TransformedKPIData[]; currentData: TransformedKPIData[] } => {
  if (data.length === 0) {
    return { pastData: [], currentData: [] }
  }

  const pastData: TransformedKPIData[] = []
  const currentData: TransformedKPIData[] = []

  const startYear = new Date(compareMonthStartDate).getFullYear()
  const endYear = new Date(selectedMonthStartDate).getFullYear()

  const selectedMonthStart = new Date(selectedMonthStartDate)
  const compareMonthStart = new Date(compareMonthStartDate)

  data.forEach((item) => {
    const itemYear = new Date(item.date).getFullYear()
    const itemDate = new Date(item.date)

    const isPastYear = itemYear < startYear || itemYear < endYear
    const isPastMonth =
      itemDate < selectedMonthStart && itemDate >= compareMonthStart
    const isPast = isDimensionYear ? isPastYear : isPastMonth
    const suffix = isPast ? '_past' : '_current'

    const newItem = addSuffixToKPIs(item, suffix, kpi1, kpi2, kpi3)

    isPast ? pastData.push(newItem) : currentData.push(newItem)
  })

  accumulateKPIs(pastData)
  accumulateKPIs(currentData)

  return { pastData, currentData }
}

export const calculateAverageChange = (
  data: TransformedKPIData[]
): KPIAverageChange => {
  if (data.length === 0) {
    return {}
  }

  const totalChanges: KPIAverageChange = {}

  Object.keys(data[0]).forEach((key) => {
    if (!key.includes('date') && key.endsWith('_accumulated')) {
      const totalKey = key
      const averageKey = key.replace('_accumulated', '_accumulated_average')

      const kpiKey = key.replace(/_current_accumulated|_past_accumulated/, '')
      const formatKey = `${kpiKey}_format`
      const kpiFormat = data[0][formatKey]

      if (
        kpiFormat === KPI_FORMAT.currency ||
        kpiFormat === KPI_FORMAT.integer ||
        kpiFormat === KPI_FORMAT.time
      ) {
        totalChanges[totalKey] = data[data.length - 1][key]
        totalChanges[averageKey] = data[data.length - 1][key] / data.length
      }

      totalChanges[formatKey] = kpiFormat
    }
  })

  return totalChanges
}

export const getDatesInRange = (
  startDate: string,
  endDate: string
): string[] => {
  const dates = []
  const currentDate = new Date(startDate)
  const end = new Date(endDate)

  while (currentDate <= end) {
    dates.push(currentDate.toISOString().split('T')[0])
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

export const createForecastData = (
  kpi1: Kpi,
  kpi2: Kpi,
  kpi3: Kpi,
  date: string,
  totalChanges: KPIAverageChange,
  isDimensionYear: boolean
): TransformedKPIData[] => {
  if (date.length === 0) {
    return []
  }

  const startDate = new Date(date)
  startDate.setDate(startDate.getDate() + 1)
  let endDate: Date
  isDimensionYear
    ? (endDate = new Date(startDate.getFullYear(), 11, 31))
    : (endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1))

  const dateRange = getDatesInRange(
    startDate.toISOString().split('T')[0],
    endDate.toISOString().split('T')[0]
  )

  const forecastData: TransformedKPIData[] = []

  dateRange.forEach((date_forecast: string, index) => {
    const newData: TransformedKPIData = { date_forecast }

    Object.keys(totalChanges).forEach((key) => {
      if (key.endsWith('_accumulated')) {
        const kpiKey = key.replace(/_current_accumulated|_past_accumulated/, '')
        const kpiFormat: any = totalChanges[`${kpiKey}_format`]

        if (
          kpiFormat === KPI_FORMAT.currency ||
          kpiFormat === KPI_FORMAT.integer ||
          kpiFormat === KPI_FORMAT.time
        ) {
          const accumulatedKey = key.replace(
            '_current_accumulated',
            '_forecast_accumulated'
          )

          const averageKey = key.replace('_accumulated', '_accumulated_average')

          newData[accumulatedKey] =
            totalChanges[key] + totalChanges[averageKey] * (index + 1)
        }
      }
    })

    forecastData.push(newData)
  })

  const forecastDataWithRatioPercent =
    kpi3.format === KPI_FORMAT.percent
      ? forecastData.map((item) => ({
          ...item,
          [`${kpi3.id}_forecast_accumulated`]:
            item[`${kpi2.id}_forecast_accumulated`] !== null ||
            item[`${kpi2.id}_forecast_accumulated`] !== 0
              ? item[`${kpi1.id}_forecast_accumulated`] /
                item[`${kpi2.id}_forecast_accumulated`]
              : null,
        }))
      : forecastData

  return forecastDataWithRatioPercent
}

export const transformLastMonthKeys = (
  data: TransformedKPIData[]
): TransformedKPIData[] => {
  if (data.length === 0) {
    return data
  }

  const lastObjectDate = new Date(data[data.length - 1].date_current)
  const lastMonth = lastObjectDate.getMonth() + 1
  const lastYear = lastObjectDate.getFullYear()
  const lastDayOfMonth = new Date(lastYear, lastMonth, 0).getDate()

  if (lastObjectDate.getDate() === lastDayOfMonth) {
    return data
  }

  const updatedData = data.map((item) => {
    const itemDate = new Date(item.date_current)
    if (itemDate.getMonth() + 1 === lastMonth) {
      const newItem: TransformedKPIData = { date_forecast: item.date_current }

      Object.keys(item).forEach((key) => {
        if (key !== 'date_current') {
          newItem[key.replace('_current', '_forecast')] = item[key]
        }
      })

      return newItem
    }

    return item
  })

  return updatedData
}

export const checkFirstAndLastDayOfMonth = (
  data: TransformedKPIData[]
): boolean => {
  if (data.length < 2) return false

  const firstDate = parseISO(data[0].date_current)
  const lastDate = parseISO(data[data.length - 1].date_current)
  const month = firstDate.getMonth()
  const year = firstDate.getFullYear()

  return (
    isSameDay(firstDate, startOfMonth(new Date(year, month, 1))) &&
    isSameDay(lastDate, endOfMonth(new Date(year, month + 1, 0)))
  )
}

export const checkFirstAndLastDayOfYear = (
  data: TransformedKPIData
): boolean => {
  if (data.length < 2) return false

  const firstDate = parseISO(data[0].date_current)
  const lastDate = parseISO(
    data[data.length - 1].date_current || data[data.length - 1].date_forecast
  )
  const year = firstDate.getFullYear()

  return (
    isSameDay(firstDate, startOfYear(new Date(year, 0, 1))) &&
    isSameDay(lastDate, endOfYear(new Date(year, 11, 31)))
  )
}

export const extractLastDayOfMonth = (
  chartData: TransformedKPIData[]
): TransformedKPIData[] => {
  if (chartData.length === 0) {
    return chartData
  }
  const lastOfMonthMap: { [key: string]: TransformedKPIData } = {}

  chartData.forEach((item) => {
    const dateKey = item.date_current || item.date_forecast || item.date_past
    const date = new Date(dateKey)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const monthKey = `${year}-${month}`

    if (
      !lastOfMonthMap[monthKey] ||
      new Date(
        lastOfMonthMap[monthKey].date_current ||
          lastOfMonthMap[monthKey].date_forecast ||
          lastOfMonthMap[monthKey].date ||
          lastOfMonthMap[monthKey].date_past ||
          ''
      ) < date
    ) {
      lastOfMonthMap[monthKey] = item
    }
  })

  return Object.values(lastOfMonthMap)
}

export const prefillAggregatedMap = (
  isDimensionYear: boolean,
  selectedMonthStartDate: string,
  compareMonthStartDate: string,
  locale: Locale
): Map<string, TransformedKPIData> => {
  const aggregatedMap = new Map<string, TransformedKPIData>()
  const fullYear = parseISO('2024-01-01')

  const getDaysInMonth = (dateValue: string) => {
    const date = new Date(dateValue)
    const year = date.getFullYear()
    const month = date.getMonth() + 1

    return new Date(year, month, 0).getDate()
  }

  const selectedMonth = getDaysInMonth(selectedMonthStartDate)
  const compareMonth = getDaysInMonth(compareMonthStartDate)

  const dayLength = Math.max(selectedMonth, compareMonth)
  const monthLength = 12
  const length = isDimensionYear ? monthLength : dayLength

  // eslint-disable-next-line array-callback-return
  Array.from({ length }, (_, i) => {
    const key = String(i + 1).padStart(2, '0')
    const newItem: TransformedKPIData = {}

    if (isDimensionYear) {
      newItem.month_name = format(setMonth(fullYear, i), 'MMM', { locale })
    }
    if (!isDimensionYear) {
      newItem.day_number = i + 1
    }

    aggregatedMap.set(key, newItem)
  })

  return aggregatedMap
}

export const calculateRatio = (
  numerator: number,
  denominator: number
): number | null => (denominator !== 0 ? numerator / denominator : null)

export const mergeData = (
  data: TransformedKPIData[],
  isDimensionYear: boolean,
  selectedMonthStartDate: string,
  compareMonthStartDate: string,
  kpi1: Kpi,
  kpi2: Kpi,
  kpi3: Kpi,
  locale: Locale
): TransformedKPIData[] => {
  if (data.length === 0) {
    return data
  }

  const aggregatedMap = prefillAggregatedMap(
    isDimensionYear,
    selectedMonthStartDate,
    compareMonthStartDate,
    locale
  )
  data.forEach((item) => {
    const dateKey = item.date_current || item.date_forecast || item.date_past

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [year, month, day] = dateKey.split('-')

    const aggregationKey = isDimensionYear ? month : day

    if (!aggregatedMap.has(aggregationKey)) {
      aggregatedMap.set(aggregationKey, { ...item })
    } else {
      const existingItem = aggregatedMap.get(aggregationKey)!

      Object.keys(item).forEach((key) => {
        if (key.includes('date') && !existingItem[key]) {
          existingItem[key] = item[key]
        } else if (typeof item[key] === 'number' && !existingItem[key]) {
          existingItem[key] = (existingItem[key] || 0) + item[key]
        }
      })
    }
  })

  const aggregatedValues = Array.from(aggregatedMap.values())

  const formatAggregatedValues = (item: any, type: string) => ({
    [`${kpi3.id}_${type}_monthly_total`]: calculateRatio(
      item[`${kpi1.id}_${type}_monthly_total`],
      item[`${kpi2.id}_${type}_monthly_total`]
    ),
    [`${kpi3.id}_${type}_accumulated`]: calculateRatio(
      item[`${kpi1.id}_${type}_accumulated`],
      item[`${kpi2.id}_${type}_accumulated`]
    ),
  })

  const aggregatedValuesIncludingKpi3 =
    kpi3.format !== KPI_FORMAT.time
      ? aggregatedValues.map((item) => ({
          ...item,
          ...formatAggregatedValues(item, 'past'),
          ...formatAggregatedValues(
            item,
            `${item[`${kpi1.id}_current_accumulated`] ? 'current' : 'forecast'}`
          ),
        }))
      : aggregatedValues

  return aggregatedValuesIncludingKpi3
}

export const findLastObjectWithSuffix = (
  data: TransformedKPIData[],
  suffix: string
): TransformedKPIData | undefined => {
  return data
    .filter((item) => Object.keys(item).some((key) => key.endsWith(suffix)))
    .sort((a, b) => {
      const dateA = a.date_current || a.date_forecast || a.date_past
      const dateB = b.date_current || b.date_forecast || b.date_past
      return new Date(dateB).getTime() - new Date(dateA).getTime()
    })[0]
}

export const calculatePercentageChange = (
  newValue: number,
  oldValue: number
) => {
  if (!oldValue) return null

  if (oldValue === 0) return null

  if (oldValue === newValue) return 0

  return (newValue - oldValue) / oldValue
}

export const getAccumulatedValue = (
  obj: TransformedKPIData,
  kpiId: string,
  suffix: string
): number => {
  return obj[`${kpiId}_${suffix}_accumulated`] || null
}

export const generateKPICardData = (
  chartData: TransformedKPIData[],
  kpis: KPICard[]
): KPICardData[] | TransformedKPIData[] => {
  if (chartData.length === 0) {
    return chartData
  }

  const totalPast = findLastObjectWithSuffix(chartData, '_past') || {}
  const totalCurrent = findLastObjectWithSuffix(chartData, '_current') || {}
  const totalForecast = findLastObjectWithSuffix(chartData, '_forecast') || {}

  const kpisTotal = kpis.map((kpi) => {
    const pastTotal = getAccumulatedValue(totalPast, kpi.details.id, 'past')

    const currentTotal = getAccumulatedValue(
      totalCurrent,
      kpi.details.id,
      'current'
    )
    const forecastTotal = getAccumulatedValue(
      totalForecast,
      kpi.details.id,
      'forecast'
    )

    const currentTotalChange = calculatePercentageChange(
      currentTotal,
      pastTotal
    )

    const forecastTotalChange = calculatePercentageChange(
      forecastTotal,
      pastTotal
    )

    return {
      id: kpi.id,
      details: kpi.details,
      pastTotal: pastTotal ?? null,
      currentTotal: currentTotal ?? null,
      currentTotalChange: currentTotalChange ?? null,
      forecastTotal: forecastTotal ?? null,
      forecastTotalChange: forecastTotalChange ?? null,
      color: kpi.color,
    }
  })

  const totalKpi1 = kpisTotal.find((kpi) => kpi.id === 'kpi1')
  const totalKpi2 = kpisTotal.find((kpi) => kpi.id === 'kpi2')

  const pastTotalKpi1 = totalKpi1?.pastTotal || 0
  const pastTotalKpi2 = totalKpi2?.pastTotal || 0
  const pastRatioKpi3 = (pastTotalKpi1 / pastTotalKpi2) * 100

  const currentTotalKpi1 = totalKpi1?.currentTotal || 0
  const currentTotalKpi2 = totalKpi2?.currentTotal || 0
  const currentRatioKpi3 = (currentTotalKpi1 / currentTotalKpi2) * 100

  const forecastTotalKpi1 = totalKpi1?.forecastTotal || 0
  const forecastTotalKpi2 = totalKpi2?.forecastTotal || 0
  const forecastRatioKpi3 = (forecastTotalKpi1 / forecastTotalKpi2) * 100

  const currentTotalChangekpi3 =
    (currentRatioKpi3 - pastRatioKpi3) / pastRatioKpi3
  const forecastTotalChangekpi3 =
    (forecastRatioKpi3 - pastRatioKpi3) / pastRatioKpi3

  const kpisTotalWithPercent = kpisTotal.map((kpi) =>
    kpi.pastTotal !== 0 && kpi.details.format === KPI_FORMAT.percent
      ? {
          ...kpi,
          currentTotalChange: currentTotalChangekpi3,
          forecastTotalChange: forecastTotalChangekpi3,
        }
      : kpi
  )

  return kpisTotalWithPercent
}

export const updateSecondRowKpiCardData = (
  firstRowData: KPICardData[] | TransformedKPIData[],
  secondRowData: KPICardData[] | TransformedKPIData[],
  isCurrent: boolean
): (KPICardData | TransformedKPIData)[] => {
  return firstRowData.map((data, index) => ({
    ...data,
    forecastTotal:
      (isCurrent
        ? secondRowData[index]?.forecastTotal
        : secondRowData[index]?.currentTotal) ?? null,
    forecastTotalChange:
      (isCurrent
        ? secondRowData[index]?.forecastTotalChange
        : secondRowData[index]?.currentTotalChange) ?? null,
  }))
}

export const swapAccumulatedKpiValues = (
  data: TransformedKPIData[],
  currentKpiSuffix: string,
  forecastKpiSuffix: string
): TransformedKPIData[] => {
  if (data.length === 0) {
    return data
  }

  let lastCurrentItem: TransformedKPIData | null = null
  let firstForecastItem: TransformedKPIData | null = null

  data.forEach((item) => {
    if (Object.keys(item).some((key) => key.includes(currentKpiSuffix))) {
      lastCurrentItem = item
    }
    if (
      firstForecastItem === null &&
      Object.keys(item).some((key) => key.includes(forecastKpiSuffix))
    ) {
      firstForecastItem = item
    }
  })

  if (lastCurrentItem === null || firstForecastItem === null) {
    return data
  }

  return data.map((item) => {
    if (item === lastCurrentItem) {
      return {
        ...item,
        [forecastKpiSuffix]: item[currentKpiSuffix],
      }
    }
    if (item === firstForecastItem) {
      return {
        ...item,
        [currentKpiSuffix]: item[forecastKpiSuffix],
      }
    }
    return item
  })
}

export const extractCompareAndSelectedMonths = (
  data: KPIData[],
  selectedMonthStartDate: string,
  compareMonthStartDate: string
): KPIData[] => {
  const selectedMonthStart = startOfMonth(parseISO(selectedMonthStartDate))
  const selectedMonthEnd = endOfMonth(parseISO(selectedMonthStartDate))
  const compareMonthStart = startOfMonth(parseISO(compareMonthStartDate))
  const compareMonthEnd = endOfMonth(parseISO(compareMonthStartDate))

  const isDateWithinInterval = (dateStr: string, start: Date, end: Date) => {
    const date = parseISO(dateStr)
    return isWithinInterval(date, { start, end })
  }

  const selectedMonthData = data?.filter((item) =>
    isDateWithinInterval(item.date, selectedMonthStart, selectedMonthEnd)
  )

  const compareMonthData = data?.filter((item) =>
    isDateWithinInterval(item.date, compareMonthStart, compareMonthEnd)
  )

  return [...compareMonthData, ...selectedMonthData]
}

export const extractByOneDateRange = (
  data: KPIData[],
  startDate: string,
  endDate: string
): KPIData[] => {
  return data.length > 0
    ? data?.filter((item) => {
        return item.date >= startDate && item.date <= endDate
      })
    : []
}

export const getDateTrendPageValues = (
  monthSelect: string,
  yearSelect: string,
  monthToCompareOption: MonthToCompareOption,
  monthList: string[]
) => {
  const selectedMonthIndex = monthList.indexOf(monthSelect)

  const selectedDate = new Date(Number(yearSelect), selectedMonthIndex, 1)

  const selectedMonthStartDate = startOfMonth(selectedDate)
  const selectedMonthEndDate = endOfMonth(selectedDate)

  const compareMonthStartDate =
    monthToCompareOption === 'PREVIOUS_MONTH'
      ? startOfMonth(subMonths(selectedMonthEndDate, 1))
      : startOfMonth(subYears(selectedMonthEndDate, 1))

  const compareYearStartDate = startOfYear(subYears(selectedMonthEndDate, 1))

  return {
    selectedMonthStartDate: formatDateToYearMonthDayString(
      selectedMonthStartDate
    ),
    selectedMonthEndDate: formatDateToYearMonthDayString(selectedMonthEndDate),
    compareMonthStartDate: formatDateToYearMonthDayString(
      compareMonthStartDate
    ),
    compareYearStartDate: formatDateToYearMonthDayString(compareYearStartDate),
    monthToCompareOption,
  }
}

export const filterDataByDate = (
  data: KPIData[],
  targetDate: Date | string
): KPIData[] => {
  const date = new Date(targetDate)
  const targetMonth = date.getMonth() + 1
  const targetDay = date.getDate()

  return data.filter((entry) => {
    const entryDate = new Date(entry.date)
    const entryYear = entryDate.getFullYear()
    const entryMonth = entryDate.getMonth() + 1
    const entryDay = entryDate.getDate()

    return (
      (entryYear === date.getFullYear() ||
        entryYear === date.getFullYear() - 1) &&
      (entryMonth < targetMonth ||
        (entryMonth === targetMonth && entryDay <= targetDay))
    )
  })
}

export const filterDataByCurrentDate = (
  data: any[],
  lastCurrentDate: Date | string
): KPIData[] => {
  return data.filter((obj) => {
    if (obj.date_past) {
      const pastDate = new Date(obj.date_past).getDate()
      const currentDate = new Date(lastCurrentDate).getDate()
      return pastDate <= currentDate
    }
    return true
  })
}
