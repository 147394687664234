import { Box, ToggleButton, styled } from '@mui/material'

export const DimensionSectionWrapper = styled(Box)(({ theme }) => ({
  minHeight: '64px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
}))

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    minWidth: '100px',
    margin: `${theme.spacing(0.2)} ${theme.spacing(0.2)}`,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    color: theme.palette.text.primary,
    borderColor: 'transparent',
    fontSize: theme.typography.body2.fontSize,
  },
  '&:hover.MuiToggleButton-root': {
    backgroundColor: theme.palette.background.paper,
  },
  '&.MuiToggleButton-root.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    fontWeight: 'bold',
  },
  '&.MuiToggleButton-root.Mui-disabled': {
    color: theme.palette.text.disabled,
    borderColor: 'transparent',
  },
}))
