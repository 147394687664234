import i18n from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(I18nextBrowserLanguageDetector) // detect user language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    ns: ['common', 'userDetails'], // add namespaces used in resources
    defaultNS: 'common',
    resources: {
      en: {
        common: {
          WELCOME_TO_ORBIT: 'Welcome to orbit',
          WELCOME_TO_ORBIT_HOME_PAGE:
            'Welcome back to your personal orbit ads.',
          LOG_IN_TEXT: 'Log in to your account',
          WELCOME_BACK: 'Welcome back! Please login with your user data.',
          PERSONAL_INFORMATION: 'Personal information',
          PREFERENCES: 'Preferences',
          UPGRADE: 'Upgrade - coming soon',
          FIRST_NAME: 'First name',
          LAST_NAME: 'Last name',
          E_MAIL: 'E-mail',
          PASSWORD: 'Password',
          LANGUAGE: 'Language',
          THEME: 'Theme',
          LIGHT_MODE: 'Light',
          DARK_MODE: 'Dark',
          REMEMBER_ME: 'Remember me',
          FORGOT_PASSWORD: 'Forgot password?',
          BOOK_DEMO: 'Book demo',
          ENTER_YOUR_EMAIL: 'E-mail*',
          ENTER_YOUR_PASSWORD: 'Password*',
          LOGIN: 'Login',
          ENTER_CREDENTIAL_TEXT: 'Please log in with your user data.',
          WRONG_CREDENTIAL_TEXT: 'E-mail or password wrong. Please try again.',
          TOO_MANY_REQUEST_TEXT: 'Too many requests, please try again later',
          SOMETHING_WENT_WRONG: 'Something went wrong, please try again.',
          PLEASE_WAIT: 'Please wait...',
          ENGLISH: 'English',
          GERMAN: 'German',
          IMPRINT: 'Imprint',
          DATA_PRIVACY: 'Data privacy',
          TERMS_OF_USE: 'AGB',
          COPY_RIGHT: 'orbit - powered by mso digital',
          DEVELOPMENT_OVER_TIME: 'Development over time',
          THOUSAND_SUFFIX: 'k',
          MILLION_SUFFIX: 'm',
          BILLION_SUFFIX: 'b',
          NO_RECORDS: 'No records to be displayed.',
          NO_RECORDS_SHORT: 'No records',
          ASCENDING: 'ascending',
          DESCENDING: 'descending',
          TOTAL: 'Total',
          BAR_CHART_SORT_TEXT: 'All metrics are sorted',
          BY: 'by',
          SORTED: '',
          APPLY: 'Apply',
          CANCEL: 'Cancel',
          SEARCH: 'Search',
          TYPE_TO_SEARCH: 'Type to search …',
          ADD: 'Add',
          TREND: 'Trend',
          FORECAST: 'Forecast',
          FORECAST_SHORT: 'F',
          YEAR_END_FORECAST: 'Year-end forecast',
          YEAR_END_RESULT: 'Year-end result',
          MONTH_END_FORECAST: 'Month-end forecast',
          MONTH_END_RESULT: 'Month-end result',
          TRY_AGAIN: 'Try again',
          PAGE_NOT_LOADIND_ERROR_TITLE: 'Error',
          PAGE_NOT_LOADIND_ERROR_MESSAGE:
            'Something went wrong, please try again.',
          SELECTED_DATE: 'Selected date',
          COMPARED_WITH: 'compared with',
        },
        profile: {
          HI: 'hi',
          MANAGE_ACCOUNT: 'Manage account',
          SIGN_OUT: 'Sign out',
        },
        navigation: {
          HOME: 'Home',
          PROFILE: 'Profile',
          E_COMMERCE: 'E-Commerce',
          E_COM: 'E-Com',
          LEAD: 'Lead',
          BRANDING: 'Branding',
          BRAND: 'Brand',
          EXPLORER: 'Explorer',
          TREND: 'Trend',
          SOURCES: 'Sources',
        },
        kpi: {
          COST_PER_LEAD: 'Cost per lead',
          CONVERSION_RATE: 'Conversion rate',
          CONVERSIONS: 'Conversions',
          CLICK_THROUGH_RATE: 'Click through rate',
          COST_PER_MILLE: 'Cost per mille',
          ENGAGEMENT_RATE: 'Engagement rate',
          AVG_SESSION_DURATION: 'AVG session duration',
          COST_REVENUE_RATIO: 'Cost revenue ratio',
          AVERAGE_ORDER_VALUE: 'AVG order value',
          COST: 'Cost',
          CLICKS: 'Clicks',
          LEADS: 'Leads',
          REVENUE: 'Revenue',
          IMPRESSIONS: 'Impressions',
          ENGAGED_SESSIONS: 'Engaged sessions',
          SESSIONS_GA: 'Sessions GA',
          SESSION_DURATION: 'Session duration',
        },
        filter: {
          FILTER: 'Filter',
          TYPE_OF_FILTER: 'Type of filter',
          BASIC_FILTER: 'Basic filter',
          ADVANCED_FILTER: 'Advanced filter',
          DIMENSION_FILTER: 'Dimension filter',
          SELECT: 'Select',
          SELECTED: 'Selected',
          NOTHING_TO_SELECT: 'Nothing to select',
          NOTHING_TO_SELECT_BASED_ON_SEARCH:
            'Nothing to select based on search term',
          NOTHING_SELECTED: 'Nothing selected',
          NOTHING_SELECTED_BASED_ON_SEARCH:
            'Nothing selected based on search term',
          SELECT_ALL: 'Select all',
          SELECT_ALL_SEARCH_RESULTS: 'Select all search results',
          DESELECT_ALL: 'Deselect all',
          DESELECT_ALL_SEARCH_RESULTS: 'Deselect all search results',
          SELECT_DATE_PERIOD: 'Select date period',
          SELECT_DATE_RANGE: 'Choose date range',
          MONTH_COMPARE_WITH: 'Month compare with',
          MONTH_COMPARE_WITH_INFO_TEXT:
            'For the month dimension, choose whether the selected month/year combination should be compared with the previous month or the same month of the previous year.',
          TREND_SELECT_DATE_PERIOD_INFO_TEXT:
            'The month and year dropdowns filter both the year and month views. For example, selecting May and 2024 will display different results based on the view chosen: </br></br> In the year view, you will see all data up to the end of May 2024, even if data is available through later months, such as September 2024. This feature can be used for generating quarterly reviews or similar analyses. </br></br> In the month view, only data for May 2024 will be shown. If May 2024 is the current month, a month-end forecast will also be displayed.',
          PREVIOUS_MONTH: 'Previous month',
          SAME_MONTH_PREVIOUS_YEAR: 'Same month previous year',
          DATE_FILTER: 'Date filter',
          RESET_ALL: 'Reset all',
          ADD_FILTER: 'Add Filter',
          FIRST_DATE_RANGE: 'First date range',
          DIMENSIONS: 'Dimensions',
          REPORTING_UNIT: 'Reporting unit',
          TRAFFIC_SOURCE: 'Traffic source',
          CAMPAIGN_TYPE: 'Campaign type',
          CAMPAIGN_NAME: 'Campaign name',
          CAMPAIGN_STATUS: 'Campaign status',
          DATE_PICKER_WARNING_MESSAGE: 'Please select a date range.',
          ELEMENTS_IF_THE_FOLLOWING_APPLIES:
            'Elements if the following applies',
          TYPE_YOUR_CONDITION: 'Type in your condition ...',
          CONTAINS_IGNORE_CASE: 'Contains (ignore case)',
          CONTAINS_NOT_IGNORE_CASE: 'Contains not (ignore case)',
          EQUALS_IGNORE_CASE: 'Equals (ignore case)',
          EQUALS_NOT_IGNORE_CASE: 'Equals not (ignore case)',
          STARTS_WITH_IGNORE_CASE: 'Starts with (ignore case)',
          STARTS_NOT_WITH_IGNORE_CASE: 'Starts not with (ignore case)',
          OR: 'Or',
          AND: 'And',
        },
        dateRange: {
          ALL: 'All',
          CUSTOM: 'Custom',
          DAYS: 'days',
          YESTERDAY: 'Yesterday',
          LAST_7_DAYS: 'Last full 7 days',
          LAST_14_DAYS: 'Last full 14 days',
          LAST_30_DAYS: 'Last full 30 days',
          LAST_WEEK: 'Last week',
          CURRENT_MONTH: 'Current month',
          LAST_MONTH: 'Last month',
          LAST_3_MONTHS: 'Last 3 months',
          LAST_6_MONTHS: 'Last 6 months',
          LAST_12_MONTHS: 'Last 12 months',
          CURRENT_YEAR: 'Current year',
          YEAR: 'Year',
          MONTH: 'Month',
        },
        info: {
          TREND_LINE_CHART_INFO_TITEL:
            'Daily overview in comparison with the previous month or previous year month',
          TREND_LINE_CHART_INFO_TEXT:
            'For the current month, the data up to yesterday is aggregated on a daily basis and expanded daily. If no or incomplete data is available for a selected month or the corresponding previous month or the previous year month, it cannot be displayed.',
          TREND_BAR_CHART_INFO_TITEL:
            'Monthly overview compared to previous year',
          TREND_BAR_CHART_INFO_TEXT:
            'For the selected month/year combination, the data up to the last day of the selected month is aggregated on a monthly basis and compared with the entire previous year. If no or incomplete data is available for a selected year or the corresponding previous year, it cannot be displayed.',
          TREND_AREA_CHART_YEARLY_CURRENT_YEAR_INFO_TITLE:
            'Year-end forecast compared to previous year',
          TREND_AREA_CHART_YEARLY_NOT_CURRENT_YEAR_INFO_TITLE:
            'Year-end result compared to previous year',
          TREND_AREA_CHART_YEARLY_CURRENT_YEAR_INFO_TEXT:
            'For the selected month/year combination, the data up to the last day of the selected month is aggregated on a monthly basis and displayed together with the year-end forecast in the area chart. If the history is available, the previous year is shown as a dashed line.',
          TREND_AREA_CHART_YEARLY_NOT_CURRENT_YEAR_INFO_TEXT:
            'The annual result for the selected year is shown in the area chart. If the history is available, the previous year is shown as a dashed line.',
          TREND_AREA_CHART_MONTHLY_CURRENT_MONTH_INFO_TITEL:
            'Month-end forecast compared to previous month or previous year',
          TREND_AREA_CHART_MONTHLY_NOT_CURRENT_MONTH_INFO_TITEL:
            'Month-end result compared to previous month or previous year',
          TREND_AREA_CHART_MONTHLY_CURRENT_MONTH_INFO_TEXT:
            "For the current month, the data up to the last available day is aggregated and displayed together with the month-end forecast in the area chart. The previous month or previous year's month (to be set in the date filter) is shown as a dashed line if the history is available.",
          TREND_AREA_CHART_MONTHLY_NOT_CURRENT_MONTH_INFO_TEXT:
            'For the selected month/year combination, the data up to the last available day is displayed aggregated in the area chart. The previous month or previous year (set in the date filter) is shown as a dashed line if the history is available.',
          TREND_CARD_CURRENT_YEARLY_INFO_TITEL:
            'Percentage change on the same period previous year',
          TREND_CARD_CURRENT_YEARLY_INFO_TEXT:
            'The percentage figure shows the percentage change in the cumulative overall performance of the selected month/year combination compared with the same period in the previous year.',
          TREND_CARD_RESULT_YEARLY_INFO_TITEL:
            "Percentage change on previous year's result",
          TREND_CARD_RESULT_YEARLY_INFO_TEXT:
            'The percentage figure shows the percentage change in the annual result for the selected year compared to the previous year.',
          TREND_CARD_FORECAST_YEARLY_INFO_TITEL:
            "Percentage change on previous year's result",
          TREND_CARD_FORECAST_YEARLY_INFO_TEXT:
            'The percentage figure shows the percentage change in the calculated year-end forecast for the selected year compared to the annual result for the previous year.',
          TREND_CARD_CURRENT_MONTHLY_INFO_TITEL:
            'Percentage change on previous month or previous year month',
          TREND_CARD_CURRENT_MONTHLY_INFO_TEXT:
            'The percentage figure shows the percentage change in the cumulative overall performance of the selected month compared to the same period of the previous month or previous year (to be set in the date filter).',
          TREND_CARD_RESULT_MONTHLY_INFO_TITEL:
            'Percentage change on previous month or previous year month',
          TREND_CARD_RESULT_MONTHLY_INFO_TEXT:
            'The percentage figure shows the percentage change in the cumulative overall performance of the selected month compared to the same period of the previous month or previous year (to be set in the date filter).',
          TREND_CARD_FORECAST_MONTHLY_INFO_TITEL:
            'Percentage change on previous month or previous year month',
          TREND_CARD_FORECAST_MONTHLY_INFO_TEXT:
            'The percentage figure shows the percentage change in the cumulative overall performance of the selected month compared to the same period of the previous month or previous year (to be set in the date filter).',
          UPGRADE_PACKAGE_TITEL:
            'Looking to set new objectives for your campaigns?',
          UPGRADE_PACKAGE_TEXT:
            "This report type is not currently in use. If you're interested in exploring campaigns with different goals and objectives, please reach out to your account manager for further assistance.",
        },
      },
      de: {
        common: {
          WELCOME_TO_ORBIT: 'Willkommen bei orbit',
          WELCOME_TO_ORBIT_HOME_PAGE:
            'Willkommen zurück zu Deinem persönlichen orbit ads.',
          LOG_IN_TEXT: 'Logge Dich ein',
          WELCOME_BACK: 'Willkommen zurück! Bitte melde Dich an.',
          PERSONAL_INFORMATION: 'Persönliche Informationen',
          PREFERENCES: 'Präferenzen',
          UPGRADE: 'Upgrade - bald verfügbar',
          FIRST_NAME: 'Vorname',
          LAST_NAME: 'Nachname',
          E_MAIL: 'E-Mail',
          PASSWORD: 'Passwort',
          LANGUAGE: 'Sprache',
          THEME: 'Design',
          LIGHT_MODE: 'Hell',
          DARK_MODE: 'Dunkel',
          REMEMBER_ME: 'Angemeldet bleiben',
          FORGOT_PASSWORD: 'Passwort vergessen?',
          BOOK_DEMO: 'Demo buchen',
          ENTER_YOUR_EMAIL: 'E-Mail*',
          ENTER_YOUR_PASSWORD: 'Passwort*',
          LOGIN: 'Anmelden',
          ENTER_CREDENTIAL_TEXT: 'Mit Benutzerdaten anmelden.',
          WRONG_CREDENTIAL_TEXT:
            'E-Mail oder Passwort falsch. Bitte erneut versuchen.',
          TOO_MANY_REQUEST_TEXT:
            'Zu viele Anfragen, bitte versuchen Sie es später erneut',
          SOMETHING_WENT_WRONG:
            'Ein Fehler ist aufgetreten, bitte versuche es erneut.',
          PLEASE_WAIT: 'Bitte warten...',
          ENGLISH: 'Englisch',
          GERMAN: 'Deutsch',
          IMPRINT: 'Impressum',
          DATA_PRIVACY: 'Datenschutz',
          TERMS_OF_USE: 'AGB',
          COPY_RIGHT: 'orbit - unterstützt durch mso digital',
          DEVELOPMENT_OVER_TIME: 'Entwicklung im Zeitverlauf',
          THOUSAND_SUFFIX: 't',
          MILLION_SUFFIX: 'mi',
          BILLION_SUFFIX: 'md',
          NO_RECORDS: 'Es können keine Datensätze angezeigt werden.',
          NO_RECORDS_SHORT: 'keine Datensätze',
          ASCENDING: 'aufsteigend',
          DESCENDING: 'absteigend',
          TOTAL: 'Gesamt',
          BAR_CHART_SORT_TEXT: 'Alle Metriken sind',
          BY: 'nach',
          SORTED: 'sortiert',
          APPLY: 'Anwenden',
          CANCEL: 'Abbrechen',
          SEARCH: 'Suche',
          TYPE_TO_SEARCH: 'Suchbegriff eingeben …',
          ADD: 'Hinzufügen',
          TREND: 'Entwicklung',
          FORECAST: 'Prognose',
          FORECAST_SHORT: 'P',
          YEAR_END_FORECAST: 'Jahresendprognose',
          YEAR_END_RESULT: 'Jahresendergebnis',
          MONTH_END_FORECAST: 'Monatsendprognose',
          MONTH_END_RESULT: 'Monatsendergebnis',
          TRY_AGAIN: 'Erneut versuchen',
          PAGE_NOT_LOADIND_ERROR_TITLE: 'Fehler',
          PAGE_NOT_LOADIND_ERROR_MESSAGE:
            'Ein Fehler ist aufgetreten, bitte versuche es erneut.',
          SELECTED_DATE: 'Ausgewähltes Datum',
          COMPARED_WITH: 'verglichen mit',
        },
        profile: {
          HI: 'hallo',
          MANAGE_ACCOUNT: 'Konto verwalten',
          SIGN_OUT: 'Abmelden',
        },
        navigation: {
          HOME: 'Start',
          PROFILE: 'Profil',
          E_COMMERCE: 'E-Commerce',
          E_COM: 'E-Com',
          LEAD: 'Lead',
          BRANDING: 'Branding',
          BRAND: 'Brand',
          EXPLORER: 'Explorer',
          TREND: 'Trend',
          SOURCES: 'Sources',
        },
        kpi: {
          COST_PER_LEAD: 'Kosten pro Lead',
          CONVERSION_RATE: 'Conversion rate',
          CONVERSIONS: 'Conversions',
          CLICK_THROUGH_RATE: 'Klickrate',
          COST_PER_MILLE: 'Tausenderkontaktpreis',
          ENGAGEMENT_RATE: 'Interaktionsrate',
          AVG_SESSION_DURATION: '⌀ Sitzungsdauer',
          COST_REVENUE_RATIO: 'Kosten-Umsatz-Relation',
          AVERAGE_ORDER_VALUE: '⌀ Bestellwert',
          COST: 'Kosten',
          CLICKS: 'Klicks',
          LEADS: 'Leads',
          REVENUE: 'Umsatz',
          IMPRESSIONS: 'Impressionen',
          ENGAGED_SESSIONS: 'Interaktive Sitzungen',
          SESSIONS_GA: 'Sitzungen GA',
          SESSION_DURATION: 'Sitzungsdauer',
        },
        filter: {
          FILTER: 'Filter',
          TYPE_OF_FILTER: 'Filtertyp',
          BASIC_FILTER: 'Standardfilter',
          ADVANCED_FILTER: 'Erweiterter Filter',
          DIMENSION_FILTER: 'Dimensionsfilter',
          SELECT: 'Auswählbar',
          SELECTED: 'Ausgewählt',
          NOTHING_TO_SELECT: 'Nichts auszuwählen',
          NOTHING_TO_SELECT_BASED_ON_SEARCH:
            'Keine Ergebnisse für den Suchbegriff auswählbar',
          NOTHING_SELECTED: 'Nichts ausgewählt',
          NOTHING_SELECTED_BASED_ON_SEARCH:
            'Keine Ergebnisse für den Suchbegriff ausgewählt',
          SELECT_ALL: 'Alles auswählen',
          SELECT_ALL_SEARCH_RESULTS: 'Alle Suchergebnisse auswählen',
          DESELECT_ALL: 'Alles abwählen',
          DESELECT_ALL_SEARCH_RESULTS: 'Alle Suchergebnisse abwählen',
          SELECT_DATE_PERIOD: 'Wähle einen Datumszeitraum',
          SELECT_DATE_RANGE: 'Wähle einen Datumsbereich',
          MONTH_COMPARE_WITH: 'Monatsvergleich mit',
          MONTH_COMPARE_WITH_INFO_TEXT:
            'Wähle für die Monatsansicht, ob die ausgewählte Monat-Jahres-Kombination mit dem vorherigen Monat oder dem Vorjahresmonat verglichen werden soll.',
          TREND_SELECT_DATE_PERIOD_INFO_TEXT:
            'Die Dropdowns für Monat und Jahr filtern sowohl die Jahres- als auch die Monatsansicht. Sind bspw. Mai und 2024 ausgewählt, werden je nach gewählter Ansicht unterschiedliche Ergebnisse angezeigt: </br></br>  In der Jahresansicht werden alle Daten bis Ende Mai 2024 visualisiert, auch wenn Daten für spätere Monate, wie z. B. September 2024, verfügbar sind. Diese Funktion kann für die Erstellung von Quartalsübersichten oder ähnlicher Analysen verwendet werden. </br></br> In der Monatsansicht werden nur die Daten für Mai 2024 angezeigt. Wenn der Mai 2024 der aktuelle Monat ist, wird auch eine Prognose zum Monatsende angezeigt.',
          PREVIOUS_MONTH: 'Letzter Monat',
          SAME_MONTH_PREVIOUS_YEAR: 'Vorjahresmonat',
          DATE_FILTER: 'Datumsfilter',
          ADD_FILTER: 'Filter hinzufügen',
          RESET_ALL: 'Alle zurücksetzen',
          FIRST_DATE_RANGE: 'Erster Datumsbereich',
          DIMENSIONS: 'Dimensionen',
          REPORTING_UNIT: 'Reporting unit',
          TRAFFIC_SOURCE: 'Kanal',
          CAMPAIGN_TYPE: 'Kampagnentyp',
          CAMPAIGN_NAME: 'Kampagnenname',
          CAMPAIGN_STATUS: 'Kampagnenstatus',
          DATE_PICKER_WARNING_MESSAGE: 'Bitte wählen Sie einen Datumsbereich.',
          ELEMENTS_IF_THE_FOLLOWING_APPLIES:
            'Elemente, wenn Folgendes zutrifft',
          TYPE_YOUR_CONDITION: 'Gib die Bedingung ein ...',
          CONTAINS_IGNORE_CASE: 'Enthält (Groß-/Kleinschreibung ignoriert)',
          CONTAINS_NOT_IGNORE_CASE:
            'Enthält nicht (Groß-/Kleinschreibung ignoriert)',
          EQUALS_IGNORE_CASE: 'Entspricht (Groß-/Kleinschreibung ignoriert)',
          EQUALS_NOT_IGNORE_CASE:
            'Entspricht nicht (Groß-/Kleinschreibung ignoriert)',
          STARTS_WITH_IGNORE_CASE:
            'Beginnt mit (Groß-/Kleinschreibung ignoriert)',
          STARTS_NOT_WITH_IGNORE_CASE:
            'Beginnt nicht mit (Groß-/Kleinschreibung ignoriert)',
          OR: 'Oder',
          AND: 'Und',
        },
        dateRange: {
          ALL: 'Gesamt',
          CUSTOM: 'Individuell',
          DAYS: 'Tage',
          YESTERDAY: 'Gestern',
          LAST_7_DAYS: 'Letzte volle 7 Tage',
          LAST_14_DAYS: 'Letzte volle 14 Tage',
          LAST_30_DAYS: 'Letzte volle 30 Tage',
          LAST_WEEK: 'Letzte Woche',
          CURRENT_MONTH: 'Aktueller Monat',
          LAST_MONTH: 'Letzter Monat',
          LAST_3_MONTHS: 'Letzte 3 Monate',
          LAST_6_MONTHS: 'Letzte 6 Monate',
          LAST_12_MONTHS: 'Letzte 12 Monate',
          CURRENT_YEAR: 'Laufendes Jahr',
          YEAR: 'Jahr',
          MONTH: 'Monat',
        },
        info: {
          TREND_LINE_CHART_INFO_TITEL:
            'Tagesübersicht im Vormonats- oder Vorjahresmonatsvergleich',
          TREND_LINE_CHART_INFO_TEXT:
            'Für den aktuellen Monat werden die Daten bis zum gestrigen Tag auf Tagesbasis aggregiert und täglich erweitert. Falls für einen ausgewählten Monat oder den entsprechenden Vormonat bzw. Vorjahresmonat keine oder unvollständige Daten vorliegen, können diese nicht angezeigt werden.',
          TREND_BAR_CHART_INFO_TITEL: 'Monatsübersicht im Vorjahresvergleich',
          TREND_BAR_CHART_INFO_TEXT:
            'Für die ausgewählte Monats-Jahres-Kombination werden die Daten bis zum letzten Tag des ausgewählten Monats auf Monatsbasis aggregiert und dem gesamten Vorjahr gegenübergestellt. Falls für ein ausgewähltes Jahr oder das entsprechende Vorjahr keine oder unvollständige Daten vorliegen, können diese nicht angezeigt werden.',
          TREND_AREA_CHART_YEARLY_CURRENT_YEAR_INFO_TITLE:
            'Jahresprognose im Vorjahresvergleich',
          TREND_AREA_CHART_YEARLY_NOT_CURRENT_YEAR_INFO_TITLE:
            'Jahresendergebnis im Vorjahresvergleich',
          TREND_AREA_CHART_YEARLY_CURRENT_YEAR_INFO_TEXT:
            'Für die ausgewählte Monats-Jahres-Kombination werden die Daten bis zum letzten Tag des ausgewählten Monats auf Monatsbasis aggregiert und zusammen mit der Jahresendprognose  im Flächendiagramm dargestellt. Das Vorjahr wird, sofern die Historie vorhanden ist, als gestrichelte Linie dargestellt.',
          TREND_AREA_CHART_YEARLY_NOT_CURRENT_YEAR_INFO_TEXT:
            'Das Jahresergebnis wird für das ausgewählte Jahr im Flächendiagramm dargestellt. Das Vorjahr wird, sofern die Historie vorhanden ist, als gestrichelte Linie dargestellt.',
          TREND_AREA_CHART_MONTHLY_CURRENT_MONTH_INFO_TITEL:
            'Monatsendprognose im Vormonats- bzw. Vorjahresmonatsvergleich',
          TREND_AREA_CHART_MONTHLY_NOT_CURRENT_MONTH_INFO_TITEL:
            'Monatsendergebnis im Vormonats- bzw. Vorjahresmonatsvergleich',
          TREND_AREA_CHART_MONTHLY_CURRENT_MONTH_INFO_TEXT:
            'Für den aktuellen Monat werden die Daten bis zum letzten verfügbaren Tag aggregiert und zusammen mit der Monatsendprognose im Flächendiagramm dargestellt. Der Vormonat bzw. Vorjahresmonat (einzustellen im Datumsfilter) wird, sofern die Historie vorhanden ist, als gestrichelte Linie dargestellt.',
          TREND_AREA_CHART_MONTHLY_NOT_CURRENT_MONTH_INFO_TEXT:
            'Für die ausgewählte Monats-Jahres-Kombination werden die Daten bis zum letzten verfügbaren Tag aggregiert im Flächendiagramm dargestellt. Der Vormonat bzw. Vorjahresmonat (einzustellen im Datumsfilter) wird, sofern die Historie vorhanden ist, als gestrichelte Linie dargestellt.',
          TREND_CARD_CURRENT_YEARLY_INFO_TITEL:
            'Prozentuale Veränderung zum gleichen Zeitraum des Vorjahres',
          TREND_CARD_CURRENT_YEARLY_INFO_TEXT:
            'Die Prozentzahl zeigt die prozentuale Veränderung der kumulierten Gesamtperformance der ausgewählten Monat-Jahres-Kombination im Vergleich zum gleichen Zeitraum des Vorjahres.',
          TREND_CARD_RESULT_YEARLY_INFO_TITEL:
            'Prozentuale Veränderung zum Vorjahrsergebnis',
          TREND_CARD_RESULT_YEARLY_INFO_TEXT:
            'Die Prozentzahl zeigt die prozentuale Veränderung des Jahresergebnisses des ausgewählten Jahres im Vergleich zu dem des Vorjahres.',
          TREND_CARD_FORECAST_YEARLY_INFO_TITEL:
            'Prozentuale Veränderung zum Vorjahrsergebnis',
          TREND_CARD_FORECAST_YEARLY_INFO_TEXT:
            'Die Prozentzahl zeigt die prozentuale Veränderung der berechneten Jahresendprognose des ausgewählten Jahres im Vergleich zum Jahresergebnis des Vorjahres.',
          TREND_CARD_CURRENT_MONTHLY_INFO_TITEL:
            'Prozentuale Veränderung zum Vormonat bzw. Vorjahresmonat',
          TREND_CARD_CURRENT_MONTHLY_INFO_TEXT:
            'Die Prozentzahl zeigt die prozentuale Veränderung der kumulierten Gesamtperformance des aktuellen Monats im Vergleich zum gleichen Zeitraum des Vormonats oder Vorjahresmonats (einzustellen im Datumsfilter).',
          TREND_CARD_RESULT_MONTHLY_INFO_TITEL:
            'Prozentuale Veränderung zum Vormonat bzw. Vorjahresmonat',
          TREND_CARD_RESULT_MONTHLY_INFO_TEXT:
            'Die Prozentzahl zeigt die prozentuale Veränderung der kumulierten Gesamtperformance des ausgewählten Monats im Vergleich zum gleichen Zeitraum des Vormonats oder Vorjahresmonats (Einzustellen im Datumsfilter).',
          TREND_CARD_FORECAST_MONTHLY_INFO_TITEL:
            'Prozentuale Veränderung zum Vormonat bzw. Vorjahresmonat',
          TREND_CARD_FORECAST_MONTHLY_INFO_TEXT:
            'Die Prozentzahl zeigt die prozentuale Veränderung der kumulierten Gesamtperformance des ausgewählten Monats im Vergleich zum gleichen Zeitraum des Vormonats oder Vorjahresmonats (Einzustellen im Datumsfilter).',
          UPGRADE_PACKAGE_TITEL: 'Neue Ziele für Ihre Kampagnen setzen?',
          UPGRADE_PACKAGE_TEXT:
            'Dieser Berichtstyp wird derzeit nicht verwendet. Wenn Sie Kampagnen mit anderen Zielen und Vorgaben erkunden möchten, wenden Sie sich bitte an Ihren Account Manager für weitere Unterstützung.',
        },
      },
    },
  })
