import {
  Box,
  Checkbox,
  TextField,
  ToggleButton,
  Typography,
  styled,
} from '@mui/material'

export const FilterModalWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '950px',
  minHeight: '650px',
  maxHeight: '90vh',
  overflow: 'auto',
  display: 'flex',
  backgroundColor: theme.palette.background.paper3,
  borderRadius: theme.spacing(2.5),

  [theme.breakpoints.up('md')]: {
    maxHeight: '650px',
  },
}))

export const FilterModalMenuWrapper = styled(Box)(({ theme }) => ({
  width: '280px',
  minWidth: '280px',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  gap: theme.spacing(3),
  borderRight: `2px solid ${theme.palette.background.paper2}`,
}))

export const FilterModalMainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: theme.spacing(2),
  gap: theme.spacing(3),
}))

export const FilterModalMenuItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.spacing(1.75)} ${theme.spacing(2)}`,
  gap: theme.spacing(3),
  borderRadius: theme.spacing(2),
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.background.paper2,
  },
}))

export const DateDropdownSelectWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '52px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper2,
  padding: `0 ${theme.spacing(2)}`,
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
}))

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    height: '36px',
    width: '50%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper2,
    borderColor: 'transparent',
    fontSize: theme.typography.body2.fontSize,
  },
  '&:hover.MuiToggleButton-root': {
    backgroundColor: theme.palette.background.paper3,
  },
  '&.MuiToggleButton-root.Mui-selected': {
    backgroundColor: theme.palette.background.paper3,
    fontWeight: 'bold',
  },
  '&.MuiToggleButton-root.Mui-disabled': {
    color: theme.palette.text.disabled,
    borderColor: 'transparent',
  },
}))

export const BasicFilterSearchTextFiled = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '52px',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper2,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
}))

export const UserConditionTextFiled = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '52px',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper3,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
}))

export const SelectionSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
}))

export const SelectionColumnWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: `${theme.spacing(1.5)} 0`,
  gap: theme.spacing(1),
}))

export const SelectionColumnHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

export const SelectionColumnBody = styled(Box)(({ theme }) => ({
  maxHeight: '238px',
  overflow: 'auto',
}))

export const SelectionDivider = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: theme.palette.background.brush,
}))

export const CheckboxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
}))

export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(0.5),
}))

export const CustomCheckboxLabel = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} 0`,
  wordBreak: 'break-all',
}))

export const NoItemText = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

export const AdvancedFilterConditionSelectWrapper = styled(Box)(
  ({ theme }) => ({
    width: '100%',
    height: '52px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper3,
    padding: `0 ${theme.spacing(2)}`,
    borderRadius: theme.spacing(2),
    cursor: 'pointer',
  })
)

export const AdvancedFilterSetWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper2,
}))
