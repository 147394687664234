import { useAppSelector } from 'store/redux-hooks'
import { selectTheme } from 'store/features/theme-slice'
import { selectAuth } from 'store/features/auth-slice'

import useSectionAccess from 'hooks/useSectionAccess'

import { PackageType } from 'common/constants'

import { Section } from 'common/constants-navigation'
import NavSection from '../nav-section'
import NavigationSubsection from '../nav-subsection'
import PackageTooltip from './package-tooltip'

import { Box, Collapse } from '@mui/material'

import { NavDesktopOpenWrapper } from './styles'

const NavDesktopOpen = ({
  navigationConfig,
  navigationItems,
  handleNavigation,
}: {
  navigationConfig: any
  navigationItems: Record<string, Section>
  handleNavigation: any
}) => {
  const { isGlobalView } = useAppSelector(selectTheme)
  const {
    value: { currentCustomer },
  } = useAppSelector(selectAuth)

  const userPackage = 'performance' as PackageType

  const customerReportTypes = currentCustomer ? currentCustomer.reportTypes : []

  const { isSectionActive } = useSectionAccess(
    userPackage,
    isGlobalView,
    customerReportTypes
  )

  return (
    <NavDesktopOpenWrapper>
      {Object.values(navigationItems).map((section) => (
        <Box key={section.address}>
          <PackageTooltip isAccessible={isSectionActive(section.address)}>
            <Box>
              <NavSection
                sectionConfig={section}
                navigationConfig={navigationConfig}
                handleNavigation={handleNavigation}
              />
            </Box>
          </PackageTooltip>
          <Collapse
            in={section.address === navigationConfig.expandSectionAddress}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ ml: 4, py: 1 }}>
              <NavigationSubsection
                expandSectionConfig={section}
                navigationConfig={navigationConfig}
                handleNavigation={handleNavigation}
              />
            </Box>
          </Collapse>
        </Box>
      ))}
    </NavDesktopOpenWrapper>
  )
}

export default NavDesktopOpen
