export const formatNumber = (
  highestNumber: number,
  number: number,
  currentLanguage: string
) => {
  const scales = [
    { threshold: 1e8, divisor: 1e6, abbreviation: 'm', fixed: 0 },
    { threshold: 1e7, divisor: 1e6, abbreviation: 'm', fixed: 1 },
    { threshold: 1e6, divisor: 1e6, abbreviation: 'm', fixed: 2 },
    { threshold: 1e5, divisor: 1e3, abbreviation: 'k', fixed: 0 },
    { threshold: 1e4, divisor: 1e3, abbreviation: 'k', fixed: 1 },
    { threshold: 1e3, divisor: 1e3, abbreviation: 'k', fixed: 2 },
  ]
  const roundedNumber = Math.round(number)

  if (roundedNumber > highestNumber) {
    for (const { threshold, divisor, abbreviation, fixed } of scales) {
      if (roundedNumber >= threshold) {
        return `${(roundedNumber / divisor).toLocaleString(currentLanguage, {
          maximumFractionDigits: fixed,
        })}${abbreviation}`
      }
    }
  }

  for (const { threshold, divisor, abbreviation, fixed } of scales) {
    if (highestNumber >= threshold) {
      return `${(roundedNumber / divisor).toLocaleString(currentLanguage, {
        maximumFractionDigits: fixed,
      })}${abbreviation}`
    }
  }

  if (highestNumber < 1e3) {
    if (highestNumber <= 1) {
      return `${number.toLocaleString(currentLanguage, {
        maximumFractionDigits: 2,
      })}`
    }

    return `${number.toLocaleString(currentLanguage, {
      maximumFractionDigits: 0,
    })}`
  }

  return roundedNumber
}
