import { Link } from 'react-router-dom'

import LogoSmallLight from 'assets/logo-small-light.png'

import { useAppDispatch } from 'store/redux-hooks'
import { setModal } from 'store/features/modal-slice'

import {
  DRAWER,
  MOBILE_NAVIGATION,
  SIDEBAR_DRAWER_MOBILE,
} from 'common/constants'
import { HOME } from 'common/constants-navigation'

import HeaderPanel from './header-panel'
import HeaderBreadCrumb from './header-breadcrumb'

import { Menu } from '@mui/icons-material'
import { useMediaQuery, useTheme } from '@mui/material'

import {
  FirstRowHeaderWrapper,
  HeaderWrapper,
  SecondRowHeaderWrapper,
} from './styles'

const Header = () => {
  const dispatch = useAppDispatch()

  const theme = useTheme()

  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isExtraWideScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const handleSidebarDrawerMobile = () => {
    dispatch(
      setModal({
        type: SIDEBAR_DRAWER_MOBILE,
        activeComponent: MOBILE_NAVIGATION,
      })
    )
  }

  if (isExtraWideScreen) {
    return (
      <HeaderWrapper>
        <FirstRowHeaderWrapper>
          <HeaderBreadCrumb />
          <HeaderPanel />
        </FirstRowHeaderWrapper>
      </HeaderWrapper>
    )
  }

  if (isWideScreen) {
    return (
      <HeaderWrapper>
        <FirstRowHeaderWrapper>
          <HeaderPanel />
        </FirstRowHeaderWrapper>
        <SecondRowHeaderWrapper>
          <HeaderBreadCrumb />
        </SecondRowHeaderWrapper>
      </HeaderWrapper>
    )
  }

  // isSmallScreen
  return (
    <HeaderWrapper>
      <FirstRowHeaderWrapper>
        <Link to={HOME.address}>
          <img
            src={LogoSmallLight}
            alt="company logo"
            style={{
              width: `${DRAWER.WIDTH_LOGO_SMALL}px`,
              height: 'auto',
            }}
          />
        </Link>
        <Menu onClick={handleSidebarDrawerMobile} sx={{ cursor: 'pointer' }} />
      </FirstRowHeaderWrapper>
      <SecondRowHeaderWrapper>
        <HeaderBreadCrumb />
      </SecondRowHeaderWrapper>
    </HeaderWrapper>
  )
}

export default Header
