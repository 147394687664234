import { Box, styled } from '@mui/material'

export const LegendWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

export const LineChartWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))
