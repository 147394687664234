import { useTranslation } from 'react-i18next'

import { Box, Tooltip, Typography, useTheme } from '@mui/material'

const PackageTooltip = ({
  isAccessible,
  children,
}: {
  isAccessible: boolean
  children: any
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Tooltip
      title={
        !isAccessible && (
          <Box>
            <Typography sx={{ fontWeight: 'bold' }}>
              {t('UPGRADE_PACKAGE_TITEL', { ns: 'info' })}
            </Typography>
            <Typography sx={{ py: 2 }}>
              {t('UPGRADE_PACKAGE_TEXT', { ns: 'info' })}
            </Typography>
          </Box>
        )
      }
      placement="right"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: '480px',
            bgcolor: 'background.paper',
            color: 'text.primary',
            p: 2,
            borderRadius: 3,
            boxShadow: theme.palette.customShadow.popover,
            '& .MuiTooltip-arrow': {
              color: 'background.paper',
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

export default PackageTooltip
