import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import SourcesLineChartLegend from './sources-line-chart-legend'

const SourceLineChartNoData = ({
  titleKpi1,
  titleKpi2,
  titleKpi3,
}: {
  titleKpi1: string
  titleKpi2: string
  titleKpi3: string
}) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
        borderRadius: 3,
      }}
    >
      <SourcesLineChartLegend
        titleKpi1={titleKpi1}
        titleKpi2={titleKpi2}
        titleKpi3={titleKpi3}
      />
      <Box
        sx={{
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>{t('NO_RECORDS', { ns: 'common' })}</Typography>
      </Box>
    </Box>
  )
}

export default SourceLineChartNoData
