import { useTranslation } from 'react-i18next'

import { Box, ToggleButtonGroup, Typography } from '@mui/material'

import { CustomToggleButton, DimensionSectionWrapper } from './styles'

const trendDimensions = ['YEAR', 'MONTH']

const TrendDimensionSection = ({
  dimensionFilter,
  handleDimensionFilter,
}: {
  dimensionFilter: any
  handleDimensionFilter: any
}) => {
  const { t } = useTranslation()

  return (
    <DimensionSectionWrapper>
      <Box sx={{ minWidth: '100px' }}>
        <Typography
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
        >
          {t('DIMENSIONS', {
            ns: 'filter',
          })}
        </Typography>
      </Box>
      <ToggleButtonGroup
        exclusive
        value={dimensionFilter}
        onChange={handleDimensionFilter}
        size="small"
        sx={{
          bgcolor: 'background.default',
          borderRadius: 6,
          p: 0.5,
          '& .MuiToggleButton-root': {
            textTransform: 'none',
            borderRadius: 5,
          },
        }}
      >
        {trendDimensions.map((dimension) => (
          <CustomToggleButton key={dimension} value={dimension}>
            <Box
              sx={{
                position: 'relative',
                filter: 'grayScale(100%)',
              }}
            >
              {t(`${dimension}`, {
                ns: 'dateRange',
              })}
            </Box>
          </CustomToggleButton>
        ))}
      </ToggleButtonGroup>
    </DimensionSectionWrapper>
  )
}

export default TrendDimensionSection
