import { useMemo } from 'react'

import { FeatureType } from 'common/constants'
import { Kpi } from 'common/constants-metrics'
import { getValueKpi3 } from 'common/helpers'

interface DataItem {
  [key: string]: any
}

interface AggregateData {
  [dimension: string]: any
  details: any[]
}

interface UseAggregateDataParams {
  data: DataItem[]
  dimension: string
  kpi1: Kpi
  kpi2: Kpi
  kpi3: Kpi
  totalKpi1: number
  totalKpi2: number
}

const useAggregateData = ({
  data,
  dimension,
  kpi1,
  kpi2,
  kpi3,
  totalKpi1,
  totalKpi2,
}: UseAggregateDataParams) => {
  const totals = useMemo<AggregateData[]>(() => {
    const isTrafficSource = dimension === FeatureType.TrafficSource

    const sums = data.reduce<AggregateData>((acc, item) => {
      const valueKpi1 = item[kpi1.id]
      const valueKpi2 = item[kpi2.id]
      const reportingUnitName = item[FeatureType.ReportingUnit]
      const trafficSourceName = item[FeatureType.TrafficSource]
      const dimensionName = item[dimension]

      if (!acc[dimensionName]) {
        acc[dimensionName] = {
          [dimension]: dimensionName,
          [kpi1.id]: 0,
          [`relative_${kpi1.id}_percent`]: 0,
          [kpi2.id]: 0,
          [`relative_${kpi2.id}_percent`]: 0,
          [kpi3.id]: 0,
          details: [],
        }
      }

      acc[dimensionName][kpi1.id] += valueKpi1
      acc[dimensionName][kpi2.id] += valueKpi2

      if (isTrafficSource) {
        acc[dimensionName].details.push({
          [FeatureType.ReportingUnit]: reportingUnitName,
          [kpi1.id]: valueKpi1,
          [kpi2.id]: valueKpi2,
          [kpi3.id]: getValueKpi3(valueKpi1, valueKpi2, kpi3.format),
        })
      }
      if (!isTrafficSource) {
        acc[dimensionName].details.push({
          [FeatureType.TrafficSource]: trafficSourceName,
          [kpi1.id]: valueKpi1,
          [kpi2.id]: valueKpi2,
          [kpi3.id]: getValueKpi3(valueKpi1, valueKpi2, kpi3.format),
        })
      }

      return acc
    }, {} as AggregateData)

    return Object.values(sums)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dimension])

  const totalsAndRelativeTotals = useMemo(() => {
    return totals.map((item) => ({
      ...item,
      [`relative_${kpi1.id}_percent`]: Math.round(
        ((item[kpi1.id] / totalKpi1) * 100).toFixed(2) as any
      ),
      [`relative_${kpi2.id}_percent`]: Math.round(
        ((item[kpi2.id] / totalKpi2) * 100).toFixed(2) as any
      ),
      [kpi3.id]: getValueKpi3(item[kpi1.id], item[kpi2.id], kpi3.format),

      details: item.details.map((i: any) => ({
        ...i,
        [`relative_${kpi1.id}_percent`]: Math.round(
          (i[kpi1.id] / item[kpi1.id]) * 100
        ),
        [`relative_${kpi2.id}_percent`]: Math.round(
          (i[kpi2.id] / item[kpi2.id]) * 100
        ),
      })),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals])

  return totalsAndRelativeTotals
}

export default useAggregateData
