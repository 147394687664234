import { useTranslation } from 'react-i18next'

import { Section } from 'common/constants-navigation'

import { SvgIconComponent } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'

import { NavKpiPath, NavSubsection } from './styles'

const NavigationSubsection = ({
  navigationConfig,
  expandSectionConfig,
  handleNavigation,
}: {
  expandSectionConfig: Section
  navigationConfig: any
  handleNavigation: any
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const renderIcon = (
    isSelected: boolean,
    Icon: SvgIconComponent,
    SelectIcon?: SvgIconComponent
  ) => {
    return isSelected && SelectIcon ? <SelectIcon /> : <Icon />
  }

  return (
    <Box>
      {!!expandSectionConfig?.subsections &&
        expandSectionConfig.subsections.map((subsection, index) => (
          <Box key={subsection.address}>
            <NavSubsection
              isSelect={
                navigationConfig.selectSubsectionAddress ===
                `${expandSectionConfig.address}/${subsection.address}`
              }
            >
              {renderIcon(
                navigationConfig.selectSubsectionAddress === subsection.address,
                subsection.icon
              )}
              <Typography
                variant="body2"
                sx={{
                  color: 'text.primary',
                  fontWeight: 700,
                }}
              >
                {t(`${subsection.title}`, { ns: 'navigation' })}
              </Typography>
            </NavSubsection>
            {subsection.kpiPath.map((kpi) => (
              <NavKpiPath
                key={kpi.address}
                isSelect={
                  navigationConfig.selectKpiAddress ===
                  `${expandSectionConfig.address}/${subsection.address}/${kpi.address}`
                }
                onClick={() =>
                  handleNavigation({
                    action: 'select',
                    isSubsections: true,
                    expandSectionAddress: expandSectionConfig.address,
                    selectSectionAddress: expandSectionConfig.address,
                    selectSubsectionAddress: `${expandSectionConfig.address}/${subsection.address}`,
                    selectKpiAddress: `${expandSectionConfig.address}/${subsection.address}/${kpi.address}`,
                  })
                }
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight:
                      navigationConfig.selectKpiAddress ===
                      `${expandSectionConfig.address}/${subsection.address}/${kpi.address}`
                        ? 700
                        : 'inherit',
                  }}
                >
                  {t(`${kpi.title}`, { ns: 'kpi' })}
                </Typography>
              </NavKpiPath>
            ))}
            {!!expandSectionConfig?.subsections &&
              index < expandSectionConfig.subsections.length - 1 && (
                <Box
                  sx={{
                    mt: 1,
                    mb: 2,
                    borderBottom: `0.1rem solid ${theme.palette.background.disabled}`,
                  }}
                />
              )}
          </Box>
        ))}
    </Box>
  )
}

export default NavigationSubsection
