import { useNavigate } from 'react-router-dom'

import { HOME } from 'common/constants-navigation'

import { Alert, AlertTitle, Button } from '@mui/material'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Alert severity="error" sx={{ borderRadius: 3, padding: 2, margin: 2 }}>
      <AlertTitle sx={{ fontWeight: 'bold' }}>404 - Page not found</AlertTitle>

      <Button
        onClick={() => navigate(HOME.address)}
        variant="contained"
        sx={{
          minWidth: '136px',
          height: '40px',
          borderRadius: 2,
          fontWeight: 'bold',
          textTransform: 'capitalize',
          mt: 3,
        }}
      >
        {HOME.title}
      </Button>
    </Alert>
  )
}

export default NotFound
