import { MouseEvent, useState } from 'react'

import { useTranslation } from 'react-i18next'

import LogoSmallLight from 'assets/logo-small-light.png'

import { DRAWER } from 'common/constants'

import {
  Box,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

const PackagePopover = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'pro-popover' : undefined

  return (
    <Box sx={{ position: 'absolute', bottom: '-16px', right: 0 }}>
      <IconButton aria-describedby={id} onClick={handleClick} disableRipple>
        <img
          src={LogoSmallLight}
          alt="company logo"
          style={{
            width: `${DRAWER.WIDTH_PACKAGE}px`,
            height: 'auto',
          }}
        />
      </IconButton>
      <Popover
        id={id}
        open={isWideScreen ? false : open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ '& .MuiPopover-paper': { borderRadius: 3 } }}
      >
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('UPGRADE_PACKAGE_TITEL', { ns: 'info' })}
          </Typography>
          <Typography sx={{ py: 2 }}>
            {t('UPGRADE_PACKAGE_TEXT', { ns: 'info' })}
          </Typography>
        </Box>
      </Popover>
    </Box>
  )
}

export default PackagePopover
