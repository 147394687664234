import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'
import { setModal } from 'store/features/modal-slice'

import { getIsRememberMe, persistItem } from 'common/helpers'
import { LOGIN, PROFILE } from 'common/constants-navigation'

import LegalLinks from 'layout/footer/legal-links'

import {
  ArrowCircleUpOutlined,
  DarkModeOutlined,
  Language,
  LogoutRounded,
  Person,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Chip,
  Divider,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

const UserMenu = ({ handleClose }: any) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))

  const {
    value: { userInfo },
  } = useAppSelector(selectAuth)

  const handleUserProfile = () => {
    navigate(PROFILE.address)
    isWideScreen
      ? handleClose()
      : dispatch(setModal({ type: '', activeComponent: '' }))
  }

  const handleSignOut = () => {
    const isRememberMe = getIsRememberMe()
    const isLogout = true
    persistItem('auth', null, isRememberMe, isLogout)
    !isRememberMe && persistItem('theme', null, isRememberMe, isLogout)
    window.location.href = `/${LOGIN.address}`
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mb: 4,
          mt: { xs: '-32px', md: 0 },
        }}
      >
        <Avatar
          sx={{
            width: 64,
            height: 64,
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              bgcolor: 'primary.main',
              color: theme.palette.text.contrastText,
              fontWeight: 'bold',
            }}
          >
            {`${userInfo?.firstName.slice(0, 1).toUpperCase()}${userInfo?.lastName.slice(0, 1).toUpperCase()}`}
          </Typography>
        </Avatar>
        <Typography
          variant="h6"
          sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
        >
          {t('HI', { ns: 'profile' })} {userInfo?.firstName}
        </Typography>
        <Chip
          color="primary"
          variant="outlined"
          label={t('MANAGE_ACCOUNT', { ns: 'profile' })}
          clickable
          sx={{
            height: '40px',
            py: 1.5,
            px: 2,
            borderRadius: '20px',
            '& .MuiChip-label': { fontSize: theme.typography.body2 },
          }}
          onClick={handleUserProfile}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 3,
          border: `1px solid ${theme.palette.background.paper2}`,
          py: 1,
        }}
      >
        <MenuItem>
          <ArrowCircleUpOutlined />
          <Typography ml={2}>{t('UPGRADE', { ns: 'common' })}</Typography>
        </MenuItem>

        <Divider
          variant="middle"
          sx={{
            '&.MuiDivider-middle': { m: 0.5 },
          }}
        />

        <MenuItem onClick={handleUserProfile}>
          <Person />
          <Typography ml={2}>{t('PROFILE', { ns: 'navigation' })}</Typography>
        </MenuItem>
        <MenuItem onClick={handleUserProfile}>
          <DarkModeOutlined />
          <Typography ml={2}>{t('THEME', { ns: 'common' })}</Typography>
        </MenuItem>
        <MenuItem onClick={handleUserProfile}>
          <Language />
          <Typography ml={2}>{t('LANGUAGE', { ns: 'common' })}</Typography>
        </MenuItem>

        <Divider
          variant="middle"
          sx={{
            '&.MuiDivider-middle': { m: 0.5 },
          }}
        />

        <MenuItem onClick={handleSignOut}>
          <LogoutRounded />
          <Typography ml={2}>{t('SIGN_OUT', { ns: 'profile' })}</Typography>
        </MenuItem>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          pt: 3,
          mt: 'auto',
        }}
      >
        <LegalLinks />
      </Box>
    </Box>
  )
}

export default UserMenu
