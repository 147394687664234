import { Section } from 'common/constants-navigation'

import NavDesktopMini from './nav-desktop-mini'
import NavDesktopOpen from './nav-desktop-open'

import { Box } from '@mui/material'

const NavDesktop = ({
  isOpenSidebarDrawer,
  navigationConfig,
  navigationItems,
  handleNavigation,
}: {
  isOpenSidebarDrawer: boolean
  navigationConfig: any
  navigationItems: Record<string, Section>
  handleNavigation: any
}) => {
  return (
    <Box>
      {isOpenSidebarDrawer ? (
        <NavDesktopOpen
          navigationConfig={navigationConfig}
          navigationItems={navigationItems}
          handleNavigation={handleNavigation}
        />
      ) : (
        <NavDesktopMini
          selectSection={navigationConfig.selectSectionAddress}
          navigationItems={navigationItems}
        />
      )}
    </Box>
  )
}

export default NavDesktop
