import { CUSTOMER_MENU, HEADER } from 'common/constants'

import { Box, BoxProps, styled } from '@mui/material'
import { createShouldForwardProp } from 'common/helpers'

interface CustomerSelectWrapperProps extends BoxProps {
  isOpen: boolean
}

const CustomerSelectWrapperForwardProps = createShouldForwardProp(['isOpen'])

export const CustomerSelectWrapper = styled(Box, {
  shouldForwardProp: CustomerSelectWrapperForwardProps,
})<CustomerSelectWrapperProps>(({ theme, isOpen }) => ({
  width: `${CUSTOMER_MENU.WIDTH_DESKTOP_WIDE}px`,
  height: HEADER.HEIGHT_DESKTOP,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: isOpen
    ? theme.palette.background.paper3
    : theme.palette.background.paper,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderLeft: `2px solid ${theme.palette.background.default}`,
  borderRight: `2px solid ${theme.palette.background.default}`,
  cursor: 'pointer',

  [theme.breakpoints.up('lg')]: {
    width: `${CUSTOMER_MENU.WIDTH_DESKTOP_EXTRA_WIDE}px`,
  },
}))
