import { useTranslation } from 'react-i18next'

import { Box, InputBase, Typography, useTheme } from '@mui/material'

interface Customer {
  customerId?: number
  customerName?: string
  currency?: string
  reportingUnits?: number
}

const CustomerSearch = ({
  userSearch,
  selectedCustomer,
  sortedCustomerOptions,
  handleUserSearch,
  handleCustomerSelect,
}: {
  userSearch: any
  selectedCustomer: Customer
  sortedCustomerOptions: Customer[]
  handleUserSearch: any
  handleCustomerSelect: any
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box sx={{ width: '100%' }}>
      <InputBase
        role="searchbox"
        autoFocus
        onChange={(e) => handleUserSearch(e.target.value)}
        value={userSearch}
        sx={{
          width: '100%',
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          backgroundColor: 'background.default',
          borderRadius: '40px',
        }}
        placeholder={t('TYPE_TO_SEARCH', {
          ns: 'common',
        })}
      />
      <Box sx={{ maxHeight: '400px', overflow: 'auto', p: 1 }}>
        {sortedCustomerOptions.length > 0 ? (
          sortedCustomerOptions.map((option) => (
            <Box
              key={option?.customerId}
              onClick={() => handleCustomerSelect(option)}
              sx={{
                py: { xs: 2, md: 1 },
                px: 1,
                borderBottom: {
                  xs: `1px solid ${theme.palette.background.default}`,
                  md: 'none',
                },
                '&:hover': {
                  backgroundColor: theme.palette.background.paper2,
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  wordBreak: 'break-all',
                  color:
                    option?.customerName === selectedCustomer?.customerName
                      ? 'primary.main'
                      : 'inherit',
                }}
              >
                {option?.customerName}
              </Typography>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              py: { xs: 2, md: 1 },
              px: 1,
              borderBottom: {
                xs: `1px solid ${theme.palette.background.default}`,
                md: 'none',
              },
              '&:hover': {
                backgroundColor: theme.palette.background.paper2,
                cursor: 'pointer',
              },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: 'primary.main',
              }}
            >
              No result for your search
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CustomerSearch
