import { useMemo } from 'react'

const useCalculateTotal = (data: any[], key: string) => {
  const total = useMemo(
    () => data.reduce((acc, item) => acc + (item[key] || 0), 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  return total
}

export default useCalculateTotal
