import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'

const SourceBarChartNoData = ({
  titleKpi1,
  titleKpi2,
  titleKpi3,
}: {
  titleKpi1: string
  titleKpi2: string
  titleKpi3: string
}) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
        borderRadius: 3,
        padding: 3,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {[titleKpi1, titleKpi2, titleKpi3].map((title) => (
          <Box
            key={title}
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {t(title, { ns: 'kpi' })}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>{t('NO_RECORDS', { ns: 'common' })}</Typography>
      </Box>
    </Box>
  )
}

export default SourceBarChartNoData
