import React from 'react'
import { TooltipProps } from 'recharts'
import { useTranslation } from 'react-i18next'

import { FeatureType } from 'common/constants'
import { getKpiValueAndSign } from 'common/helpers'

import { Box, Typography, useTheme } from '@mui/material'

interface CustomTooltipProps extends TooltipProps<number, string> {
  kpiFormat: string
  currency: string
  isTrafficSource: boolean
  isKpi3: boolean
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  coordinate,
  viewBox,
  label,
  kpiFormat,
  currency,
  isTrafficSource,
  isKpi3,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]

  const kpi = payload ? payload[0] : { value: null, payload: '', name: '' }
  const kpiId = kpi ? (kpi.name as string) : ''
  const kpiTotalValue = kpi ? kpi.value : null
  const kpiDetails = kpi ? kpi.payload : {}
  const kpiRelativePercent = `${kpiDetails[`relative_${kpiId}_percent`] === 0 ? 0 : kpiDetails[`relative_${kpiId}_percent`] || '- '}%`

  const isTooltipAvailable = active && payload && payload.length

  if (!isTooltipAvailable) return null

  const dimensionDetails = kpiDetails ? kpiDetails.details : []

  const sortedDimensionDetails = dimensionDetails.sort((a: any, b: any) => {
    const isNumberA = typeof a[kpiId] === 'number'
    const isNumberB = typeof b[kpiId] === 'number'

    if (isNumberA && isNumberB) {
      return (
        (b[kpiId] !== Infinity ? b[kpiId] : -Infinity) -
        (a[kpiId] !== Infinity ? a[kpiId] : -Infinity)
      )
    }

    if (isNumberA) {
      return -1
    }
    if (isNumberB) {
      return 1
    }
    return 0
  })

  const tooltipX = coordinate?.x ?? 0
  const tooltipY = coordinate?.y ?? 0

  const chartHeight = viewBox?.height ?? 0

  const tooltipPosition = {
    left: tooltipX - (isKpi3 ? 200 : 100),
    top: tooltipY > chartHeight / 2 ? tooltipY - 100 : tooltipY - 50,
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        left: `${tooltipPosition.left}px`,
        top: `${tooltipPosition.top}px`,
        width: '275px',
        maxHeight: '224px',
        overflowY: 'auto',
        pointerEvents: 'auto',
        p: 2,
        borderRadius: 2,
        bgcolor: theme.palette.background.paper3,
        boxShadow: theme.palette.customShadow.popover,
        transition: 'top 0.3s ease',
      }}
    >
      <Typography
        fontWeight="bold"
        sx={{ textTransform: 'capitalize', wordBreak: 'break-all' }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
          pb: 0.5,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography>{t('TOTAL', { ns: 'common' })}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography fontWeight="bold">
            {getKpiValueAndSign(
              kpiTotalValue,
              kpiFormat,
              currentlanguage,
              currency
            )}
          </Typography>
          {!isKpi3 && (
            <Box
              sx={{
                width: '32px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant="caption">{kpiRelativePercent}</Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 1,
          gap: 1,
        }}
      >
        {sortedDimensionDetails.map((item: any) => (
          <Box
            key={
              isTrafficSource
                ? item[FeatureType.ReportingUnit]
                : item[FeatureType.TrafficSource]
            }
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body2">
              {isTrafficSource
                ? item[FeatureType.ReportingUnit]
                : item[FeatureType.TrafficSource]}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                {item[kpiId] === Infinity || item[kpiId] === undefined
                  ? '-'
                  : getKpiValueAndSign(
                      item[kpiId],
                      kpiFormat,
                      currentlanguage,
                      currency
                    )}
              </Typography>

              {!isKpi3 && (
                <Box
                  sx={{
                    width: '32px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography variant="caption">
                    {item[`relative_${kpiId}_percent`] === 0
                      ? 0
                      : item[`relative_${kpiId}_percent`] || '- '}
                    %
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default CustomTooltip
