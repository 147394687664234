export const baseFontFamily = 'Lato'
export const basePalette = {
  light: {
    primary: {
      light: '#9751E3',
      main: '#7200D6',
      dark: '#A9B7C5',
      contrastText: '#fff',
    },
    secondary: {
      light: '#71AEFF',
      main: '#3D92FF',
      dark: '#A9B7C5',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#5C7AA2',
      main: '#254D83',
      dark: '#A9B7C5',
      contrastText: '#fff',
    },
    background: {
      default: '#F1F4FA',
      paper: '#FFFFFF',
      paper2: '#F1F4FA',
      paper3: '#FFFFFF',
      brush: '#9FAFC480',
      disabled: '#DEDEDE',
    },
    text: {
      primary: '#060607',
      secondary: '#9FAFC4',
      contrastText: '#F1F4FA',
      disabled: '#979797',
      legend: '#6B7A8C',
    },
    action: {
      disabled: '#979797',
      disabledBackground: '#DEDEDE',
    },
    customShadow: {
      popover: '0 4px 8px #00000040',
      modal: '0 8px 16px #00000040',
      navigation: '0 0 8px #00000040',
    },
    divider: '#9FAFC440',
  },
  dark: {
    primary: {
      light: '#C285F3',
      main: '#AD5AED',
      dark: '#D3D3D3',
      contrastText: '#fff',
    },
    secondary: {
      light: '#61C3EF',
      main: '#00AFEA',
      dark: '#D3D3D3',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#D5A991',
      main: '#C68C6A',
      dark: '#D3D3D3',
      contrastText: '#fff',
    },
    background: {
      default: '#060607',
      paper: '#06172E',
      paper2: '#1B2A43',
      paper3: '#2A3651',
      brush: '#6B7A8C80',
      disabled: '#818181',
    },
    text: {
      primary: '#F1F4FA',
      secondary: '#6B7A8C',
      contrastText: '#060607',
      disabled: '#818181',
      legend: '#6B7A8C',
    },
    action: {
      disabled: '#818181',
      disabledBackground: '#3C3C3C',
    },
    customShadow: {
      popover: '0 4px 8px #00000080',
      modal: '0 8px 16px #00000080',
      navigation: '2px 0 12px #00000080',
    },
    divider: '#6B7A8C40',
  },
}
