import React from 'react'
import { useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import { TooltipProps } from 'recharts'

import { Kpi } from 'common/constants-metrics'
import { getKpiValueAndSign } from 'common/helpers'

import { Box, Typography, useTheme } from '@mui/material'

interface CustomTrendTooltipProps extends TooltipProps<number, string> {
  isDimensionYear: boolean
  isLineChart?: boolean
  isBarChart?: boolean
  isAreaChart?: boolean
  kpi: Kpi
  currency: string
  selectLines: { [key: string]: boolean }
  pastColor: string
  currentColor: string
  forecastColor?: string
}

const CustomTrendTooltip: React.FC<CustomTrendTooltipProps> = ({
  active,
  payload,
  isDimensionYear,
  isLineChart = false,
  isBarChart = false,
  isAreaChart = false,
  kpi,
  currency,
  selectLines,
  pastColor,
  currentColor,
  forecastColor,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]
  const locale = currentlanguage === 'de' ? de : enUS

  const pastDateLabel = payload?.find((item) => item.payload)?.payload.date_past
  const currentDateLabel = payload?.find((item) => item.payload)?.payload
    .date_current
  const forecastDateLabel = payload?.find((item) => item.payload)?.payload
    .date_forecast

  const getKpiValue = (item: any, key: string): boolean => {
    const formattedKey = `${kpi.id}_${key}`
    if (isLineChart) {
      return item === formattedKey
    }
    if (isBarChart) {
      return item === `${formattedKey}_monthly_total`
    }
    if (isAreaChart) {
      return item === `${formattedKey}_accumulated`
    }
    return false
  }

  const pastValue = payload?.find((item) =>
    getKpiValue(item.dataKey, 'past')
  )?.value

  const currentValue = payload?.find((item) =>
    getKpiValue(item.dataKey, 'current')
  )?.value

  const forecastValue = payload?.find((item) =>
    getKpiValue(item.dataKey, 'forecast')
  )?.value

  const isPast = selectLines.isPast ? selectLines.past : false
  const isCurrent = selectLines.isCurrent ? selectLines.current : false
  const isForecast = selectLines.forecast ? selectLines.forecast : false

  const isAnyKpiAvailable =
    (isPast && !!pastDateLabel) ||
    (isCurrent && !!currentDateLabel) ||
    (isForecast && !!forecastDateLabel)

  const isTooltipAvailable = active && payload && payload.length

  const kpiData = [
    {
      key: 'past',
      dateLabel: pastDateLabel,
      kpiValue: pastValue,
      isAvailable: isPast,
      color: pastColor,
    },
    {
      key: 'current',
      dateLabel: currentDateLabel,
      kpiValue: currentValue,
      isAvailable: isCurrent,
      color: currentColor,
    },
    {
      key: 'forecast',
      dateLabel: forecastDateLabel,
      kpiValue: forecastValue,
      isAvailable: isForecast,
      color: forecastColor,
    },
  ] as const

  if (isTooltipAvailable) {
    return (
      <Box
        sx={{
          width: '260px',
          p: 2,
          borderRadius: 2,
          bgcolor: theme.palette.background.paper3,
          boxShadow: theme.palette.customShadow.popover,
        }}
      >
        <Typography fontWeight={900}>
          {t(`${kpi.title}`, { ns: 'kpi' })}
        </Typography>
        {isAnyKpiAvailable && (
          <Box display="flex" flexDirection="column" mt={1}>
            {kpiData.map(
              ({ key, dateLabel, kpiValue, isAvailable, color }) =>
                isAvailable &&
                !!dateLabel && (
                  <Box key={key} display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box minWidth="16px" height="4px" bgcolor={color} />
                      <Typography variant="body2">
                        {format(
                          parseISO(dateLabel),
                          isDimensionYear ? `MMM yyyy` : `dd MMM yyyy`,
                          {
                            locale,
                          }
                        )}
                        {key === 'forecast' &&
                          ` (${t('FORECAST_SHORT', { ns: 'common' })})`}
                        :
                      </Typography>
                    </Box>
                    <Typography fontWeight={700}>
                      {getKpiValueAndSign(
                        kpiValue,
                        kpi.format,
                        currentlanguage,
                        currency
                      )}
                    </Typography>
                  </Box>
                )
            )}
          </Box>
        )}
      </Box>
    )
  }

  return null
}

export default CustomTrendTooltip
