import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'

import { store } from 'store/store'

import './localization/index'
import App from 'app'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  auth: process.env.REACT_APP_GTM_AUTH || '',
  preview: process.env.REACT_APP_GTM_PREVIEW || '',
}

TagManager.initialize(tagManagerArgs)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
