import { useTranslation } from 'react-i18next'

import { Box, Typography, useTheme } from '@mui/material'
import LegalLinks from './legal-links'

const Footer = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: 'space-between',
        marginTop: 'auto',
        gap: 1,
        padding: { xs: 3, md: 0 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: `${theme.spacing(1)} ${theme.spacing(3)}`,
        }}
      >
        <LegalLinks />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="body2" color={theme.palette.text.legend}>
          © {new Date().getFullYear()}&nbsp;{t('COPY_RIGHT', { ns: 'common' })}
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
