import { useTranslation } from 'react-i18next'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Menu, Typography, useTheme } from '@mui/material'

import { DateDropdownSelectWrapper } from './styles'

const DateCompareDropdown = ({
  infoDetails,
  presetList,
  presetSelect,
  handlePresetSelect,
  isOpenPresetDropdown,
  openPresetDropdown,
  closePresetDropdown,
  anchorEl,
}: {
  infoDetails: { title: string; text: string }
  presetList: string[]
  presetSelect: string
  closePresetDropdown: any
  handlePresetSelect: any
  isOpenPresetDropdown: boolean
  openPresetDropdown: any
  anchorEl: null | HTMLElement
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1.5 }}
    >
      <Typography
        variant="caption"
        sx={{
          wordSpacing: '0.1em',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {t(infoDetails.title, {
          ns: 'filter',
        })}
      </Typography>
      <DateDropdownSelectWrapper onClick={openPresetDropdown}>
        {t(`${presetSelect}`, {
          ns: 'dateRange',
        })}
        {isOpenPresetDropdown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </DateDropdownSelectWrapper>
      <Menu
        id="date-range-menu"
        anchorEl={anchorEl}
        open={isOpenPresetDropdown}
        onClose={closePresetDropdown}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: '300px',
              maxHeight: '284px',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              mt: '6px',
              px: 1.5,
              borderRadius: 4,
              boxShadow: theme.palette.customShadow.popover,
              bgcolor: theme.palette.background.paper2,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {presetList.map((presetItem) => (
          <Box
            key={presetItem}
            onClick={() => handlePresetSelect(presetItem)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              py: 1,
              px: 2,
              my: 0.5,
              borderRadius: 2,
              cursor: 'pointer',
              color:
                presetItem === presetSelect
                  ? theme.palette.primary.main
                  : 'inherit',
              ':hover': {
                bgcolor: theme.palette.background.paper3,
              },
            }}
          >
            <Typography variant="body1">
              {t(`${presetItem}`, {
                ns: 'dateRange',
              })}
            </Typography>
          </Box>
        ))}
      </Menu>
    </Box>
  )
}

export default DateCompareDropdown
