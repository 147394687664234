import { createShouldForwardProp } from 'common/helpers'
import { DRAWER, HEADER, SIDEBAR, Z_INDEX_SIDEBAR } from 'common/constants'

import { Box, BoxProps, styled } from '@mui/material'

interface CustomSidebarProps extends BoxProps {
  isSidebarFixed: boolean
}
interface CustomSidebarDrawerProps extends BoxProps {
  isOpenSidebarDrawer: boolean
}

interface SidebarLogoWrapperProps extends BoxProps {
  isOpenSidebarDrawer: boolean
}

const CustomSidebarForwardProps = createShouldForwardProp(['isSidebarFixed'])
const CustomSidebarDrawerForwardProps = createShouldForwardProp([
  'isOpenSidebarDrawer',
])
const SidebarLogoWrapperForwardProps = createShouldForwardProp([
  'isOpenSidebarDrawer',
])

export const CustomSidebar = styled(Box, {
  shouldForwardProp: CustomSidebarForwardProps,
})<CustomSidebarProps>(({ isSidebarFixed }) => ({
  position: 'relative',
  minWidth: `${isSidebarFixed ? SIDEBAR.WIDTH_DESKTOP_OPEN : SIDEBAR.WIDTH_DESKTOP_MINI}px`,
}))

export const CustomSidebarDrawer = styled(Box, {
  shouldForwardProp: CustomSidebarDrawerForwardProps,
})<CustomSidebarDrawerProps>(({ theme, isOpenSidebarDrawer }) => ({
  position: 'absolute',
  height: '100%',
  width: `${isOpenSidebarDrawer ? SIDEBAR.WIDTH_DESKTOP_OPEN : SIDEBAR.WIDTH_DESKTOP_MINI}px`,
  zIndex: Z_INDEX_SIDEBAR,
  transition: 'width 0.3s',

  '&::before': {
    content: "''",
    position: 'absolute',
    top: `${HEADER.HEIGHT_DESKTOP_OFFSET}px`,
    right: `-${SIDEBAR.WIDTH_EXPAND_BUTTON / 2}px`,
    width: `${SIDEBAR.WIDTH_EXPAND_BUTTON}px`,
    height: `${SIDEBAR.HEIGHT_EXPAND_BUTTON}px`,
    borderRadius: '50%',
    boxShadow: theme.palette.customShadow.navigation,
  },
}))

export const SidebarDrawerWrapper = styled(Box)(() => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
}))

export const SidebarDrawerContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(6)} 0 ${theme.spacing(2)}`,
  overflowX: 'hidden',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.palette.customShadow.navigation,
}))

export const CustomSidebarExpandButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: `${HEADER.HEIGHT_DESKTOP_OFFSET}px`,
  right: `-${SIDEBAR.WIDTH_EXPAND_BUTTON / 2}px`,
  width: `${SIDEBAR.WIDTH_EXPAND_BUTTON}px`,
  height: `${SIDEBAR.HEIGHT_EXPAND_BUTTON}px`,
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  zIndex: Z_INDEX_SIDEBAR,
  color: theme.palette.text.legend,
  backgroundColor: theme.palette.background.paper,

  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

export const SidebarLogoWrapper = styled(Box, {
  shouldForwardProp: SidebarLogoWrapperForwardProps,
})<SidebarLogoWrapperProps>(({ theme, isOpenSidebarDrawer }) => ({
  position: 'relative',
  minHeight: `${DRAWER.HEIGHT_LOGO}px`,
  maxHeight: `${DRAWER.HEIGHT_LOGO}px`,
  display: 'flex',
  justifyContent: isOpenSidebarDrawer ? 'flex-start' : 'center',
  alignItems: 'center',
  padding: `0 ${theme.spacing(2)}`,
  overflow: 'hidden',
}))
