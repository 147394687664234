import {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import BasicFilter from './basic-filter'
import AdvancedFilter from './advanced-filter'

import { Box, ToggleButtonGroup, Typography } from '@mui/material'

import { CustomToggleButton } from './styles'

interface FilterRow {
  [key: string]: {
    id: string
    filterType: string
    filterValue: string
  }
}

interface ConditionSet {
  id: string
  or: FilterRow[]
}

const FilterSettingsPanel = ({
  dimensionId,
  temporaryFilter,
  dimensionToReset,
  handleTemporaryFilter,
  handleDimensionToReset,
  dimensionItems,
}: {
  dimensionId: string
  temporaryFilter: any
  dimensionToReset: string
  handleTemporaryFilter: any
  handleDimensionToReset: any
  dimensionItems: any
}) => {
  const { t } = useTranslation()

  const dimensionItemsList = dimensionItems.map(
    (item: any) => item[dimensionId]
  )

  const sortedDimensionItemsList = useMemo(() => {
    return [...dimensionItemsList]
      .filter((item) => item !== null)
      .sort((a, b) => a.localeCompare(b))
  }, [dimensionItemsList])

  const advancedFilteredDimensions = Object.entries(
    temporaryFilter.advanced
  ).filter(([_, filters]) =>
    (filters as ConditionSet[]).some((filterGroup) =>
      filterGroup.or.some((filterObj) =>
        Object.values(filterObj as FilterRow).some(
          (filter) => filter.filterValue.trim() !== ''
        )
      )
    )
  )

  const advancedFilteredDimensionsList = advancedFilteredDimensions.map(
    ([dimension]) => dimension
  )

  const filterModes = [
    { value: 'basic', label: 'BASIC_FILTER' },
    { value: 'advanced', label: 'ADVANCED_FILTER' },
  ]
  const [filterMode, setFilterMode] = useState<'basic' | 'advanced'>(
    advancedFilteredDimensionsList.includes(dimensionId) ? 'advanced' : 'basic'
  )

  const [conditionSets, setConditionSets] = useState<ConditionSet[]>(
    !temporaryFilter.advanced[dimensionId]
      ? [
          {
            id: `${Date.now()}-${Math.random()}`,
            or: [],
          },
        ]
      : temporaryFilter.advanced[dimensionId]
  )

  const includeSelection = temporaryFilter.basic[dimensionId]?.include || []
  const excludeSelection =
    sortedDimensionItemsList?.length >
    temporaryFilter.basic[dimensionId]?.exclude?.length
      ? sortedDimensionItemsList.filter(
          (item) => !includeSelection.includes(item)
        )
      : temporaryFilter.basic[dimensionId]?.exclude || sortedDimensionItemsList

  const [selection, setSelection] = useState<{
    include: string[]
    exclude: string[]
  }>({ include: includeSelection, exclude: excludeSelection })

  const [searchQuery, setSearchQuery] = useState('')

  const filteredItemsBySearch = useMemo(() => {
    return sortedDimensionItemsList.filter((item) =>
      item?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
  }, [sortedDimensionItemsList, searchQuery])

  const selectedItems = filteredItemsBySearch.filter((item) =>
    selection.include.includes(item)
  )

  const isBasicFilterValue = selection.include.length > 0

  const isAdvancedFilterValue = conditionSets.some((set) =>
    set.or.some((condition) => condition[dimensionId]?.filterValue !== '')
  )

  useLayoutEffect(() => {
    setSearchQuery('')
    if (filterMode === 'basic') {
      setSelection({ include: includeSelection, exclude: excludeSelection })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMode])

  useLayoutEffect(() => {
    if (dimensionToReset === dimensionId) {
      handleDimensionToReset('')
      handleDeselectAll()
      setConditionSets([
        {
          id: `${Date.now()}-${Math.random()}`,
          or: [],
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionToReset])

  useEffect(() => {
    const latestBasicFilter = updateBasicFilter()

    handleTemporaryFilter({
      ...temporaryFilter,
      basic: {
        ...temporaryFilter.basic,
        [dimensionId]: latestBasicFilter,
      },
      advanced: isBasicFilterValue
        ? {
            ...temporaryFilter.advanced,
            [dimensionId]: [],
          }
        : temporaryFilter.advanced,
    })

    isBasicFilterValue &&
      setConditionSets([
        {
          id: `${Date.now()}-${Math.random()}`,
          or: [],
        },
      ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection])

  useEffect(() => {
    const resetBasicFilter = {
      filterType: 'excl',
      filterValue: [],
      include: [],
      exclude: [],
    }

    handleTemporaryFilter({
      ...temporaryFilter,
      basic: isAdvancedFilterValue
        ? {
            ...temporaryFilter.basic,
            [dimensionId]: resetBasicFilter,
          }
        : temporaryFilter.basic,
      advanced: {
        ...temporaryFilter.advanced,
        [dimensionId]: conditionSets,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionSets])

  const handleFilterMode = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    value: any
  ) => {
    if (value) {
      setFilterMode(value)
    }
  }

  const handleSelect = (item: string) => {
    setSelection((prevSelection) => {
      if (prevSelection.include.includes(item)) {
        return {
          include: prevSelection.include.filter((i) => i !== item),
          exclude: [...prevSelection.exclude, item],
        }
      }
      return {
        include: [...prevSelection.include, item],
        exclude: prevSelection.exclude.filter((i) => i !== item),
      }
    })
  }

  const handleSelectAll = () => {
    !searchQuery
      ? setSelection({
          include: sortedDimensionItemsList,
          exclude: [],
        })
      : setSelection((prev) => ({
          include: [
            ...prev.include,
            ...filteredItemsBySearch.filter(
              (item) => !prev.include.includes(item)
            ),
          ],
          exclude: [
            ...prev.exclude.filter(
              (item) => !filteredItemsBySearch.includes(item)
            ),
          ],
        }))
  }

  const handleDeselectAll = () => {
    !searchQuery
      ? setSelection({
          include: [],
          exclude: sortedDimensionItemsList,
        })
      : setSelection((prev) => ({
          include: [
            ...prev.include.filter(
              (item) => !filteredItemsBySearch.includes(item)
            ),
          ],
          exclude: [
            ...prev.exclude,
            ...filteredItemsBySearch.filter(
              (item) => !prev.exclude.includes(item)
            ),
          ],
        }))
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const updateBasicFilter = () => {
    const { include, exclude } = selection

    if (!include.length) {
      return {
        filterType: 'excl',
        filterValue: [],
        include,
        exclude,
      }
    }

    if (include.length < exclude.length) {
      return {
        filterType: 'incl',
        filterValue: [...include],
        include,
        exclude,
      }
    }

    return {
      filterType: 'excl',
      filterValue: [...exclude],
      include,
      exclude,
    }
  }

  const handleAddSet = () => {
    const newSet = {
      id: `${Date.now()}-${Math.random()}`,
      or: [],
    }
    setConditionSets([...conditionSets, newSet])
  }

  const handleRemoveSet = (id: string) => {
    setConditionSets(conditionSets.filter((set) => set.id !== id))
  }

  const handleUpdateSet = (setId: string, data: []) => {
    setConditionSets(
      conditionSets.map((set) =>
        set.id === setId ? { ...set, or: data } : set
      )
    )
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            wordSpacing: '0.1em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {t('TYPE_OF_FILTER', {
            ns: 'filter',
          })}
        </Typography>
        <ToggleButtonGroup
          exclusive
          value={filterMode}
          onChange={handleFilterMode}
          sx={{
            bgcolor: 'background.paper2',
            borderRadius: 4,
            p: 1,
            gap: 1,
            '& .MuiToggleButton-root': {
              textTransform: 'none',
              borderRadius: 3,
            },
          }}
        >
          {filterModes.map((mode) => (
            <CustomToggleButton key={mode.value} value={mode.value}>
              <Typography
                variant="body1"
                textTransform="initial"
                fontWeight={mode.value === filterMode ? 'bold' : 'inherit'}
              >
                {t(`${mode.label}`, {
                  ns: 'filter',
                })}
              </Typography>
            </CustomToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>

      {filterMode === 'basic' ? (
        <BasicFilter
          selectedItems={selectedItems}
          searchQuery={searchQuery}
          filteredItemsBySearch={filteredItemsBySearch}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          handleSearch={handleSearch}
        />
      ) : (
        <AdvancedFilter
          dimensionId={dimensionId}
          conditionSets={conditionSets}
          handleAddSet={handleAddSet}
          handleRemoveSet={handleRemoveSet}
          handleUpdateSet={handleUpdateSet}
        />
      )}
    </Box>
  )
}

export default FilterSettingsPanel
