import React, { useEffect } from 'react'

import { useAppDispatch } from 'store/redux-hooks'
import { setModal } from 'store/features/modal-slice'

import { SidebarDesktop } from './sidebar-desktop'
import { SidebarMobile } from './sidebar-mobile'

import { useMediaQuery, useTheme } from '@mui/material'

const Sidebar = () => {
  const theme = useTheme()
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))

  const dispatch = useAppDispatch()

  const handleSidebarDrawerMobile = () => {
    dispatch(setModal(''))
  }

  useEffect(() => {
    handleSidebarDrawerMobile()
    // eslint-disable-next-line
  }, [isWideScreen])

  return isWideScreen ? <SidebarDesktop /> : <SidebarMobile />
}

export default Sidebar
