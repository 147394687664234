import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import ChangeMode from 'components/user-preferences/change-mode'

import { Box, Typography, useTheme } from '@mui/material'
import ChangeLanguage from 'components/user-preferences/change-language'

const Profile = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    value: { userInfo },
  } = useAppSelector(selectAuth)

  const renderInfoRow = (label: string, value: string) => (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: { xs: 1, md: 4 },
      }}
    >
      <Typography
        sx={{
          minWidth: {
            xs: `${label === 'E_MAIL' ? 0 : '80px'}`,
            md: '80px',
          },
        }}
      >
        {t(label, { ns: 'common' })}
      </Typography>
      <Box
        sx={{
          flex: 1,
          backgroundColor: 'background.paper2',
          borderRadius: 4,
          p: 2,
        }}
      >
        <Typography>{value}</Typography>
      </Box>
    </Box>
  )

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          py: 2,
          px: 3,
          gap: 0.5,
          backgroundColor: 'background.paper',
          borderRadius: 3,
          my: 2,
          mx: { xs: 2, md: 0 },
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
        >
          Hi {userInfo?.firstName} {userInfo?.lastName}!
        </Typography>
        <Typography>
          {t('WELCOME_TO_ORBIT_HOME_PAGE', { ns: 'common' })}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: 2,
          mx: { xs: 2, md: 0 },
        }}
      >
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'background.paper',
            borderRadius: 3,
            p: 3,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {t('PERSONAL_INFORMATION', { ns: 'common' })}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
            {renderInfoRow('FIRST_NAME', userInfo?.firstName)}
            {renderInfoRow('LAST_NAME', userInfo?.lastName)}
            {renderInfoRow('E_MAIL', userInfo?.email)}
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            backgroundColor: 'background.paper',
            borderRadius: 3,
            p: 3,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {t('PREFERENCES', { ns: 'common' })}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: { xs: 2, md: 4 },
              }}
            >
              <Typography sx={{ minWidth: '80px' }}>
                {t('LANGUAGE', { ns: 'common' })}
              </Typography>
              <ChangeLanguage />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: { xs: 2, md: 4 },
              }}
            >
              <Typography sx={{ minWidth: '80px' }}>
                {t('THEME', { ns: 'common' })}
              </Typography>
              <ChangeMode />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Profile
