import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/redux-hooks'
import { selectTheme } from 'store/features/theme-slice'
import { selectAuth } from 'store/features/auth-slice'

import useSectionAccess from 'hooks/useSectionAccess'

import { Section } from 'common/constants-navigation'
import { PackageType } from 'common/constants'

import PackagePopover from './nav-mobile/package-popover'

import { ExpandLess, ExpandMore, SvgIconComponent } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { NavSectionWrapper } from './styles'

const NavSection = ({
  sectionConfig,
  navigationConfig,
  handleNavigation,
}: {
  sectionConfig: Section
  navigationConfig: any
  handleNavigation: any
}) => {
  const { t } = useTranslation()

  const { isGlobalView } = useAppSelector(selectTheme)
  const {
    value: { currentCustomer },
  } = useAppSelector(selectAuth)

  const userPackage = 'performance' as PackageType

  const customerReportTypes = currentCustomer ? currentCustomer.reportTypes : []

  const { isSectionActive } = useSectionAccess(
    userPackage,
    isGlobalView,
    customerReportTypes
  )

  const renderIcon = (
    isSelected: boolean,
    Icon: SvgIconComponent,
    SelectIcon?: SvgIconComponent
  ) => {
    return isSelected && SelectIcon ? <SelectIcon /> : <Icon />
  }

  return (
    <NavSectionWrapper
      key={sectionConfig.address}
      isSelect={navigationConfig.selectSectionAddress === sectionConfig.address}
      isAccessible={isSectionActive(sectionConfig.address)}
      onClick={() =>
        handleNavigation({
          action: 'expand',
          isSubsections: !!sectionConfig.subsections,
          expandSectionAddress: sectionConfig.address,
          selectSectionAddress: sectionConfig.address,
          selectSubsectionAddress: '',
          selectKpiAddress: sectionConfig.address,
        })
      }
    >
      {renderIcon(
        navigationConfig.selectSectionAddress === sectionConfig.address,
        sectionConfig.icon,
        sectionConfig.selectIcon
      )}
      <Typography
        variant="body1"
        sx={{
          fontWeight:
            navigationConfig.selectSectionAddress === sectionConfig.address
              ? 700
              : 'inherit',
        }}
      >
        {t(`${sectionConfig.title}`, { ns: 'navigation' })}
      </Typography>
      {!!sectionConfig.subsections &&
        isSectionActive(sectionConfig.address) && (
          <Box sx={{ display: 'flex', ml: 'auto', color: 'text.primary' }}>
            {sectionConfig.address === navigationConfig.expandSectionAddress ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </Box>
        )}
      {!isSectionActive(sectionConfig.address) && (
        <Box sx={{ position: 'relative', display: 'flex', ml: 'auto' }}>
          <PackagePopover />
        </Box>
      )}
    </NavSectionWrapper>
  )
}

export default NavSection
