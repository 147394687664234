import { useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectTheme, setIsSidebarFixed } from 'store/features/theme-slice'

import { DRAWER, MODE } from 'common/constants'
import { HOME } from 'common/constants-navigation'
import { getIsRememberMe, getPersistedItem, persistItem } from 'common/helpers'

import LogoLargeLight from 'assets/logo-large-light.png'
import LogoLargeDark from 'assets/logo-large-dark.png'
import LogoSmallLight from 'assets/logo-small-light.png'

import SidebarExpandButton from './sidebar-expand-button'
import { Navigation } from 'layout/navigation'

import { useMediaQuery, useTheme } from '@mui/material'

import {
  CustomSidebarDrawer,
  CustomSidebar,
  SidebarDrawerContainer,
  SidebarDrawerWrapper,
  SidebarLogoWrapper,
} from './styles'

const SideBarDesktop = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const { isSidebarFixed } = useAppSelector(selectTheme)
  const persistedTheme = getPersistedItem('theme')
  const persistedIsSidebarFixed = persistedTheme?.isSidebarFixed
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const [isOpenSidebarDrawer, setIsOpenSidebarDrawer] = useState<boolean>(false)

  useLayoutEffect(() => {
    handleInitialSidebarState()
    // eslint-disable-next-line
  }, [isExtraLargeScreen])

  const toggleSidebarDrawer = (isOpen: boolean) => {
    isOpen ? setIsOpenSidebarDrawer(true) : setIsOpenSidebarDrawer(false)
  }

  const toggleSidebarFixed = () => {
    dispatch(setIsSidebarFixed(!isSidebarFixed))
  }

  const handleSidebarOnHover = (isMouseEnter: boolean) => {
    if (!isSidebarFixed) {
      toggleSidebarDrawer(isMouseEnter)
    }
  }

  const handleSidebarOnClick = () => {
    const isRememberMe = getIsRememberMe()
    toggleSidebarFixed()
    toggleSidebarDrawer(!isSidebarFixed)
    persistItem(
      'theme',
      {
        ...persistedTheme,
        isSidebarFixed: !isSidebarFixed,
      },
      isRememberMe
    )
  }

  const handleInitialSidebarState = () => {
    if (persistedIsSidebarFixed || isExtraLargeScreen) {
      dispatch(setIsSidebarFixed(true))
      setIsOpenSidebarDrawer(true)
    }
    if (!isExtraLargeScreen) {
      dispatch(setIsSidebarFixed(persistedIsSidebarFixed))
      setIsOpenSidebarDrawer(persistedIsSidebarFixed)
    }
  }

  return (
    <CustomSidebar isSidebarFixed={isSidebarFixed}>
      <CustomSidebarDrawer isOpenSidebarDrawer={isOpenSidebarDrawer}>
        <SidebarDrawerWrapper>
          <SidebarExpandButton
            isSidebarFixed={isSidebarFixed}
            isOpenSidebarDrawer={isOpenSidebarDrawer}
            handleSidebarOnHover={handleSidebarOnHover}
            handleSidebarFixed={handleSidebarOnClick}
          />
          <SidebarDrawerContainer
            onMouseEnter={() => handleSidebarOnHover(true)}
            onMouseLeave={() => handleSidebarOnHover(false)}
          >
            <Link to={HOME.address}>
              <SidebarLogoWrapper isOpenSidebarDrawer={isOpenSidebarDrawer}>
                <img
                  src={
                    theme.palette.mode === MODE.light
                      ? LogoLargeLight
                      : LogoLargeDark
                  }
                  alt="company logo"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 16,
                    width: 'auto',
                    height: `${DRAWER.HEIGHT_LOGO}px`,
                    opacity: !isOpenSidebarDrawer ? 0 : 1,
                  }}
                />
                <img
                  src={LogoSmallLight}
                  alt="company logo"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 16,
                    width: 'auto',
                    height: `${DRAWER.HEIGHT_LOGO}px`,
                    opacity: !isOpenSidebarDrawer ? 1 : 0,
                  }}
                />
              </SidebarLogoWrapper>
            </Link>
            <Navigation isOpenSidebarDrawer={isOpenSidebarDrawer} />
          </SidebarDrawerContainer>
        </SidebarDrawerWrapper>
      </CustomSidebarDrawer>
    </CustomSidebar>
  )
}
export default SideBarDesktop
