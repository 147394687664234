import { MouseEvent, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { de, enUS } from 'date-fns/locale'
import {
  endOfMonth,
  endOfYear,
  format,
  getYear,
  isAfter,
  isSameMonth,
  startOfMonth,
  subDays,
  subYears,
} from 'date-fns'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectFilter } from 'store/features/filter-slice'
import { selectAuth } from 'store/features/auth-slice'
import {
  fetchLineChartDetails,
  selectChartDetails,
} from 'store/features/chart-details-slice'

import { STATUS } from 'common/constants'
import {
  calculateAverageChange,
  checkFirstAndLastDayOfMonth,
  checkFirstAndLastDayOfYear,
  checkIsSelectedDateCurrentMonth,
  checkIsSelectedDateCurrentYear,
  createForecastData,
  extractByOneDateRange,
  extractCompareAndSelectedMonths,
  extractLastDayOfMonth,
  filterDataByCurrentDate,
  filterDataByDate,
  generaetDateRange,
  generateKPICardData,
  mergeData,
  transformData,
  transformLastMonthKeys,
  updateSecondRowKpiCardData,
} from 'common/helpers-trend-page'

import {
  TrendChartSections,
  TrendChartSectionsNoData,
} from 'components/trend-chart-sections'
import { TrendCard } from 'components/trend-card'
import { TrendFilterSection } from 'components/trend-filter-section'
import { TrendDimensionSection } from 'components/trend-dimension-section'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/material'

type KPIData = { date: string; [key: string]: any }
type KPIAverageChange = { [key: string]: number }
interface KPICard {
  id: string
  details: any
  color: string
  pastColor: string
  currentColor: string
  forecastColor: string
}
interface Filter {
  filterType: string
  filterValue: string[]
  [key: string]: any
}

interface FilterRow {
  [key: string]: {
    id: string
    filterType: string
    filterValue: string
  }
}

interface ConditionSet {
  id: string
  or: FilterRow[]
}

const TrendPage = ({
  kpiPath: { kpi1, kpi2, kpi3 },
  reportType,
  sourceName,
}: any) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]
  const locale = currentlanguage === 'de' ? de : enUS

  const {
    value: { accessToken, currentCustomer },
  } = useAppSelector(selectAuth)

  const {
    basic,
    advanced,
    dateTrendPage: {
      selectedMonthStartDate,
      selectedMonthEndDate,
      compareMonthStartDate,
      compareYearStartDate,
    },
  } = useAppSelector(selectFilter)

  const {
    value: { lineChart },
    lineChartStatus,
  } = useAppSelector(selectChartDetails)

  const [dimensionFilter, setDimensionFilter] = useState<'YEAR' | 'MONTH'>(
    'YEAR'
  )
  const isDimensionYear = dimensionFilter === 'YEAR'

  const isSelectedDateCurrentYear = checkIsSelectedDateCurrentYear(
    selectedMonthStartDate
  )
  const isSelectedDateCurrentMonth = checkIsSelectedDateCurrentMonth(
    selectedMonthStartDate
  )

  useEffect(() => {
    if (accessToken) {
      handleChartDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCustomer,
    accessToken,
    kpi1,
    kpi2,
    kpi3,
    selectedMonthStartDate,
    compareMonthStartDate,
    basic,
    advanced,
  ])

  const today = new Date()
  const yesterday = subDays(today, 1)

  const selectedDate =
    isSelectedDateCurrentYear && isSelectedDateCurrentMonth
      ? yesterday
      : selectedMonthEndDate

  const selectYearEndDate = format(
    endOfYear(selectedMonthEndDate),
    'yyyy-MM-dd'
  )

  const basicFilteredDimensions = Object.fromEntries(
    Object.entries(basic).filter(
      ([_, dimensionvalue]: [dimensionName: any, dimensionvalue: any]) =>
        dimensionvalue.include.length !== 0
    )
  )

  const basicFilter = Object.fromEntries(
    Object.entries(basicFilteredDimensions as Filter).map(([key, filter]) => [
      key,
      {
        filterType: filter.filterType,
        filterValue: filter.filterValue,
      },
    ])
  )

  const flatTemporaryFilterAdvanced = Object.values(advanced).flatMap(
    (entryArray) => entryArray
  )

  const advancedFilter = (flatTemporaryFilterAdvanced as ConditionSet[]).filter(
    (item) =>
      item.or.some((subItem) => {
        const filterValue = Object.values(subItem)[0]?.filterValue
        return filterValue && filterValue.length > 0
      })
  )

  const payload = {
    customerId: currentCustomer?.customerId,
    source: {
      name: sourceName,
      dimension: ['date'],
      metric: [kpi1.id, kpi2.id, kpi3.id],
    },
    filter: {
      basic: {
        report_type: {
          filterType: 'incl',
          filterValue: [reportType],
        },
        ...basicFilter,
      },
      advanced: advancedFilter,
    },
    dateRange: {
      start: compareYearStartDate,
      end: selectYearEndDate,
      aggregation: 'day',
    },
  }

  const handleChartDetails = () => {
    dispatch(fetchLineChartDetails({ payload }))
  }

  const apiChartData = lineChart?.data || [{ date: '' }]

  const chartDataSortedByDate = [...apiChartData].sort(
    (a: { date: string }, b: { date: string }) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime()
    }
  )

  const dataExtractedByTwoYearRange = extractByOneDateRange(
    chartDataSortedByDate,
    compareYearStartDate,
    selectYearEndDate
  )

  const dataByAllDatePoint = generaetDateRange(
    compareYearStartDate,
    selectYearEndDate,
    dataExtractedByTwoYearRange,
    kpi1,
    kpi2,
    kpi3
  )

  const areaChartRawData: KPIData[] = dataByAllDatePoint

  const barChartRawData: KPIData[] = extractByOneDateRange(
    dataByAllDatePoint,
    compareYearStartDate,
    selectedMonthEndDate
  )

  const lineChartRawData: KPIData[] = extractCompareAndSelectedMonths(
    dataByAllDatePoint,
    selectedMonthStartDate,
    compareMonthStartDate
  )

  const firstRowCardRawData = filterDataByDate(dataByAllDatePoint, selectedDate)

  const { pastData: pastDataAreaChart, currentData: currentDataAreaChart } =
    transformData(
      areaChartRawData,
      kpi1,
      kpi2,
      kpi3,
      selectedMonthStartDate,
      compareMonthStartDate,
      isDimensionYear
    )

  const { pastData: pastDataBarChart, currentData: currentDataBarChart } =
    transformData(
      barChartRawData,
      kpi1,
      kpi2,
      kpi3,
      selectedMonthStartDate,
      compareMonthStartDate,
      isDimensionYear
    )

  const { pastData: pastDataLineChart, currentData: currentDataLineChart } =
    transformData(
      lineChartRawData,
      kpi1,
      kpi2,
      kpi3,
      selectedMonthStartDate,
      compareMonthStartDate,
      isDimensionYear
    )

  const {
    pastData: pastDataFirstRowCard,
    currentData: currentDataFirstRowCard,
  } = transformData(
    firstRowCardRawData,
    kpi1,
    kpi2,
    kpi3,
    selectedMonthStartDate,
    compareMonthStartDate,
    isDimensionYear
  )

  const currentData = isDimensionYear
    ? currentDataAreaChart
    : currentDataLineChart

  const averageCurrentDataChanges: KPIAverageChange =
    calculateAverageChange(currentData)

  const currentDataWithUpdatedKeys =
    isDimensionYear && isSelectedDateCurrentYear
      ? transformLastMonthKeys(currentData)
      : currentData

  const isCurrentDataFullYear = checkFirstAndLastDayOfYear(
    currentDataWithUpdatedKeys
  )

  const isCurrentDataFullMonth = checkFirstAndLastDayOfMonth(currentData)

  const currentDataEndDate =
    currentData[currentData.length - 1]?.date_current || ''

  const isSelectedDateCurrent = isDimensionYear
    ? isSelectedDateCurrentYear
    : isSelectedDateCurrentMonth

  const isCurrentDataFull = isDimensionYear
    ? isCurrentDataFullYear
    : isCurrentDataFullMonth

  const forecastData =
    isSelectedDateCurrent && !isCurrentDataFull
      ? createForecastData(
          kpi1,
          kpi2,
          kpi3,
          currentDataEndDate,
          averageCurrentDataChanges,
          isDimensionYear
        )
      : []

  const areaChartCompleteData = [
    ...pastDataAreaChart,
    ...currentDataWithUpdatedKeys,
    ...forecastData,
  ]

  const barChartCompleteData = [...pastDataBarChart, ...currentDataBarChart]

  const lineChartCompleteData = [
    ...pastDataLineChart,
    ...currentDataWithUpdatedKeys,
    ...forecastData,
  ]

  const firstRowCardCompleteData = [
    ...pastDataFirstRowCard,
    ...currentDataFirstRowCard,
  ]

  const areaChartDataTransformedByMonth = extractLastDayOfMonth(
    areaChartCompleteData
  )

  const barChartDataTransformedByMonth =
    extractLastDayOfMonth(barChartCompleteData)

  const firstRowCardDataTransformedByMonth = extractLastDayOfMonth(
    firstRowCardCompleteData
  )

  const areaChartData = mergeData(
    isDimensionYear ? areaChartDataTransformedByMonth : lineChartCompleteData,
    isDimensionYear,
    selectedMonthStartDate,
    compareMonthStartDate,
    kpi1,
    kpi2,
    kpi3,
    locale
  )

  const barChartData = mergeData(
    barChartDataTransformedByMonth,
    isDimensionYear,
    selectedMonthStartDate,
    compareMonthStartDate,
    kpi1,
    kpi2,
    kpi3,
    locale
  )
  const lineChartData = mergeData(
    lineChartCompleteData,
    isDimensionYear,
    selectedMonthStartDate,
    compareMonthStartDate,
    kpi1,
    kpi2,
    kpi3,
    locale
  )

  const firstRowCardDataByDay = isCurrentDataFullMonth
    ? lineChartCompleteData
    : filterDataByCurrentDate(lineChartCompleteData, selectedDate)

  const firstRowCardData = mergeData(
    isDimensionYear
      ? firstRowCardDataTransformedByMonth
      : firstRowCardDataByDay,
    isDimensionYear,
    selectedMonthStartDate,
    compareMonthStartDate,
    kpi1,
    kpi2,
    kpi3,
    locale
  )

  const handleDimensionFilter = (
    event: MouseEvent<HTMLButtonElement>,
    value: 'YEAR' | 'MONTH'
  ) => {
    if (value) {
      setDimensionFilter(value)
    }
  }

  const kpiConfig: KPICard[] = [
    {
      id: 'kpi1',
      details: kpi1,
      color: theme.palette.primary.main,
      pastColor: theme.palette.primary.dark,
      currentColor: theme.palette.primary.main,
      forecastColor: theme.palette.primary.light,
    },
    {
      id: 'kpi2',
      details: kpi2,
      color: theme.palette.secondary.main,
      pastColor: theme.palette.secondary.dark,
      currentColor: theme.palette.secondary.main,
      forecastColor: theme.palette.secondary.light,
    },
    {
      id: 'kpi3',
      details: kpi3,
      color: theme.palette.tertiary.main,
      pastColor: theme.palette.tertiary.dark,
      currentColor: theme.palette.tertiary.main,
      forecastColor: theme.palette.tertiary.light,
    },
  ]

  const firstRowKpiCardData = generateKPICardData(firstRowCardData, kpiConfig)
  const secondRowKpiCardData = generateKPICardData(areaChartData, kpiConfig)

  const isCurrent = isDimensionYear
    ? isSelectedDateCurrentYear
    : isSelectedDateCurrentYear && isSelectedDateCurrentMonth

  const updatedKpiCardData = updateSecondRowKpiCardData(
    firstRowKpiCardData,
    secondRowKpiCardData,
    isCurrent
  )

  const forecastLabel = isDimensionYear
    ? 'YEAR_END_FORECAST'
    : 'MONTH_END_FORECAST'

  const resultLabel = isDimensionYear ? 'YEAR_END_RESULT' : 'MONTH_END_RESULT'

  const isFuture = isAfter(selectedDate, yesterday)
  const formattedYesterdayMonth = format(yesterday, 'dd MMM', {
    locale,
  })

  const selectedEndDate = format(selectedDate, 'dd')

  const selectedMonth = format(startOfMonth(selectedDate), 'MMM', {
    locale,
  })
  const comparedMonth = format(startOfMonth(compareMonthStartDate), 'MMM', {
    locale,
  })

  const selectedYearMonth = format(startOfMonth(selectedDate), 'MMM yyyy', {
    locale,
  })
  const comparedYearMonth = format(
    startOfMonth(compareMonthStartDate),
    'MMM yyyy',
    {
      locale,
    }
  )

  const selectedYearMonthDay = format(endOfMonth(selectedDate), 'dd MMM yyyy', {
    locale,
  })
  const comparedYearMonthDay = format(
    endOfMonth(compareMonthStartDate),
    'dd MMM yyyy',
    {
      locale,
    }
  )

  const selectedYear = getYear(selectedDate)
  const comparedYear = getYear(subYears(selectedDate, 1))

  const firstMonthOfYear = format(new Date(selectedYear, 0, 1), 'dd MMM', {
    locale,
  })

  const lastMonthOfYear = format(new Date(selectedYear, 11, 31), 'dd MMM', {
    locale,
  })

  const monthSelectedTrend = `01 ${selectedMonth} ${' - '} ${selectedEndDate} ${selectedYearMonth}`
  const monthComparedTrend = `01 ${comparedMonth} ${' - '} ${selectedEndDate} ${comparedYearMonth}`

  const monthSelectedForecast = `01 ${selectedMonth} ${' - '} ${selectedYearMonthDay}`
  const monthComparedForecast = `01 ${comparedMonth} ${' - '} ${comparedYearMonthDay}`

  const yearTrendMonthDay = isFuture
    ? formattedYesterdayMonth
    : `${selectedEndDate} ${selectedMonth}`

  const yearSelectedTrend = `${firstMonthOfYear} ${' - '} ${yearTrendMonthDay} ${selectedYear}`
  const yearComparedTrend = `${firstMonthOfYear} ${' - '} ${yearTrendMonthDay} ${comparedYear}`

  const yearSelectedForecast = `${firstMonthOfYear} ${' - '} ${lastMonthOfYear} ${selectedYear}`
  const yearComparedForecast = `${firstMonthOfYear} ${' - '} ${lastMonthOfYear} ${comparedYear}`

  const isSelectedDateLastMonthOfYear = isSameMonth(
    selectedDate,
    new Date(selectedYear, 11, 31)
  )

  const isSelectedDateLastMonthOfLastYear =
    !isSelectedDateCurrentYear && isSelectedDateLastMonthOfYear

  if (lineChartStatus === STATUS.pending) {
    return <LinearProgress sx={{ borderRadius: 2 }} />
  }

  if (lineChartStatus === STATUS.rejected) {
    return (
      <Alert severity="error" sx={{ borderRadius: 3, padding: 2 }}>
        <AlertTitle sx={{ fontWeight: 'bold' }}>
          {t('PAGE_NOT_LOADIND_ERROR_TITLE', { ns: 'common' })}
        </AlertTitle>
        <Typography>
          {t('PAGE_NOT_LOADIND_ERROR_MESSAGE', { ns: 'common' })}
        </Typography>
        <Button
          onClick={handleChartDetails}
          variant="contained"
          sx={{
            minWidth: '136px',
            height: '40px',
            borderRadius: 2,
            fontWeight: 'bold',
            textTransform: 'capitalize',
            mt: 3,
          }}
        >
          {t('TRY_AGAIN', { ns: 'common' })}
        </Button>
      </Alert>
    )
  }

  if (lineChartStatus === STATUS.fulfilled && lineChart.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mx: { xs: 2, md: 0 },
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pb: 1,
          }}
        >
          <TrendFilterSection isDimensionYear />
          <TrendDimensionSection
            dimensionFilter={dimensionFilter}
            handleDimensionFilter={handleDimensionFilter}
          />
        </Box>
        {[kpi1, kpi2, kpi3].map((kpi) => (
          <TrendChartSectionsNoData
            key={kpi.id}
            isSelectedDateCurrentYear={isSelectedDateCurrentYear}
            isSelectedDateCurrentMonth={isSelectedDateCurrentMonth}
            isDimensionYear={isDimensionYear}
            kpi={kpi}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mx: { xs: 2, md: 0 },
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pb: 1,
        }}
      >
        <TrendFilterSection isDimensionYear={isDimensionYear} />
        <TrendDimensionSection
          dimensionFilter={dimensionFilter}
          handleDimensionFilter={handleDimensionFilter}
        />
      </Box>
      {updatedKpiCardData.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {isSelectedDateLastMonthOfLastYear
            ? null
            : (isDimensionYear || (!isDimensionYear && isCurrent)) && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 3,
                    padding: 2,
                    gap: 1,
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Typography variant="h5" fontWeight="bold">
                    {t('TREND', { ns: 'common' })}
                  </Typography>

                  <Typography
                    variant="body2"
                    noWrap
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    {isDimensionYear
                      ? `
                      ${t('SELECTED_DATE', { ns: 'common' })}:
                      ${yearSelectedTrend} 
                      ${t('COMPARED_WITH', { ns: 'common' })}: 
                      ${yearComparedTrend}
                    `
                      : `
                      ${t('SELECTED_DATE', { ns: 'common' })}:
                      ${monthSelectedTrend} 
                      ${t('COMPARED_WITH', { ns: 'common' })}: 
                      ${monthComparedTrend}
                    `}
                  </Typography>

                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: 2,
                    }}
                  >
                    {firstRowKpiCardData.map((kpi) => (
                      <TrendCard
                        key={kpi.id}
                        isCurrent={isCurrent}
                        isDimensionYear={isDimensionYear}
                        kpi={kpi.details}
                        firstRowTotal={kpi.currentTotal}
                        firstRowTotalChange={kpi.currentTotalChange}
                        secondRowTotal={kpi.forecastTotal}
                        secondRowTotalChange={kpi.forecastTotalChange}
                        color={kpi.color}
                      />
                    ))}
                  </Box>
                </Box>
              )}

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 3,
              padding: 2,
              gap: 1,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {t(`${isCurrent ? forecastLabel : resultLabel}`, {
                ns: 'common',
              })}
            </Typography>

            <Typography
              variant="body2"
              noWrap
              sx={{ color: theme.palette.text.secondary }}
            >
              {isDimensionYear
                ? `
                    ${t('SELECTED_DATE', { ns: 'common' })}:
                    ${yearSelectedForecast} 
                    ${t('COMPARED_WITH', { ns: 'common' })}: 
                    ${yearComparedForecast}
                  `
                : `
                    ${t('SELECTED_DATE', { ns: 'common' })}:
                    ${monthSelectedForecast} 
                    ${t('COMPARED_WITH', { ns: 'common' })}: 
                    ${monthComparedForecast}
                  `}
            </Typography>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2,
              }}
            >
              {updatedKpiCardData.map((kpi) => (
                <TrendCard
                  key={kpi.id}
                  isCurrent={isCurrent}
                  isDimensionYear={isDimensionYear}
                  kpi={kpi.details}
                  firstRowTotal={kpi.currentTotal}
                  firstRowTotalChange={kpi.currentTotalChange}
                  secondRowTotal={kpi.forecastTotal}
                  secondRowTotalChange={kpi.forecastTotalChange}
                  color={kpi.color}
                  isSecondRow
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {kpiConfig.map((kpi) => (
        <TrendChartSections
          key={kpi.id}
          isSelectedDateCurrentYear={isSelectedDateCurrentYear}
          isSelectedDateCurrentMonth={isSelectedDateCurrentMonth}
          isDimensionYear={isDimensionYear}
          selectedMonth={selectedMonthStartDate}
          compareMonth={compareMonthStartDate}
          compareYear={compareYearStartDate}
          areaChartData={areaChartData}
          barChartData={barChartData}
          lineChartData={lineChartData}
          kpi={kpi.details}
          pastColor={kpi.pastColor}
          currentColor={kpi.currentColor}
          forecastColor={kpi.forecastColor}
        />
      ))}
    </Box>
  )
}

export default TrendPage
