import { useTranslation } from 'react-i18next'

import { Box, Typography, useTheme } from '@mui/material'
import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

const Home = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    value: { userInfo },
  } = useAppSelector(selectAuth)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 2,
        px: 3,
        gap: 0.5,
        backgroundColor: 'background.paper',
        borderRadius: 3,
        my: 2,
        mx: { xs: 2, md: 0 },
      }}
    >
      <Typography
        variant="h5"
        sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
      >
        Hi {userInfo?.firstName}!
      </Typography>
      <Typography variant="body1">
        {t('WELCOME_TO_ORBIT_HOME_PAGE', { ns: 'common' })}
      </Typography>
    </Box>
  )
}

export default Home
