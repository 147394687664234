import { useTranslation } from 'react-i18next'

import { Kpi } from 'common/constants-metrics'

import TrendBarChart from './trend-bar-chart'
import TrendLineChart from './trend-line-chart'
import TrendAreaChart from './trend-area-chart'
import TrendChartInfoIcon from './trend-chart-info-icon'

import { Box, Typography, useTheme } from '@mui/material'

const TrendChartSections = ({
  isSelectedDateCurrentYear,
  isSelectedDateCurrentMonth,
  isDimensionYear,
  selectedMonth,
  compareMonth,
  compareYear,
  areaChartData,
  barChartData,
  lineChartData,
  kpi,
  pastColor,
  currentColor,
  forecastColor,
}: {
  isSelectedDateCurrentYear: boolean
  isSelectedDateCurrentMonth: boolean
  isDimensionYear: boolean
  selectedMonth: string
  compareMonth: string
  compareYear: string
  areaChartData: any
  barChartData: any
  lineChartData: any
  kpi: Kpi
  pastColor: string
  currentColor: string
  forecastColor: string
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const trendInfoTitle = t(
    isDimensionYear
      ? 'TREND_BAR_CHART_INFO_TITEL'
      : 'TREND_LINE_CHART_INFO_TITEL',
    { ns: 'info' }
  )
  const trendInfoText = t(
    isDimensionYear
      ? 'TREND_BAR_CHART_INFO_TEXT'
      : 'TREND_LINE_CHART_INFO_TEXT',
    { ns: 'info' }
  )

  const forecastYearlyInfoLabel = isSelectedDateCurrentYear
    ? 'YEAR_END_FORECAST'
    : 'YEAR_END_RESULT'

  const forecastMonthlyInfoLabel =
    isSelectedDateCurrentYear && isSelectedDateCurrentMonth
      ? 'MONTH_END_FORECAST'
      : 'MONTH_END_RESULT'

  const forecastInfoLabel = t(
    isDimensionYear ? forecastYearlyInfoLabel : forecastMonthlyInfoLabel,
    { ns: 'info' }
  )

  const forecastYearlyInfoTitle = isSelectedDateCurrentYear
    ? 'TREND_AREA_CHART_YEARLY_CURRENT_YEAR_INFO_TITLE'
    : 'TREND_AREA_CHART_YEARLY_NOT_CURRENT_YEAR_INFO_TITLE'

  const forecastMonthlyInfoTitle =
    isSelectedDateCurrentYear && isSelectedDateCurrentMonth
      ? 'TREND_AREA_CHART_MONTHLY_CURRENT_MONTH_INFO_TITEL'
      : 'TREND_AREA_CHART_MONTHLY_NOT_CURRENT_MONTH_INFO_TITEL'

  const forecastInfoTitle = t(
    isDimensionYear ? forecastYearlyInfoTitle : forecastMonthlyInfoTitle,
    { ns: 'info' }
  )

  const forecastYearlyInfoText = isSelectedDateCurrentYear
    ? 'TREND_AREA_CHART_YEARLY_CURRENT_YEAR_INFO_TEXT'
    : 'TREND_AREA_CHART_YEARLY_NOT_CURRENT_YEAR_INFO_TEXT'

  const forecastMonthlyInfoText =
    isSelectedDateCurrentYear && isSelectedDateCurrentMonth
      ? 'TREND_AREA_CHART_MONTHLY_CURRENT_MONTH_INFO_TEXT'
      : 'TREND_AREA_CHART_MONTHLY_NOT_CURRENT_MONTH_INFO_TEXT'

  const forecastInfoText = t(
    isDimensionYear ? forecastYearlyInfoText : forecastMonthlyInfoText,
    { ns: 'info' }
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 3,
        padding: 2,
        gap: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold">
        {t(kpi.title, { ns: 'kpi' })}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: { sx: 12, md: 8 },
          }}
        >
          <ChartLable
            label="TREND"
            tooltipTitle={trendInfoTitle}
            tooltipText={trendInfoText}
          />
          {isDimensionYear ? (
            <TrendBarChart
              isDimensionYear={isDimensionYear}
              compareMonth={compareMonth}
              selectedMonth={selectedMonth}
              compareYear={compareYear}
              chartData={barChartData}
              kpi={kpi}
              pastColor={pastColor}
              currentColor={currentColor}
            />
          ) : (
            <TrendLineChart
              isDimensionYear={isDimensionYear}
              compareMonth={compareMonth}
              selectedMonth={selectedMonth}
              chartData={lineChartData}
              kpi={kpi}
              pastColor={pastColor}
              currentColor={currentColor}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: { sx: 12, md: 4 },
          }}
        >
          <ChartLable
            label={forecastInfoLabel}
            tooltipTitle={forecastInfoTitle}
            tooltipText={forecastInfoText}
          />
          <TrendAreaChart
            isSelectedDateCurrentYear={isSelectedDateCurrentYear}
            isSelectedDateCurrentMonth={isSelectedDateCurrentMonth}
            isDimensionYear={isDimensionYear}
            compareMonth={compareMonth}
            selectedMonth={selectedMonth}
            compareYear={compareYear}
            chartData={areaChartData}
            kpi={kpi}
            pastColor={pastColor}
            currentColor={currentColor}
            forecastColor={forecastColor}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TrendChartSections

const ChartLable = ({
  label,
  tooltipTitle,
  tooltipText,
}: {
  label: string
  tooltipTitle: string
  tooltipText: string
}) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <TrendChartInfoIcon title={tooltipTitle} text={tooltipText} />
        <Typography
          sx={{
            writingMode: 'vertical-lr',
            transform: 'rotate(180deg)',
            fontWeight: 'bold',
          }}
        >
          {t(label, { ns: 'common' })}
        </Typography>
      </Box>
    </Box>
  )
}
