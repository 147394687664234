import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'

import RoutesConfig from 'routes/routes'
import { useCustomTheme } from 'theme/useCustomTheme'
import { CssBaseline } from '@mui/material'

import 'style.css'

const App = () => {
  const theme = useCustomTheme()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Router>
        <RoutesConfig />
      </Router>
    </ThemeProvider>
  )
}

export default App
