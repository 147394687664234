export const STATUS = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
} as const

export const KPI_FORMAT = {
  currency: 'currency',
  integer: 'integer',
  percent: 'percent',
  time: 'time',
} as const

export const KPI_PATH_SORT = {
  asc: 'ASCENDING',
  desc: 'DESCENDING',
} as const

export const REPORT_TYPE = {
  branding: 'branding',
  lead: 'lead',
  ecommerce: 'e-commerce',
} as const

export const SOURCE_NAME = {
  channel: 'channel',
  googleAnalytics: 'googleAnalytics',
} as const

export const ROLES = {
  member: 'member',
  editor: 'editor',
  admin: 'admin',
  globalAdmin: 'globalAdmin',
} as const

export const MODE = {
  light: 'light',
  dark: 'dark',
} as const

export const CURRENCY = {
  euro: 'EUR',
  dollar: 'USD',
  frank: 'CHF',
  pound: 'GBP',
} as const

export const LOGO_SIZE = {
  small: 'small',
  large: 'large',
}

export const SIDEBAR_DRAWER_MOBILE = 'sidebar-drawer-mobile' as const
export const MOBILE_NAVIGATION = 'mobile-navigation' as const
export const ACCOUNT_MENU = 'account-menu' as const
export const CUSTOMER_SELECT = 'customer-select' as const
export const FILTER_MODAL = 'filter-modal' as const

export const Z_INDEX_SIDEBAR = 345 as const

export const DASHBOARD_LAYOUT = {
  WIDTH_MAX: 4500 as const,
  WIDTH_MIN: 375 as const,
} as const

export const HEADER = {
  HEIGHT_MOBILE: 72,
  HEIGHT_DESKTOP: 64,
  HEIGHT_DESKTOP_OFFSET: 32 + 16,
} as const

export const SIDEBAR = {
  WIDTH_DESKTOP_OPEN: 280,
  WIDTH_DESKTOP_MINI: 64,
  HEIGHT_EXPAND_BUTTON: 32,
  WIDTH_EXPAND_BUTTON: 32,
} as const

export const DRAWER = {
  HEIGHT_LOGO: 28,
  WIDTH_LOGO_SMALL: 32,
  WIDTH_LOGO_LARGE: 176,
  WIDTH_PACKAGE: 16,
} as const

export const CUSTOMER_MENU: {
  WIDTH_DESKTOP_WIDE: number
  WIDTH_DESKTOP_EXTRA_WIDE: number
} = {
  WIDTH_DESKTOP_EXTRA_WIDE: 300,
  WIDTH_DESKTOP_WIDE: 320,
}

export enum PageType {
  Home = 'home',
  Explorer = 'explorer',
  Ecommerce = 'e-commerce',
  Lead = 'lead',
  Branding = 'branding',
}

export enum PackageType {
  Essential = 'essential',
  Basic = 'basic',
  Premium = 'premium',
  Performance = 'performance',
}

export enum FeatureType {
  ReportingUnit = 'customer_unit_name',
  TrafficSource = 'traffic_source',
  CampaignType = 'campaign_type',
  CampaignName = 'campaign_name',
  CampaignStatus = 'campaign_status',
  Date = 'date',
}
