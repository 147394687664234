import { useTranslation } from 'react-i18next'

import { KPI_PATH_SORT } from 'common/constants'

import { Typography } from '@mui/material'

import { SortStatusWrapper } from './styles'

const SortStatusText = ({
  titleKpi1,
  titleKpi2,
  titleKpi3,
  sortConfig,
}: {
  titleKpi1: string
  titleKpi2: string
  titleKpi3: string
  sortConfig: { kpiId: string; kpiTitle: string; ascending: boolean }
}) => {
  const { t, i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]
  const isGerman = currentlanguage === 'de'

  const colorMap = {
    [titleKpi1]: 'primary.main',
    [titleKpi2]: 'secondary.main',
    [titleKpi3]: 'tertiary.main',
  }

  const sort = sortConfig.ascending
    ? t(KPI_PATH_SORT.asc, { ns: 'common' })
    : t(KPI_PATH_SORT.desc, { ns: 'common' })
  const kpiTitle = t(`${sortConfig.kpiTitle}`, { ns: 'kpi' })

  return (
    <SortStatusWrapper>
      <Typography>{t('BAR_CHART_SORT_TEXT', { ns: 'common' })}</Typography>
      <Typography fontWeight={900} color={colorMap[sortConfig.kpiTitle]}>
        {sort}
      </Typography>
      <Typography>{t('BY', { ns: 'common' })}</Typography>
      <Typography
        fontWeight={900}
        color={colorMap[sortConfig.kpiTitle]}
        textTransform={isGerman ? 'inherit' : 'lowercase'}
      >
        {kpiTitle}
      </Typography>
      <Typography>{t('SORTED', { ns: 'common' })}</Typography>
    </SortStatusWrapper>
  )
}

export default SortStatusText
