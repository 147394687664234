import { Box, ToggleButton, styled } from '@mui/material'

export const SourcesBarChartWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: `${theme.spacing(2)} 0`,
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1.5),
  overflow: 'auto',
  backgroundColor: theme.palette.background.paper,
}))

export const BarChartsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: theme.spacing(6),
  },
}))

export const SortStatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
}))

export const FilterSectionWrapper = styled(Box)(({ theme }) => ({
  minHeight: '64px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
}))

export const DimensionSectionWrapper = styled(Box)(({ theme }) => ({
  minHeight: '64px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
}))

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    minWidth: '100px',
    margin: `${theme.spacing(0.2)} ${theme.spacing(0.2)}`,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    color: theme.palette.text.primary,
    borderColor: 'transparent',
    fontSize: theme.typography.body2.fontSize,
  },
  '&:hover.MuiToggleButton-root': {
    backgroundColor: theme.palette.background.paper,
  },
  '&.MuiToggleButton-root.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    fontWeight: 'bold',
  },
  '&.MuiToggleButton-root.Mui-disabled': {
    color: theme.palette.text.disabled,
    borderColor: 'transparent',
  },
}))

export const CustomChip = styled(Box)(({ theme }) => ({
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  padding: `0 ${theme.spacing(2)}`,
  borderRadius: theme.spacing(2.5),
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
}))
