import { useTranslation } from 'react-i18next'

import { ChevronLeft, SvgIconComponent } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'

import { NavMobileSubsectionHeaderWrapper } from './styles'

const NavMobileSubsectionHeader = ({
  inViewSectionTitle,
  inViewSectionIcon,
  navigationConfig,
  handleNavigation,
}: {
  inViewSectionTitle: string
  inViewSectionIcon: SvgIconComponent
  navigationConfig: any
  handleNavigation: any
}) => {
  const { t } = useTranslation()

  const renderIcon = (SelectIcon?: SvgIconComponent) => {
    return SelectIcon && <SelectIcon />
  }

  return (
    <NavMobileSubsectionHeaderWrapper>
      <IconButton
        onClick={() =>
          handleNavigation({
            ...navigationConfig,
            isSubsections: true,
            action: 'back',
            expandSectionAddress: '',
          })
        }
      >
        <ChevronLeft />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          color: 'primary.main',
        }}
      >
        {renderIcon(inViewSectionIcon)}
        <Typography variant="h6">
          {t(`${inViewSectionTitle}`, { ns: 'navigation' })}
        </Typography>
      </Box>
    </NavMobileSubsectionHeaderWrapper>
  )
}

export default NavMobileSubsectionHeader
