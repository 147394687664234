import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { Kpi } from 'common/constants-metrics'
import { KPI_FORMAT } from 'common/constants'
import { getKpiValueAndSign } from 'common/helpers'

import TrendCardInfoIcon from './trend-card-info-icon'

import {
  ArrowCircleRightOutlined,
  ArrowCircleUpOutlined,
} from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'

const TrendCard = ({
  isCurrent,
  isDimensionYear,
  kpi,
  firstRowTotal,
  firstRowTotalChange,
  secondRowTotal,
  secondRowTotalChange,
  color,
  isSecondRow = false,
}: {
  isCurrent: boolean
  isDimensionYear: boolean
  kpi: Kpi
  firstRowTotal: number | null
  firstRowTotalChange: number | null
  secondRowTotal: number | null
  secondRowTotalChange: number | null
  color: string
  isSecondRow?: boolean | null
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const {
    value: { currentCustomer },
  } = useAppSelector(selectAuth)

  const currentlanguage = i18n.language.split('-')[0]
  const currency = currentCustomer ? currentCustomer?.currency : ''

  const trendCardCurrentInfoTitle = t(
    isDimensionYear
      ? 'TREND_CARD_CURRENT_YEARLY_INFO_TITEL'
      : 'TREND_CARD_CURRENT_MONTHLY_INFO_TITEL',
    { ns: 'info' }
  )
  const trendCardCurrentInfoText = t(
    isDimensionYear
      ? 'TREND_CARD_CURRENT_YEARLY_INFO_TEXT'
      : 'TREND_CARD_CURRENT_MONTHLY_INFO_TEXT',
    { ns: 'info' }
  )

  const trendCardResultInfoTitle = t(
    isDimensionYear
      ? 'TREND_CARD_RESULT_YEARLY_INFO_TITEL'
      : 'TREND_CARD_RESULT_MONTHLY_INFO_TITEL',
    { ns: 'info' }
  )
  const trendCardResultInfoText = t(
    isDimensionYear
      ? 'TREND_CARD_RESULT_YEARLY_INFO_TEXT'
      : 'TREND_CARD_RESULT_MONTHLY_INFO_TEXT',
    { ns: 'info' }
  )
  const trendCardForecastInfoTitle = t(
    isDimensionYear
      ? 'TREND_CARD_FORECAST_YEARLY_INFO_TITEL'
      : 'TREND_CARD_FORECAST_MONTHLY_INFO_TITEL',
    { ns: 'info' }
  )
  const trendCardForecastInfoText = t(
    isDimensionYear
      ? 'TREND_CARD_FORECAST_YEARLY_INFO_TEXT'
      : 'TREND_CARD_FORECAST_MONTHLY_INFO_TEXT',
    { ns: 'info' }
  )

  const firstRowKpiCards = {
    id: 'current',
    total: firstRowTotal,
    totalChange: firstRowTotalChange,
    tooltipTitle: trendCardCurrentInfoTitle,
    tooltipText: trendCardCurrentInfoText,
  }

  const secondRowKpiCards = {
    id: 'forecast',
    total: secondRowTotal,
    totalChange: secondRowTotalChange,
    tooltipTitle: isCurrent
      ? trendCardForecastInfoTitle
      : trendCardResultInfoTitle,
    tooltipText: isCurrent
      ? trendCardForecastInfoText
      : trendCardResultInfoText,
  }

  const kpiCardByRow = isSecondRow ? secondRowKpiCards : firstRowKpiCards

  const kpiCard = isDimensionYear
    ? kpiCardByRow
    : isCurrent
      ? kpiCardByRow
      : secondRowKpiCards

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 3,
        padding: 2,
        gap: 2,
        backgroundColor: theme.palette.background.paper2,
      }}
    >
      <Typography variant="h5" fontWeight="bold">
        {t(`${kpi.title}`, { ns: 'kpi' })}
      </Typography>
      {!!kpiCard.total && kpiCard.total >= 0 ? (
        <Typography variant="h5" sx={{ fontWeight: 'bold', color }}>
          {getKpiValueAndSign(
            kpiCard.total,
            kpi.format,
            currentlanguage,
            currency
          )}
        </Typography>
      ) : (
        <Typography variant="h5" sx={{ fontWeight: 'bold', color }}>
          {t('NO_RECORDS_SHORT', { ns: 'common' })}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            pr: '18px',
          }}
        >
          {kpiCard.totalChange && kpiCard.totalChange !== 0 ? (
            <ArrowCircleUpOutlined
              sx={{
                color,
                fontSize: '20px',
                transform: `${kpiCard.totalChange > 0 ? 'rotate(45deg)' : 'rotate(135deg)'}`,
              }}
            />
          ) : (
            <ArrowCircleRightOutlined
              sx={{
                color,
                fontSize: '20px',
              }}
            />
          )}

          <Typography variant="body2" sx={{ fontWeight: 'bold', p: 0.5 }}>
            {!!Number(kpiCard.totalChange) &&
            !!kpiCard.totalChange &&
            kpiCard.totalChange > 0
              ? '+'
              : ''}
            {Number(kpiCard.totalChange) || kpiCard.totalChange === 0
              ? getKpiValueAndSign(
                  kpiCard.totalChange,
                  KPI_FORMAT.percent,
                  currentlanguage,
                  currency
                )
              : '------ '}
          </Typography>
          <TrendCardInfoIcon
            title={kpiCard.tooltipTitle}
            text={kpiCard.tooltipText}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TrendCard
