import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { de, enUS } from 'date-fns/locale'
import { format, Locale } from 'date-fns'

import { getDateTrendPageValues } from 'common/helpers-trend-page'

import DateCompareInfoIcon from './date-compare-info-icon'
import DateCompareDropdown from './date-compare-dropdown'
import DateCompareOption from './date-compare-option'

import { Box, Typography } from '@mui/material'

const getRecentYears = (length: number): string[] => {
  const currentYear = new Date().getFullYear()
  return Array.from({ length }, (_, i) => (currentYear - i).toString())
}

const getMonthList = (locale: Locale): string[] => {
  return Array.from({ length: 12 }, (_, i) =>
    format(new Date(2000, i, 1), 'MMMM', { locale })
  )
}

interface DateCompareProps {
  temporaryFilter: any
  handleTemporaryFilter: (filter: any) => void
}

const DateCompare: React.FC<DateCompareProps> = ({
  temporaryFilter,
  handleTemporaryFilter,
}) => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language.split('-')[0]
  const locale = currentLanguage === 'de' ? de : enUS

  const yearList = getRecentYears(4)
  const monthList = getMonthList(locale)

  const currentYear = new Date().getFullYear().toString()
  const currentMonth = format(new Date(), 'MMMM')
  const initialSelectedYear = new Date(
    temporaryFilter.dateTrendPage.selectedMonthStartDate
  )
    .getFullYear()
    .toString()

  const initialSelectedMonth = format(
    new Date(temporaryFilter.dateTrendPage.selectedMonthStartDate),
    'MMMM',
    { locale }
  )

  const initialSelectedCompareOption =
    temporaryFilter.dateTrendPage.monthToCompareOption

  const [monthToCompareOption, setMonthToCompareOption] = useState<
    'PREVIOUS_MONTH' | 'SAME_MONTH_PREVIOUS_YEAR'
  >(initialSelectedCompareOption || 'PREVIOUS_MONTH')

  const [yearSelect, setYearSelect] = useState(
    initialSelectedYear || currentYear
  )
  const [monthSelect, setMonthSelect] = useState(
    initialSelectedMonth || currentMonth
  )

  const [yearAnchorEl, setYearAnchorEl] = useState<null | HTMLElement>(null)
  const isOpenYearDropdown = Boolean(yearAnchorEl)

  const [monthAnchorEl, setMonthAnchorEl] = useState<null | HTMLElement>(null)
  const isOpenMonthDropdown = Boolean(monthAnchorEl)

  const handleMonthToCompareOption = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMonthToCompareOption(
      event.target.value as 'PREVIOUS_MONTH' | 'SAME_MONTH_PREVIOUS_YEAR'
    )
    handleTemporaryFilter({
      ...temporaryFilter,
      dateTrendPage: getDateTrendPageValues(
        monthSelect,
        yearSelect,
        event.target.value as 'PREVIOUS_MONTH' | 'SAME_MONTH_PREVIOUS_YEAR',
        monthList
      ),
    })
  }

  const handleYearSelect = (yearToSelect: string | number) => {
    setYearSelect(yearToSelect.toString())
    handleTemporaryFilter({
      ...temporaryFilter,
      dateTrendPage: getDateTrendPageValues(
        monthSelect,
        yearToSelect.toString(),
        monthToCompareOption,
        monthList
      ),
    })
    setYearAnchorEl(null)
  }

  const handleMonthSelect = (monthToSelect: string) => {
    setMonthSelect(monthToSelect)
    handleTemporaryFilter({
      ...temporaryFilter,
      dateTrendPage: getDateTrendPageValues(
        monthToSelect,
        yearSelect,
        monthToCompareOption,
        monthList
      ),
    })
    setMonthAnchorEl(null)
  }

  const openYearDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setYearAnchorEl(event.currentTarget)
  }

  const openMonthDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setMonthAnchorEl(event.currentTarget)
  }

  const closeYearDropdown = () => {
    setYearAnchorEl(null)
  }

  const closeMonthDropdown = () => {
    setMonthAnchorEl(null)
  }

  const yearInfoDetails = {
    title: t('YEAR', {
      ns: 'dateRange',
    }),
    text: t('TREND_YEAR_INFO_TEXT', {
      ns: 'filter',
    }),
  }

  const monthInfoDetails = {
    title: t('MONTH', {
      ns: 'dateRange',
    }),
    text: t('TREND_MONTH_INFO_TEXT', {
      ns: 'filter',
    }),
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="caption"
          sx={{
            wordSpacing: '0.1em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {t('SELECT_DATE_PERIOD', { ns: 'filter' })}
        </Typography>
        <DateCompareInfoIcon
          title={t('SELECT_DATE_PERIOD', { ns: 'filter' })}
          text={t('TREND_SELECT_DATE_PERIOD_INFO_TEXT', {
            ns: 'filter',
          })}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 4,
          py: 4,
        }}
      >
        <DateCompareDropdown
          infoDetails={monthInfoDetails}
          presetSelect={monthSelect}
          handlePresetSelect={handleMonthSelect}
          isOpenPresetDropdown={isOpenMonthDropdown}
          openPresetDropdown={openMonthDropdown}
          closePresetDropdown={closeMonthDropdown}
          anchorEl={monthAnchorEl}
          presetList={monthList}
        />
        <DateCompareDropdown
          infoDetails={yearInfoDetails}
          presetSelect={yearSelect}
          handlePresetSelect={handleYearSelect}
          isOpenPresetDropdown={isOpenYearDropdown}
          openPresetDropdown={openYearDropdown}
          closePresetDropdown={closeYearDropdown}
          anchorEl={yearAnchorEl}
          presetList={yearList}
        />
      </Box>
      <DateCompareOption
        monthToCompareOption={monthToCompareOption}
        handleMonthToCompareOption={handleMonthToCompareOption}
      />
    </Box>
  )
}

export default DateCompare
