import {
  Explore,
  ExploreOutlined,
  FolderShared,
  FolderSharedOutlined,
  Home,
  HomeOutlined,
  Person,
  PersonOutline,
  PieChartOutline,
  Sell,
  SellOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
  SvgIconComponent,
  TrendingUp,
} from '@mui/icons-material'
import { KPI_PATH } from './constants-kpi-path'

export interface KpiPath {
  title: string
  address: string
}

export interface Subsections {
  title: string
  icon: SvgIconComponent
  address: string
  kpiPath: KpiPath[]
}

export interface Section {
  title: string
  shortTitle: string
  address: string
  icon: SvgIconComponent
  selectIcon: SvgIconComponent
  subsections?: Subsections[]
}

export const TREND: { title: string; address: string; icon: SvgIconComponent } =
  {
    title: 'TREND',
    address: 'trend',
    icon: TrendingUp,
  }

export const SOURCES: {
  title: string
  address: string
  icon: SvgIconComponent
} = {
  title: 'SOURCES',
  address: 'sources',
  icon: PieChartOutline,
}

export const LOGIN = {
  address: 'login',
}

export const PROFILE: Section = {
  title: 'PROFILE',
  shortTitle: 'PROFILE',
  address: 'profile',
  icon: PersonOutline,
  selectIcon: Person,
}

export const HOME: Section = {
  title: 'HOME',
  shortTitle: 'HOME',
  address: '/',
  icon: HomeOutlined,
  selectIcon: Home,
}

export const EXPLORER: Section = {
  title: 'EXPLORER',
  shortTitle: 'EXPLORER',
  address: 'explorer',
  icon: ExploreOutlined,
  selectIcon: Explore,
}

export const LEAD: Section = {
  title: 'LEAD',
  shortTitle: 'LEAD',
  address: 'lead',
  icon: FolderSharedOutlined,
  selectIcon: FolderShared,
  subsections: [
    {
      title: TREND.title,
      address: TREND.address,
      icon: TREND.icon,
      kpiPath: [
        {
          title: KPI_PATH.LEAD_COST_PER_LEAD.title,
          address: KPI_PATH.LEAD_COST_PER_LEAD.kpiPathAddress,
        },
        {
          title: KPI_PATH.LEAD_CONVERSION_RATE.title,
          address: KPI_PATH.LEAD_CONVERSION_RATE.kpiPathAddress,
        },
        {
          title: KPI_PATH.LEAD_CLICK_THROUGH_RATE.title,
          address: KPI_PATH.LEAD_CLICK_THROUGH_RATE.kpiPathAddress,
        },
      ],
    },
    {
      title: SOURCES.title,
      address: SOURCES.address,
      icon: SOURCES.icon,
      kpiPath: [
        {
          title: KPI_PATH.LEAD_COST_PER_LEAD.title,
          address: KPI_PATH.LEAD_COST_PER_LEAD.kpiPathAddress,
        },
        {
          title: KPI_PATH.LEAD_CONVERSION_RATE.title,
          address: KPI_PATH.LEAD_CONVERSION_RATE.kpiPathAddress,
        },
        {
          title: KPI_PATH.LEAD_CLICK_THROUGH_RATE.title,
          address: KPI_PATH.LEAD_CLICK_THROUGH_RATE.kpiPathAddress,
        },
      ],
    },
  ],
}

export const BRANDING: Section = {
  title: 'BRANDING',
  shortTitle: 'BRAND',
  address: 'branding',
  icon: SellOutlined,
  selectIcon: Sell,
  subsections: [
    {
      title: TREND.title,
      address: TREND.address,
      icon: TREND.icon,
      kpiPath: [
        {
          title: KPI_PATH.BRANDING_COST_PER_MILLE.title,
          address: KPI_PATH.BRANDING_COST_PER_MILLE.kpiPathAddress,
        },
        {
          title: KPI_PATH.BRANDING_CLICK_THROUGH_RATE.title,
          address: KPI_PATH.BRANDING_CLICK_THROUGH_RATE.kpiPathAddress,
        },
        {
          title: KPI_PATH.BRANDING_ENGAGEMENT_RATE.title,
          address: KPI_PATH.BRANDING_ENGAGEMENT_RATE.kpiPathAddress,
        },
        {
          title: KPI_PATH.BRANDING_AVG_SESSION_DURATION.title,
          address: KPI_PATH.BRANDING_AVG_SESSION_DURATION.kpiPathAddress,
        },
      ],
    },
    {
      title: SOURCES.title,
      address: SOURCES.address,
      icon: SOURCES.icon,
      kpiPath: [
        {
          title: KPI_PATH.BRANDING_COST_PER_MILLE.title,
          address: KPI_PATH.BRANDING_COST_PER_MILLE.kpiPathAddress,
        },
        {
          title: KPI_PATH.BRANDING_CLICK_THROUGH_RATE.title,
          address: KPI_PATH.BRANDING_CLICK_THROUGH_RATE.kpiPathAddress,
        },
        {
          title: KPI_PATH.BRANDING_ENGAGEMENT_RATE.title,
          address: KPI_PATH.BRANDING_ENGAGEMENT_RATE.kpiPathAddress,
        },
        {
          title: KPI_PATH.BRANDING_AVG_SESSION_DURATION.title,
          address: KPI_PATH.BRANDING_AVG_SESSION_DURATION.kpiPathAddress,
        },
      ],
    },
  ],
}

export const E_COMMERCE: Section = {
  title: 'E_COMMERCE',
  shortTitle: 'E_COM',
  address: 'e-commerce',
  icon: ShoppingCartOutlined,
  selectIcon: ShoppingCart,
  subsections: [
    {
      title: TREND.title,
      address: TREND.address,
      icon: TREND.icon,
      kpiPath: [
        {
          title: KPI_PATH.ECOMMERCE_COST_REVENUE_RATIO.title,
          address: KPI_PATH.ECOMMERCE_COST_REVENUE_RATIO.kpiPathAddress,
        },
        {
          title: KPI_PATH.ECOMMERCE_AVERAGE_ORDER_VALUE.title,
          address: KPI_PATH.ECOMMERCE_AVERAGE_ORDER_VALUE.kpiPathAddress,
        },
        {
          title: KPI_PATH.ECOMMERCE_CONVERSION_RATE.title,
          address: KPI_PATH.ECOMMERCE_CONVERSION_RATE.kpiPathAddress,
        },
        {
          title: KPI_PATH.ECOMMERCE_CLICK_THROUGH_RATE.title,
          address: KPI_PATH.ECOMMERCE_CLICK_THROUGH_RATE.kpiPathAddress,
        },
      ],
    },
    {
      title: SOURCES.title,
      address: SOURCES.address,
      icon: SOURCES.icon,
      kpiPath: [
        {
          title: KPI_PATH.ECOMMERCE_COST_REVENUE_RATIO.title,
          address: KPI_PATH.ECOMMERCE_COST_REVENUE_RATIO.kpiPathAddress,
        },
        {
          title: KPI_PATH.ECOMMERCE_AVERAGE_ORDER_VALUE.title,
          address: KPI_PATH.ECOMMERCE_AVERAGE_ORDER_VALUE.kpiPathAddress,
        },
        {
          title: KPI_PATH.ECOMMERCE_CONVERSION_RATE.title,
          address: KPI_PATH.ECOMMERCE_CONVERSION_RATE.kpiPathAddress,
        },
        {
          title: KPI_PATH.ECOMMERCE_CLICK_THROUGH_RATE.title,
          address: KPI_PATH.ECOMMERCE_CLICK_THROUGH_RATE.kpiPathAddress,
        },
      ],
    },
  ],
}

export const SECTIONS: Record<string, Section> = {
  home: HOME,
  ecommerce: E_COMMERCE,
  lead: LEAD,
  branding: BRANDING,
  // explorer: EXPLORER,
} as const

export const PROFILE_SECTIONS: Record<string, Section> = {
  home: HOME,
  profile: PROFILE,
} as const
