import { Route, Routes } from 'react-router-dom'

import { KPI_PATH } from 'common/constants-kpi-path'
import {
  EXPLORER,
  HOME,
  LOGIN,
  SOURCES,
  TREND,
  PROFILE,
} from 'common/constants-navigation'

import RequireAuth from './require-auth'
import RequireAccess from './require-access'

import { DashboardLayout } from 'layout'
import { Login } from 'pages/login'
import { NotFound } from 'pages/not-found'
import { Explorer } from 'pages/explorer'
import { Home } from 'pages/home'
import { SourcesPage } from 'pages/sources-page'
import { TrendPage } from 'pages/trend-page'
import { Profile } from 'pages/profile'

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path={LOGIN.address} element={<Login />} />

      <Route
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }
      >
        <Route path={PROFILE.address} element={<Profile />} />
        <Route path={HOME.address} element={<Home />} />

        {Object.values(KPI_PATH).map((kpiPath) => (
          <Route
            key={`${kpiPath.sectionAddress}/${SOURCES.address}/${kpiPath.kpiPathAddress}`}
            path={`${kpiPath.sectionAddress}/${SOURCES.address}/${kpiPath.kpiPathAddress}`}
            element={
              <SourcesPage
                kpiPath={kpiPath}
                reportType={kpiPath.sectionAddress}
                sourceName={kpiPath.sourceName}
              />
            }
          />
        ))}

        {Object.values(KPI_PATH).map((kpiPath) => (
          <Route
            key={`${kpiPath.sectionAddress}/${TREND.address}/${kpiPath.kpiPathAddress}`}
            path={`${kpiPath.sectionAddress}/${TREND.address}/${kpiPath.kpiPathAddress}`}
            element={
              <TrendPage
                kpiPath={kpiPath}
                reportType={kpiPath.sectionAddress}
                sourceName={kpiPath.sourceName}
              />
            }
          />
        ))}

        <Route
          path={EXPLORER.address}
          element={
            <RequireAccess>
              <Explorer />
            </RequireAccess>
          }
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default RoutesConfig
