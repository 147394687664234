import { Box, styled } from '@mui/material'

export const FilterSectionWrapper = styled(Box)(({ theme }) => ({
  minHeight: '64px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
}))

export const DimensionSectionWrapper = styled(Box)(({ theme }) => ({
  minHeight: '64px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
}))

export const CustomChip = styled(Box)(({ theme }) => ({
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  padding: `0 ${theme.spacing(2)}`,
  borderRadius: theme.spacing(2.5),
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
}))
