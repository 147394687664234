import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectTheme } from 'store/features/theme-slice'
import { selectAuth } from 'store/features/auth-slice'
import { setModal } from 'store/features/modal-slice'

import useSectionAccess from 'hooks/useSectionAccess'

import { HOME } from 'common/constants-navigation'
import {
  CUSTOMER_SELECT,
  PackageType,
  SIDEBAR_DRAWER_MOBILE,
} from 'common/constants'

import CustomerSearchSelect from './customer-search-select'

import { Business } from '@mui/icons-material'
import { useTheme, useMediaQuery } from '@mui/material'

const HeaderCustomerSelect = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  const address = pathname.split('/')
  const sectionAddress = address[1]

  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))

  const {
    value: { currentCustomer },
  } = useAppSelector(selectAuth)

  const { isGlobalView } = useAppSelector(selectTheme)

  const userPackage = 'performance' as PackageType

  const customerReportTypes = currentCustomer ? currentCustomer.reportTypes : []

  const { isSectionActive } = useSectionAccess(
    userPackage,
    isGlobalView,
    customerReportTypes
  )

  useEffect(() => {
    if (!isSectionActive(sectionAddress)) {
      navigate(HOME.address, { replace: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer])

  const handleClick = () => {
    dispatch(
      setModal({
        type: SIDEBAR_DRAWER_MOBILE,
        activeComponent: CUSTOMER_SELECT,
      })
    )
  }

  return !isWideScreen ? (
    <Business onClick={handleClick} sx={{ cursor: 'pointer' }} />
  ) : (
    <CustomerSearchSelect />
  )
}

export default HeaderCustomerSelect
