import { createSlice } from '@reduxjs/toolkit'
import {
  formatDateToYearMonthDayString,
  getPresetDateRange,
  serializeDateRange,
} from 'common/helpers-date'

import type { RootState } from 'store/store'

import {
  startOfMonth,
  endOfMonth,
  startOfYear,
  subYears,
  subMonths,
} from 'date-fns'

const now = new Date()

const selectedMonthStartDate = formatDateToYearMonthDayString(
  startOfMonth(now)
) as string
const selectedMonthEndDate = formatDateToYearMonthDayString(
  endOfMonth(now)
) as string
const compareMonthStartDate = formatDateToYearMonthDayString(
  startOfMonth(subMonths(now, 1))
) as string
const compareYearStartDate = formatDateToYearMonthDayString(
  startOfYear(subYears(now, 1))
) as string

const initialDateRange = 'LAST_30_DAYS'
const initialFilterOptionState = {
  basic: {},
  advanced: [],
  date: {
    isCustomDateRange: false,
    label: initialDateRange,
    range: serializeDateRange(getPresetDateRange(initialDateRange)),
  },
  dateTrendPage: {
    selectedMonthStartDate,
    selectedMonthEndDate,
    compareMonthStartDate,
    compareYearStartDate,
    monthToCompareOption: 'PREVIOUS_MONTH',
  },
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState: initialFilterOptionState,
  reducers: {
    setFilter: (_, { payload }) => {
      return payload
    },
    resetFilters: () => initialFilterOptionState,
  },
})

export const selectFilter = (state: RootState) => state.filter

export const { setFilter, resetFilters } = filterSlice.actions

export default filterSlice.reducer
