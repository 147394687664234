import { useTranslation } from 'react-i18next'

import { Kpi } from 'common/constants-metrics'

import { Box, Typography, useTheme } from '@mui/material'

import TrendChartInfoIcon from './trend-chart-info-icon'

const TrendChartSectionsNoData = ({
  isSelectedDateCurrentYear,
  isSelectedDateCurrentMonth,
  isDimensionYear,
  kpi,
}: {
  isSelectedDateCurrentYear: boolean
  isSelectedDateCurrentMonth: boolean
  isDimensionYear: boolean
  kpi: Kpi
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const trendInfoTitle = t(
    isDimensionYear
      ? 'TREND_BAR_CHART_INFO_TITEL'
      : 'TREND_LINE_CHART_INFO_TITEL',
    { ns: 'info' }
  )
  const trendInfoText = t(
    isDimensionYear
      ? 'TREND_BAR_CHART_INFO_TEXT'
      : 'TREND_LINE_CHART_INFO_TEXT',
    { ns: 'info' }
  )

  const forecastYearlyInfoLabel = isSelectedDateCurrentYear
    ? 'YEAR_END_FORECAST'
    : 'YEAR_END_RESULT'

  const forecastMonthlyInfoLabel =
    isSelectedDateCurrentYear && isSelectedDateCurrentMonth
      ? 'MONTH_END_FORECAST'
      : 'MONTH_END_RESULT'

  const forecastInfoLabel = t(
    isDimensionYear ? forecastYearlyInfoLabel : forecastMonthlyInfoLabel,
    { ns: 'info' }
  )

  const forecastYearlyInfoTitle = isSelectedDateCurrentYear
    ? 'TREND_AREA_CHART_YEARLY_CURRENT_YEAR_INFO_TITLE'
    : 'TREND_AREA_CHART_YEARLY_NOT_CURRENT_YEAR_INFO_TITLE'

  const forecastMonthlyInfoTitle =
    isSelectedDateCurrentYear && isSelectedDateCurrentMonth
      ? 'TREND_AREA_CHART_MONTHLY_CURRENT_MONTH_INFO_TITEL'
      : 'TREND_AREA_CHART_MONTHLY_NOT_CURRENT_MONTH_INFO_TITEL'

  const forecastInfoTitle = t(
    isDimensionYear ? forecastYearlyInfoTitle : forecastMonthlyInfoTitle,
    { ns: 'info' }
  )

  const forecastYearlyInfoText = isSelectedDateCurrentYear
    ? 'TREND_AREA_CHART_YEARLY_CURRENT_YEAR_INFO_TEXT'
    : 'TREND_AREA_CHART_YEARLY_NOT_CURRENT_YEAR_INFO_TEXT'

  const forecastMonthlyInfoText =
    isSelectedDateCurrentYear && isSelectedDateCurrentMonth
      ? 'TREND_AREA_CHART_MONTHLY_CURRENT_MONTH_INFO_TEXT'
      : 'TREND_AREA_CHART_MONTHLY_NOT_CURRENT_MONTH_INFO_TEXT'

  const forecastInfoText = t(
    isDimensionYear ? forecastYearlyInfoText : forecastMonthlyInfoText,
    { ns: 'info' }
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 3,
        padding: 2,
        gap: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold">
        {t(kpi.title, { ns: 'kpi' })}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: { sx: 12, md: 8 },
          }}
        >
          <ChartLable
            label="TREND"
            tooltipTitle={trendInfoTitle}
            tooltipText={trendInfoText}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '300px',
              }}
            >
              <Typography>{t('NO_RECORDS', { ns: 'common' })}</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: { sx: 12, md: 4 },
          }}
        >
          <ChartLable
            label={forecastInfoLabel}
            tooltipTitle={forecastInfoTitle}
            tooltipText={forecastInfoText}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '300px',
              }}
            >
              <Typography>{t('NO_RECORDS', { ns: 'common' })}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TrendChartSectionsNoData

const ChartLable = ({
  label,
  tooltipTitle,
  tooltipText,
}: {
  label: string
  tooltipTitle: string
  tooltipText: string
}) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <TrendChartInfoIcon title={tooltipTitle} text={tooltipText} />
        <Typography
          sx={{
            writingMode: 'vertical-lr',
            transform: 'rotate(180deg)',
            fontWeight: 'bold',
          }}
        >
          {t(label, { ns: 'common' })}
        </Typography>
      </Box>
    </Box>
  )
}
