import { DASHBOARD_LAYOUT } from 'common/constants'

import { Box, styled } from '@mui/material'

export const DashboardLayoutWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}))

export const DashboardLayoutContainer = styled(Box)(() => ({
  width: '100%',
  minWidth: `${DASHBOARD_LAYOUT.WIDTH_MIN}px`,
  maxWidth: `${DASHBOARD_LAYOUT.WIDTH_MAX}px`,
  display: 'flex',
  overflow: 'auto',
}))

export const DashboardLayoutMain = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
}))

export const OutletWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: `${theme.spacing(2)} 0 ${theme.spacing(3)}`,
}))
