import { useTranslation } from 'react-i18next'

import LogoSmallLight from 'assets/logo-small-light.png'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'
import { selectTheme } from 'store/features/theme-slice'

import { isFeatureAccessible } from 'common/helpers'

import { FeatureType } from 'common/constants'

import { Box, ToggleButtonGroup, Typography } from '@mui/material'

import { CustomToggleButton, DimensionSectionWrapper } from './styles'

export interface BarChartDimension {
  value: string
  title: string
}

const barChartDimensions: BarChartDimension[] = [
  {
    value: FeatureType.ReportingUnit,
    title: 'REPORTING_UNIT',
  },
  {
    value: FeatureType.TrafficSource,
    title: 'TRAFFIC_SOURCE',
  },
  {
    value: FeatureType.CampaignType,
    title: 'CAMPAIGN_TYPE',
  },
]

const SourcesBarChartDimension = ({
  dimensionFilter,
  handleDimensionFilter,
}: {
  dimensionFilter: any
  handleDimensionFilter: any
}) => {
  const { t } = useTranslation()

  const {
    value: { package: userPackage },
  } = useAppSelector(selectAuth)

  const { isGlobalView } = useAppSelector(selectTheme)
  const isReportingUnit = true
  const isDimensionAvailable = (feature: string) => {
    if (feature === FeatureType.ReportingUnit) {
      return isReportingUnit
    }
    return true
  }

  return (
    <DimensionSectionWrapper>
      <Box sx={{ minWidth: '100px' }}>
        <Typography
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
        >
          {t('DIMENSIONS', {
            ns: 'filter',
          })}
        </Typography>
      </Box>
      <ToggleButtonGroup
        exclusive
        value={dimensionFilter}
        onChange={handleDimensionFilter}
        size="small"
        sx={{
          bgcolor: 'background.default',
          borderRadius: 6,
          p: 0.5,
          '& .MuiToggleButton-root': {
            textTransform: 'none',
            borderRadius: 5,
          },
        }}
      >
        {barChartDimensions.map((dimension) => (
          <CustomToggleButton
            key={dimension.value}
            value={dimension.value}
            disabled={
              !isDimensionAvailable(dimension.value) ||
              !isFeatureAccessible(
                userPackage,
                dimension.value as FeatureType,
                isGlobalView
              )
            }
          >
            <Box
              sx={{
                position: 'relative',
                filter: 'grayScale(100%)',
              }}
            >
              {t(`${dimension.title}`, {
                ns: 'filter',
              })}
              {isDimensionAvailable(dimension.value) &&
                !isFeatureAccessible(
                  userPackage,
                  dimension.value as FeatureType,
                  isGlobalView
                ) && (
                  <Box
                    sx={{ position: 'absolute', top: '-6px', right: '-12px' }}
                  >
                    <img
                      src={LogoSmallLight}
                      alt="company logo"
                      style={{
                        width: '10px',
                        height: 'auto',
                      }}
                    />
                  </Box>
                )}
            </Box>
          </CustomToggleButton>
        ))}
      </ToggleButtonGroup>
    </DimensionSectionWrapper>
  )
}

export default SourcesBarChartDimension
