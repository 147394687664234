import { REPORT_TYPE, KPI_PATH_SORT, SOURCE_NAME } from './constants'
import { METRICS } from './constants-metrics'

const {
  COST,
  CLICKS,
  LEADS,
  REVENUE,
  COST_PER_MILLE,
  COST_PER_LEAD,
  AVG_ORDER_VALUE,
  IMPRESSIONS,
  ENGAGED_SESSIONS,
  SESSIONS_GA,
  SESSION_DURATION,
  AVG_SESSION_DURATION,
  ENGAGEMENT_RATE,
  COST_REVENUE_RATIO,
  CONVERSION_RATE,
  CONVERSION_RATE_E_COMMERCE,
  CONVERSIONS,
  CLICK_THROUGH_RATE,
  CLICK_THROUGH_RATE_BRANDING,
  CLICK_THROUGH_RATE_E_COMMERCE,
} = METRICS

// Lead
export const LEAD_COST_PER_LEAD = {
  title: COST_PER_LEAD.title,
  sectionAddress: REPORT_TYPE.lead,
  kpiPathAddress: 'cost-per-lead',
  sort: KPI_PATH_SORT.desc,
  kpi1: COST,
  kpi2: LEADS,
  kpi3: COST_PER_LEAD,
  sourceName: SOURCE_NAME.channel,
}
export const LEAD_CONVERSION_RATE = {
  title: CONVERSION_RATE.title,
  sectionAddress: REPORT_TYPE.lead,
  kpiPathAddress: 'conversion-rate-something',
  sort: KPI_PATH_SORT.desc,
  kpi1: LEADS,
  kpi2: CLICKS,
  kpi3: CONVERSION_RATE,
  sourceName: SOURCE_NAME.channel,
}
export const LEAD_CLICK_THROUGH_RATE = {
  title: CLICK_THROUGH_RATE.title,
  sectionAddress: REPORT_TYPE.lead,
  kpiPathAddress: 'click-through-rate',
  sort: KPI_PATH_SORT.desc,
  kpi1: CLICKS,
  kpi2: IMPRESSIONS,
  kpi3: CLICK_THROUGH_RATE,
  sourceName: SOURCE_NAME.channel,
}

// Branding
export const BRANDING_COST_PER_MILLE = {
  title: COST_PER_MILLE.title,
  sectionAddress: REPORT_TYPE.branding,
  kpiPathAddress: 'cost-per-mille',
  sort: KPI_PATH_SORT.asc,
  kpi1: COST,
  kpi2: IMPRESSIONS,
  kpi3: COST_PER_MILLE,
  sourceName: SOURCE_NAME.channel,
}
export const BRANDING_CLICK_THROUGH_RATE = {
  title: CLICK_THROUGH_RATE_BRANDING.title,
  sectionAddress: REPORT_TYPE.branding,
  kpiPathAddress: 'click-through-rate',
  sort: KPI_PATH_SORT.desc,
  kpi1: CLICKS,
  kpi2: IMPRESSIONS,
  kpi3: CLICK_THROUGH_RATE_BRANDING,
  sourceName: SOURCE_NAME.channel,
}
export const BRANDING_ENGAGEMENT_RATE = {
  title: ENGAGEMENT_RATE.title,
  sectionAddress: REPORT_TYPE.branding,
  kpiPathAddress: 'engagement-rate',
  sort: KPI_PATH_SORT.desc,
  kpi1: ENGAGED_SESSIONS,
  kpi2: SESSIONS_GA,
  kpi3: ENGAGEMENT_RATE,
  sourceName: SOURCE_NAME.googleAnalytics,
}
export const BRANDING_AVG_SESSION_DURATION = {
  title: AVG_SESSION_DURATION.title,
  sectionAddress: REPORT_TYPE.branding,
  kpiPathAddress: 'avg-session-duration',
  sort: KPI_PATH_SORT.desc,
  kpi1: SESSION_DURATION,
  kpi2: SESSIONS_GA,
  kpi3: AVG_SESSION_DURATION,
  sourceName: SOURCE_NAME.googleAnalytics,
}

// E-Commerce
export const ECOMMERCE_COST_REVENUE_RATIO = {
  title: COST_REVENUE_RATIO.title,
  sectionAddress: REPORT_TYPE.ecommerce,
  kpiPathAddress: 'cost-revenue-ratio',
  sort: KPI_PATH_SORT.asc,
  kpi1: COST,
  kpi2: REVENUE,
  kpi3: COST_REVENUE_RATIO,
  sourceName: SOURCE_NAME.channel,
}
export const ECOMMERCE_AVERAGE_ORDER_VALUE = {
  title: AVG_ORDER_VALUE.title,
  sectionAddress: REPORT_TYPE.ecommerce,
  kpiPathAddress: 'average-order-value',
  sort: KPI_PATH_SORT.desc,
  kpi1: REVENUE,
  kpi2: CONVERSIONS,
  kpi3: AVG_ORDER_VALUE,
  sourceName: SOURCE_NAME.channel,
}
export const ECOMMERCE_CONVERSION_RATE = {
  title: CONVERSION_RATE_E_COMMERCE.title,
  sectionAddress: REPORT_TYPE.ecommerce,
  kpiPathAddress: 'conversion-rate',
  sort: KPI_PATH_SORT.desc,
  kpi1: CONVERSIONS,
  kpi2: CLICKS,
  kpi3: CONVERSION_RATE_E_COMMERCE,
  sourceName: SOURCE_NAME.channel,
}
export const ECOMMERCE_CLICK_THROUGH_RATE = {
  title: CLICK_THROUGH_RATE_E_COMMERCE.title,
  sectionAddress: REPORT_TYPE.ecommerce,
  kpiPathAddress: 'click-through-rate',
  sort: KPI_PATH_SORT.desc,
  kpi1: CLICKS,
  kpi2: IMPRESSIONS,
  kpi3: CLICK_THROUGH_RATE_E_COMMERCE,
  sourceName: SOURCE_NAME.channel,
}

export const KPI_PATH = {
  // Lead
  LEAD_COST_PER_LEAD,
  LEAD_CONVERSION_RATE,
  LEAD_CLICK_THROUGH_RATE,

  // Branding
  BRANDING_COST_PER_MILLE,
  BRANDING_CLICK_THROUGH_RATE,
  BRANDING_ENGAGEMENT_RATE,
  BRANDING_AVG_SESSION_DURATION,

  // E-Commerce
  ECOMMERCE_COST_REVENUE_RATIO,
  ECOMMERCE_AVERAGE_ORDER_VALUE,
  ECOMMERCE_CONVERSION_RATE,
  ECOMMERCE_CLICK_THROUGH_RATE,
}
