import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { PROFILE } from 'common/constants-navigation'
import { ROLES } from 'common/constants'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import GlobalView from './global-view'

import { HeaderUserMenu } from './header-user'
import { HeaderCustomerSelect } from './header-customer'

import { Apps, InfoOutlined } from '@mui/icons-material'

import { HeaderPanelWrapper } from './styles'
import { IconButton, useTheme } from '@mui/material'

const HeaderPanel = () => {
  const theme = useTheme()
  const { pathname } = useLocation()

  const {
    value: { role: userRole },
  } = useAppSelector(selectAuth)

  const isMemeber = userRole === ROLES.member

  const address = pathname.split('/')
  const sectionAddress = address[1]

  const headerCustomerSelect = useMemo(() => {
    if (sectionAddress === PROFILE.address) {
      return null
    }

    return <HeaderCustomerSelect />
  }, [sectionAddress])

  return (
    <HeaderPanelWrapper>
      {!isMemeber && headerCustomerSelect}
      {/* {!isMemeber && <GlobalView />} */}
      <IconButton
        sx={{
          color: theme.palette.text.primary,
          padding: '4px',
          '&:hover': {
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <InfoOutlined />
      </IconButton>
      <IconButton
        sx={{
          color: theme.palette.text.primary,
          padding: '4px',
          '&:hover': {
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <Apps />
      </IconButton>
      <HeaderUserMenu />
    </HeaderPanelWrapper>
  )
}

export default HeaderPanel
