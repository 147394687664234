import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material'

import { CustomSidebarExpandButton } from './styles'

const SidebarExpandButton = ({
  isOpenSidebarDrawer,
  isSidebarFixed,
  handleSidebarOnHover,
  handleSidebarFixed,
}: {
  isOpenSidebarDrawer: boolean
  isSidebarFixed: boolean
  handleSidebarOnHover: any
  handleSidebarFixed: any
}) => {
  const handleSidebarExpandButtonOnHover = (isMouseEnter: boolean) => {
    if (isOpenSidebarDrawer) {
      handleSidebarOnHover(isMouseEnter)
    }
  }
  return (
    <CustomSidebarExpandButton
      onMouseEnter={() => handleSidebarExpandButtonOnHover(true)}
      onMouseLeave={() => handleSidebarExpandButtonOnHover(false)}
      onClick={handleSidebarFixed}
    >
      {isSidebarFixed ? (
        <KeyboardArrowLeftRounded />
      ) : (
        <KeyboardArrowRightRounded />
      )}
    </CustomSidebarExpandButton>
  )
}

export default SidebarExpandButton
