import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  HOME,
  PROFILE,
  PROFILE_SECTIONS,
  SECTIONS,
} from 'common/constants-navigation'

import { SvgIconComponent } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'
import { HeaderBreadcrumbWrapper } from './styles'

const HeaderBreadCrumb = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { pathname } = useLocation()
  const address = pathname.split('/')
  const sectionAddress = address[1] || HOME.address
  const subsectionAddress = address[2]
  const kpiPathAddress = address[3]

  const breadcrumbItems = useMemo(() => {
    if (sectionAddress === PROFILE.address) {
      return PROFILE_SECTIONS
    }
    return SECTIONS
  }, [sectionAddress])

  const section = Object.values(breadcrumbItems).find(
    (item) => item.address === sectionAddress
  )
  const subsection =
    !!section?.subsections &&
    section.subsections.find((item) => item.address === subsectionAddress)

  const kpi =
    !!subsection &&
    subsection.kpiPath.find((kpiPath) => kpiPath.address === kpiPathAddress)

  const renderIcon = (SelectIcon: SvgIconComponent) => {
    return <SelectIcon />
  }

  return (
    <HeaderBreadcrumbWrapper>
      <Box sx={{ display: 'flex', color: 'primary.main' }}>
        {section && renderIcon(section.selectIcon)}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: `0 ${theme.spacing(1)}`,
        }}
      >
        {section && (
          <Typography variant="h5" fontWeight="bold">
            {t(`${section.title}`, { ns: 'navigation' })}
          </Typography>
        )}

        {subsection && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
              sx={{
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                bgcolor: 'text.primary',
              }}
            />
            <Typography variant="h5" fontWeight="bold">
              {t(`${subsection.title}`, { ns: 'navigation' })}
            </Typography>
            <Box
              sx={{
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                bgcolor: 'text.primary',
              }}
            />
          </Box>
        )}

        {kpi && (
          <Typography variant="h5" fontWeight="bold" color="primary.main">
            {t(`${kpi.title}`, { ns: 'kpi' })}
          </Typography>
        )}
      </Box>
    </HeaderBreadcrumbWrapper>
  )
}

export default HeaderBreadCrumb
